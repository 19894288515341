import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import Header from "../layout/Header";
import Spinner from "../plugins/Spinner";
import Modal from "../layout/Modal";
import Pagination from "../plugins/Pagination";
import dayjs from "dayjs";
import {
  ReactFlow,
  Background,
  BackgroundVariant,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  reconnectEdge,
  MarkerType,
  MiniMap,
  Controls 
} from 'reactflow';
import 'reactflow/dist/style.css';
import usePage from "../../hooks/usePage";
import useAPI from "../../hooks/useAPI";
import Swal from "sweetalert2";
import { upload_s3 } from './UploaderS3';
import Picker from "emoji-picker-react";
import Select from "react-select";
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json'; 
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-github_dark';
import { RichTreeView } from '@mui/x-tree-view';
import { Dialog } from '@mui/material';

import { BsSearch, BsPencilFill, BsTrash3, BsFillCameraVideoFill, BsFillFileEarmarkTextFill, BsDatabaseFillAdd } from "react-icons/bs";
import { FaEllipsisVertical } from "react-icons/fa6";
import { FaAngleLeft, FaRobot, FaImages, FaHeadphones, FaBrain, FaCheckCircle, FaSpinner } from "react-icons/fa";
import { FiMessageSquare } from "react-icons/fi";
import { AiOutlineSelect } from "react-icons/ai";
import { LuTags, LuListTree } from "react-icons/lu";
import { MdOutlineSupportAgent } from "react-icons/md";
import { HiOutlineSave } from "react-icons/hi";
import { TiFlowMerge } from "react-icons/ti";
import { BiSolidError, BiTransfer } from "react-icons/bi";
import { IoIosArrowDropdown, IoIosArrowDropup, IoMdAdd } from "react-icons/io";
import { CiSettings } from "react-icons/ci";
import { VscVariableGroup } from "react-icons/vsc";
import { IoClose } from "react-icons/io5";

import BasicAnswer from "./BotsContent/NodeTypes/BasicAnswer";
import TransferAgent from "./BotsContent/NodeTypes/TransferAgent";
import Tags from "./BotsContent/NodeTypes/Tags";
import AssitantAI from "./BotsContent/NodeTypes/AssitantAI";
import SaveAnswer from "./BotsContent/NodeTypes/SaveAnswer";
import FlowsWhatsApp from "./BotsContent/NodeTypes/FlowsWhatsApp";
import QueryApi from "./BotsContent/NodeTypes/QueryApi";

const initialNodes = [
  {
    id: '1-basicAnswer',
    type: 'basicAnswer',
    data: { 
      title: 'Saludo Inicial',
      first: true,
      bot_response: "¡Hola! ¿Cómo puedo ayudarte hoy?",
      user_response: null,
      options: [
        {
          label: 'Conversar con agente',
          id: '1-basicAnswer-1-conversar-con-agente',
          name: 'agente',
        },
        {
          label: 'Continuar con Bot',
          id: '1-basicAnswer-2-continuar-con-bot',
          name: 'Bot',
        },
      ],
      file: null,
      saved: true,
      variables: null,
      color: '#00A884',
      advanced: {
        isAcceptTerms: null,
        campaign: null,
        campaign_options: [],
        request_name: null,
        confirm_name: null,
        edit_name: null,
      },
    },
    position: { x: 250, y: 5 }
  },
];

const nodeTypes = { 
  basicAnswer: BasicAnswer, 
  transferAgent: TransferAgent,
  tags: Tags,
  assistantAI: AssitantAI,
  saveAnswer: SaveAnswer,
  flowsWhatsApp: FlowsWhatsApp,
  queryApi: QueryApi
};

const nodeTypesColors = {
  basicAnswer: '#00A884', 
  transferAgent: '#F56F89',
  tags: '#5BC0DE',
  assistantAI: '#A7ADA7',
  saveAnswer: '#D89C7E',
  flowsWhatsApp: '#B85C7C',
  queryApi: '#7ECCD8',
}

const getId = () => {
  return makeRandomId(10);
};
const makeRandomId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const companyScheduleOptions = [
  {
    label: "24 Horas",
    value: "24h"
  },
  {
    label: "Horario comercial",
    value: "companySchedule"
  }
]

const Flow = () => {

  //# Usos
  const { urlAPI_1, urlAPI_2, urlAPI_3, fetchWithAuth } = useAPI(); 
  const [isDark, setIsDark] = useState(false);

  //# Estados de plugins (Modales, Spinner...)
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [mode, setMode] = useState("list");
  const [modalCreate, setModalCreate] = useState(false);
  const [modalHeaders, setModalHeaders] = useState(false);
  const [modalRequestParams, setModalRequestParams] = useState(false);
  const [modalRequestParamsBody, setModalRequestParamsBody] = useState(false);
  const [modalResponseParams, setModalResponseParams] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [search, setSearch] = useState("");
  const [selectHeaders, setSelectHeaders] = useState(false);
  const [selectRequest, setSelectRequest] = useState(false);
  const [selectRequestBody, setSelectRequestBody] = useState(false);
  const [selectResponse, setSelectResponse] = useState(false);
  const [selectTimeInactivity, setSelectTimeInactivity] = useState(false);
  const [selectSOS, setSelectSOS] = useState(false);
  const [selectAdvancedOptions, setSelectAdvancedOptions] = useState(false);
  const [selectCompanySchedule, setSelectCompanySchedule] = useState(false);
  const [selectControlMessage, setSelectControlMessage] = useState(false);
  const [configModal, setConfigModal] = useState(false);
  const [variablesModal, setVariablesModal] = useState(false);
  const [variablesOptionsModal, setVariablesOptionsModal] = useState(false);
  const [indexOptionResToNode, setIndexOptionResToNode] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [responseToNodeModal, setResponseToNodeModal] = useState(false);
  const [typeInput, setTypeInput] = useState("");
  const [typeInputValue, setTypeInputValue] = useState("");
  const [editName, setEditName] = useState("");
  const [showEditName, setShowEditName] = useState(false);
  const [showModalCreateTemplate, setShowModalCreateTemplate] = useState(false);
  const [modalConnectionTest, setModalConnectionTest] = useState(false);

  //# Estados del Modulo
  const [listBots, setListBots] = useState([]);
  const [listBotsTemplates, setListBotsTemplates] = useState([]);
  const [validation, setValidation] = useState(false);
  const { page, setPage, byPage, resetPage } = usePage();
  const [nameBot, setNameBot] = useState("")
  const [descriptionBot, setDescriptionBot] = useState("")
  const [listAreas, setListAreas] = useState([]);
  const [listAdvisers, setListAdvisers] = useState([]);
  const [listTags, setListTags] = useState([]);
  const [listAssistants, setListAssistants] = useState([]);
  const [correspondence, setCorrespondence] = useState([]);
  const [listRequestParams, setListRequestParams] = useState([]);
  const [listOptionsResToNode, setListOptionsResToNode] = useState([]);
  const [flows, setFlows] = useState([]);
  const [listInactivity, setListInactivity] = useState([]);
  const [listAttempts, setListAttempts] = useState([]);
  const [hasAssistantAi, setHasAssistantAi] = useState(false);

  // FLUJO
  const { screenToFlowPosition } = useReactFlow();
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const memoizedNodeTypes = useMemo(() => nodeTypes, []);
  const reconnectDone = useRef(true);
  const [selectedNode, setSelectedNode] = useState(null);
  const [typeTransfer, setTypeTransfer] = useState('areas');
  const [showPicker, setShowPicker] = useState(false);
  const [headerParams, setHeaderParams] = useState({});
  const [requestParams, setRequestParams] = useState({});
  const [requestParamsBody, setRequestParamsBody] = useState({});
  const [responseParams, setResponseParams] = useState({});
  const [requestResponse, setRequestResponse] = useState({});
  const [configBot, setConfigBot] = useState({});
  const [validateMessageConfig, setValidateMessageConfig] = useState(false);
  const [editFlow, setEditFlow] = useState({});
  const [connectionTestData, setConnectionTestData] = useState({
    headers: [],
    params: [],
    data: []
  });
  const [listToNodeData, setListToNodeData] = useState({
    options: [],
    description: "",
  });

  const [selectedTemplateModal, setSelectedTemplateModal] = useState(false);

  // #--> FLUJO
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const onConnect = (params) => {
      
    let color = ""  
    if(params.source.includes('basicAnswer')) {
      color = nodeTypesColors['basicAnswer']
    }
    if(params.source.includes('transferAgent')) {
      color = nodeTypesColors['transferAgent']
    }
    if(params.source.includes('tags')) {
      color = nodeTypesColors['tags']
    }
    if(params.source.includes('assistantAI')) {
      color = nodeTypesColors['assistantAI']
    }
    if(params.source.includes('saveAnswer')) {
      color = nodeTypesColors['saveAnswer']
    }
    if(params.source.includes('flowsWhatsApp')) {
      color = nodeTypesColors['flowsWhatsApp']
    }
    if(params.source.includes('queryApi')) {
      color = nodeTypesColors['queryApi']
    }

    let edgeRetunr = false
    if(params.targetHandle.includes('target-return')) {
      edgeRetunr = true
    }
    
    if(!edgeRetunr) {
      setDataFromChildToParent(params);
    }
    
    const newEdge = {
      ...params,
      type: 'smoothstep',
      animated: edgeRetunr,
      label: validateTransitionsLength(params.source),
      labelStyle: { fontSize: 8, fontWeight: 700, fill: '#ffffff'}, 
      labelBgStyle: { fill: color },
      labelBgPadding: [4, 2], 
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: color,
      },
      style: {
        strokeWidth: 2,
        stroke: color,
      },
      zIndex: edgeRetunr ? 0 : 100
    };    

    const updatedEdges = addEdge(newEdge, edges);
    setEdges(updatedEdges);

    setTimeout(async() => {
      await updateBot(editFlow, updatedEdges, nodes);
    }, 1000)
  }

  const onDrop = (event) => {
    event.preventDefault();

    const type = event.dataTransfer.getData('application/reactflow');

    // check if the dropped element is valid
    if (typeof type === 'undefined' || !type) {
      return;
    }

    let newNode
    const position = screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });
    if(type == 'BasicAnswer') {
      
      newNode = {
        id: getId() + "-basicAnswer",
        type: 'basicAnswer',
        data: { 
          title: 'Respues Básica',
          bot_response: "",
          user_response: null,
          options: [],
          file: null,
          variables: null,
          saved: false,
          advanced: {
            request_name: null,
            confirm_name: null,
            edit_name: null,
            dynamic_buttons: false,
            dynamic_buttons_variable: null
          },
          onRemove: (value) => removeNode(value)
        },
        position,
      };
    }
    if(type == 'TransferAgent') {
      
      newNode = {
        id: getId() + "-transferAgent",
        type: 'transferAgent',
        data: { 
          title: 'Transferir Agente/Area',
          first: false,
          bot_response: "",
          user_response: null,
          agent_area: null,
          variables: null,
          saved: false,
          onRemove: (value) => removeNode(value)
        },
        position,
      };
    }
    if(type == 'Tags') {
      
      newNode = {
        id: getId() + "-tags",
        type: 'tags',
        data: { 
          title: 'Etiquetar',
          first: false,
          user_response: null,
          tags: null,
          variables: null,
          saved: false,
          onRemove: (value) => removeNode(value)
        },
        position,
      };
    }
    if(type == 'AssitantAI') {
      
      newNode = {
        id: getId() + "-assistantAI",
        type: 'assistantAI',
        data: { 
          title: 'Asistente IA',
          user_response: null,
          assitant: null,
          variables: null,
          saved: false,
          onRemove: (value) => removeNode(value)
        },
        position,
      };
    }
    if(type == 'SaveAnswer') {
      
      newNode = {
        id: getId() + "-saveAnswer",
        type: 'saveAnswer',
        data: { 
          title: 'Guardar Respuesta',
          user_response: '*',
          variable: null,
          variables: null,
          saved: false,
          onRemove: (value) => removeNode(value)
        },
        position,
      };
    }
    if(type == 'FlowsWhatsApp') {
      
      newNode = {
        id: getId() + "-flowsWhatsApp",
        type: 'flowsWhatsApp',
        data: { 
          title: 'Flujo de WhatsApp',
          user_response: null,
          flow: null,
          flow_data: null,
          variables: null,
          saved: false,
          onRemove: (value) => removeNode(value)
        },
        position,
      };
    }
    if(type == 'QueryApi') {
      
      newNode = {
        id: getId() + "-queryApi",
        type: 'queryApi',
        data: { 
          title: 'Consulta API',
          user_response: null,
          succes: 'Exito',
          failed: 'Fallo',
          url_api: null,
          auth: null,
          protocol: null,
          user: null,
          password: null,
          headers: [],
          requestParams: [],
          requestParamsBody: [],
          responseParams: [],
          variables: null,
          saved: false,
          onRemove: (value) => removeNode(value)
        },
        position,
      };
    }

    setNodes((nds) => [...nds, newNode]);
    let newNodes = [...nodes, newNode]
    setTimeout(() => {
      addNewNode(editFlow, newNodes, newNode)
    }, 500);
  }

  useEffect(() => {
    console.log('dataaaaaaaaaaaaaaaaa', selectedNode)
  }, [selectedNode])

  useEffect(() => {
    if (search === "") {
      handleSearch();
    }
  }, [search]);

  const handleSearch = async () => {
    try {
      setLoading(true);
      const url = urlAPI_3 + `chatBot?search=${encodeURIComponent(search)}`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if(result.length <= 0) {
        console.log("no hay data");
        setListBots([]);
        setValidation(true);
      } else {
        console.log("si hay lista");
        setListBots(result);
        setValidation(false);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error en la búsqueda:", error);
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }

  const onReconnectStart = () => {
    reconnectDone.current = false;
  }

  const onReconnect = (oldEdge, newConnection) => {
    reconnectDone.current = true;
    let reconnect = reconnectEdge(oldEdge, newConnection, edges)
    setEdges(reconnect);

    setTimeout(async() => {
      await updateBot(editFlow, reconnect, nodes);
    }, 500)
  }

  const onReconnectEnd = (_, edge) => {

    if (!reconnectDone.current) {
      let newEdges = edges.filter((e) => e.id !== edge.id) 
      setEdges(newEdges);
      
      setTimeout(async() => {
        await updateBot(editFlow, newEdges, nodes);
      }, 500)
    }

    reconnectDone.current = true;
  }

  const handleNodeClick = (event, node) => {
    if(!node.parentId){
      setSelectedNode(node);
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    setSelectedNode((prevInputValue) => ({
      ...prevInputValue,
      data: {
        ...prevInputValue.data,
        bot_response: prevInputValue.data.bot_response + emojiObject.emoji
      }
    }))
  };

  const setDataFromChildToParent = (params) => {
    if (!params || !params.sourceHandle || !params.target) {
      console.error('Invalid parameters', params);
      return;
    }
  
    let sHandle = params.sourceHandle.replace(/source-/, '').replace(/-\d+$/, '');
  
    const findNodeById = (nodes, id) => {
      for (let node of nodes) {
        if (node.data?.options) {
          let optionFind = node.data.options.find(option => option.id === id);
          if (optionFind) {
            return optionFind.label;
          }
        }
      }
      return null;
    };
  
    const optionLabel = findNodeById(nodes, sHandle);
  
    if (optionLabel) {
      setNodes((prevNodes) =>
        prevNodes.map((prevNode) => {
          if (prevNode.id === params.target) {
            const currentResponse = prevNode.data.user_response || '';
            const optionExists = currentResponse.split(' / ').includes(optionLabel);
            return optionExists
              ? prevNode
              : {
                  ...prevNode,
                  data: {
                    ...prevNode.data,
                    user_response: currentResponse
                      ? currentResponse + ' / ' + optionLabel
                      : optionLabel,
                  },
                };
          }
          return prevNode;
        })
      );      
    } else {
      console.warn(`No matching option found for handle: ${sHandle}`);
    }
  };  

  const validateTransitionsLength = (source) => {
    const filter = edges.filter(v => v.source == source);
    return filter.length + 1;
  };

  // CRUD
  //#--> Listar plantillas de bots
  const getBotsTemplates = async () => {
    try {
      setLoading(true);
      const url = urlAPI_3 + 'chatBotTemplates';
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
        console.log("result----result----result----result---->", result.data);
      if(result.length <= 0) {
        console.log("no hay data de plantillas ❌");
        setListBotsTemplates([]);
        // setValidation(true);
      } else {
        console.log("si hay lista de plantillas✔");
        setListBotsTemplates(result.data);
        // setValidation(false);
      }
        setLoading(false);
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };
  //#--> Listar
  const getBots = async (loader) => {
    try {
      if(loader) {
        setLoading(true);
      }
      const url = urlAPI_3 + 'chatBot';
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if(result.length <= 0) {
        console.log("no hay data");
        setListBots([]);
        setValidation(true);
      } else {
        console.log("si hay lista");
        setListBots(result);
        setValidation(false);
      }
      if(loader) {
        setLoading(false);
      }
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };
  //#--> Crear Bot
  const createBot = async () => {
    try {
      setLoading(true);

      const dataBot = await buildJson("create", edges, nodes)

      const url = urlAPI_3 + "chatBot";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(dataBot)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      setEditFlow(result)
      await getBots();
      
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  //#--> Agregar Nuevo nodo 
  const addNewNode = async (data, newNodes, newNode) => {

    setSelectedNode(newNode);
    data.nodes = newNodes
    const url = urlAPI_3 + `chatBot/${data._id}`;
    const options = { method: "PUT", mode: "cors", body: JSON.stringify(data)};
    const response = await fetchWithAuth(url, options);
    getBots(false);
  }
  //#--> Remover un nodo
  const removeNode = (id) => {

    Swal.fire({
      title: "¿Estas seguro que deseas eliminar este nodo?",
      text: "No se podrá revertir esta acción.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        
        const newNodes = nodes.filter(v => v.id !== id);
        const newEdges = edges.filter((e) => e.source !== id && e.target !== id) 
        setNodes(newNodes);
        setEdges(newEdges);
        setHasAssistantAi(false);

        setTimeout(async() => {
          await updateBot(editFlow, newEdges, newNodes);

          setSelectedNode(null);
          await getBots(false);
          setLoading(false);
        }, 500)
      }
    });
  };
  //#--> Actualizar BOT
  const updateBot = async (data, newEdges, newNodes) => {
    try {

      const dataBot = await buildJson("update", newEdges, newNodes)

      data.flows = dataBot.flows
      data.nodes = dataBot.nodes
      data.edges = dataBot.edges
      
      const url = urlAPI_3 + `chatBot/${data._id}`;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(data)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      setEditFlow(result)
      getBots(false);
    } catch (error) {
      console.log(error);
    }
  };
  //#--> Remover Bot
  const deleteBot = async (id) => {
    try {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar este bot?",
        text: "No podrás recuperarlo.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const url = urlAPI_3 + `chatBot/${id}`;
          const options = { method: "DELETE", mode: "cors"};
          const response = await fetchWithAuth(url, options);
          const result = await response.json();

          //#--> Actualización de la tabla
          getBots()
          setLoading(false);

          //#--> Confirmación de la eliminación
          Swal.fire({
            title: "¡Bien!",
            text: "Bot Eliminado con éxito",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  // #--> Armar JSON
  const buildJson = async (type, newEdges, newNodes) => {

    let flows = []
    for(let i in newNodes) {
      const node = newNodes[i]
      let newFlow = {};

      // Transitions
      const filterEdge = newEdges.filter(v => v.source == node.id)
      const transitions = filterEdge.reduce((acc, v) => {
        if (!acc.seen.has(v.target)) {
          acc.transitions.push({
            next_flow_id: v.target,
            conditions: [],
          });
          acc.seen.add(v.target);
        }
        return acc;
      }, { transitions: [], seen: new Set() }).transitions;

      // Triggers
      let triggers = [];
      if (node.data.first) {
        triggers.push({
          type: 'message',
          pattern: '*',
        });
      } else if (node.data.user_response) {
        const userResponseArray = node.data.user_response.includes(" / ")
          ? node.data.user_response.split(" / ")
          : [node.data.user_response];

        const uniquePatterns = new Set(userResponseArray.map(item => item.trim()));
        uniquePatterns.forEach(pattern => {
          triggers.push({
            type: 'message',
            pattern,
          });
        });
      } 

      if(node.type == "basicAnswer"){
        let actions = []
        if(node.data.options.length || node.data?.advanced?.dynamic_buttons) {
          actions.push(
            {
              type: 'send_message', 
              typeMessage: "interactive",
              content: {
                options: node.data.options,
                message: node.data.bot_response
              },
            }
          )
        }else if(node.data.bot_response && !node.data.file) {
          actions.push(
            {
              type: 'send_message',
              typeMessage: "text",
              content: node.data.bot_response,
            }
          )
        }else if(node.data.bot_response && node.data.file){
          actions.push(
            {
              type: 'send_message',
              typeMessage: "media",
              content: {
                name: node.data.file.name,
                type: node.data.file.type,
                url: node.data.file.url,
                fileText: node.data.bot_response
              }
            }
          )
        }else if(node.data.file){
          actions.push(
            {
              type: 'send_message',
              typeMessage: "media",
              content: {
                name: node.data.file.name,
                type: node.data.file.type,
                url: node.data.file.url,
                fileText: ""
              }
            }
          )
        }
        
        const moreTriggers = findMoreTrigger(newEdges, newNodes, node.id)
        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: [
            ...triggers,
            ...moreTriggers
          ],
          actions: actions,
          transitions: transitions,
        };
      }
      if(node.type == "transferAgent"){
        let type_transfer
        let content
        if(node.data?.agent_area?.type == 'agent') {
          type_transfer = 'transfer_agent'
          content = node.data?.agent_area?.value
        }
        if(node.data?.agent_area?.type == 'area') {
          type_transfer = 'transfer_area'
          content = node.data?.agent_area?.label
        }
        if(node.data?.agent_area?.type == 'automatic') {
          type_transfer = 'transfer_automatic'
          content = node.data?.agent_area?.id
        }
        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: triggers,
          actions: [
            {
              type: 'send_message',
              typeMessage: "text",
              content: node.data.bot_response,
            },
            {
              type: type_transfer,
              content: content,
            }
          ],
          transitions: transitions,
        };
      }
      if(node.type == "tags"){
        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: triggers,
          actions: [
            {
              type: "taged",
              content: node.data.tags,
            }
          ],
          transitions: transitions,
        };
      }
      if(node.type == "saveAnswer"){
        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: triggers,
          actions: [
            {
              type: "saveAnswer",
              content: node.data.variable,
            }
          ],
          transitions: transitions,
        };
      }
      if(node.type == "flowsWhatsApp"){
        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: triggers,
          actions: [
            {
              type: 'send_flow',
              content: {
                flow: node.data.flow,
                flow_data: node.data.flow_data
              },
            }
          ],
          transitions: transitions,
        };
      }
      if(node.type == "queryApi"){

        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: triggers,
          actions: [
            {
              type: "queryApi",
              content: node.data,
            },
            {
              type: 'send_message',
              typeMessage: "text",
              content: node.data.bot_response,
            }
          ],
          transitions: transitions,
        };
      }
      if(node.type == "assistantAI"){

        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: triggers,
          actions: [
            {
              type: 'send_message',
              typeMessage: "text",
              content: node.data.bot_response,
            },
            {
              type: "assistantAI",
              content: node.data.assistant,
            }
          ],
          transitions: transitions,
        };
      }

      flows.push(newFlow)
    }

    flows = validateFlowsTags(flows);
    flows = validateFlowsSaveAnswer(flows);

    let json = {
      name: nameBot,
      description: descriptionBot,
      enabled: false,
      inactivityTime: 15,
      inactivityTimeClose: 15,
      inactivityMessages: null,
      inactivityStatus: false,
      companySchedule: "24h",
      controlMessage: null,
      flows: flows,
      nodes: nodes,
      edges: edges
    }

    if(type == 'create') {
      return json
    } else {
      return {
        flows: flows,
        nodes: newNodes,
        edges: newEdges
      }
    }
  }
  // #--> ResetData
  const reset = () => {
    setNameBot("");
    setDescriptionBot("");
    setNodes([]);
    setEdges([]);
    setHeaderParams({});
    setRequestParams({});
    setRequestParamsBody({});
    setResponseParams({});
    setRequestResponse({});
    setConfigBot({});
    setShowPicker(false);
    setSelectHeaders(false);
    setSelectRequest(false);
    setSelectRequestBody(false);
    setSelectResponse(false);
    setSelectedNode(null);
    setEditFlow({});
  }
  // #--> BotIsActive
  const handleBotIsActive = async (e, bot) => {
    let enabled = e.target.checked
    let json = {
      ...bot,
      enabled: enabled
    }

    const validate = listBots.find(v => v.enabled == true) 
    if(validate && enabled == true) {
      setListBots([])
      await getBots(true)
      Swal.fire({
        title: "Atención!",
        text: "No se puede tener más de un Bot activo. Por favor desactiva el que tengas en uso para poder activar este.",
        icon: "info",
        confirmButtonText: "Aceptar",
      });
      return
    }

    setLoading(true);
    const url = urlAPI_3 + `chatBot/${json._id}`;
    const options = { method: "PUT", mode: "cors", body: JSON.stringify(json)};
    await fetchWithAuth(url, options);

    getBots(false);
    setLoading(false);
    Swal.fire({
      title: "¡Bien!",
      text: "Estatus del Bot actualizado",
      icon: "success",
      confirmButtonText: "Aceptar",
    });
  }
  // #--> BotConfig
  const handleBotConfig = async () => {
    
    if(selectControlMessage && !configBot?.controlMessage) {
      setValidateMessageConfig(true)
      return
    }

    let json = {
      ...configBot.bot,
      inactivityTime: configBot.inactivityTime.value,
      inactivityTimeClose: configBot.inactivityTimeClose.value,
      inactivityMessages: configBot.inactivityMessages,
      companySchedule: configBot.companySchedule,
      controlMessage: configBot.controlMessage,
      inactivityStatus: false,
      attemptsSOS: configBot.attemptsSOS,
      waitingMessageSOS: configBot.waitingMessageSOS,
      transferSOS: configBot.transferSOS,
      listSOSMessages: configBot.listSOSMessages
    }

    // wrapper to allow all messages types in the future...
    if(json.inactivityMessages){
      json.inactivityMessages = [{type: "text", text: {body: json.inactivityMessages}}];
      json.inactivityStatus = true;
    }

    setLoading(true);
    const url = urlAPI_3 + `chatBot/${json._id}`;
    const options = { method: "PUT", mode: "cors", body: JSON.stringify(json)};
    await fetchWithAuth(url, options);
    getBots(false);
    setValidateMessageConfig(false);
    setSelectSOS(false);
    setSelectTimeInactivity(false);
    setSelectCompanySchedule(false);
    setSelectControlMessage(false);
    setLoading(false);
  }
  // #--> Update name bot
  const updateNameBot = async (bot) => {
    let json = {
      ...bot,
      name: editName
    }

    setLoading(true);
    const url = urlAPI_3 + `chatBot/${json._id}`;
    const options = { method: "PUT", mode: "cors", body: JSON.stringify(json)};
    await fetchWithAuth(url, options);

    setLoading(false);
    setShowEditName(false);
    setNameBot(editName);
    setEditName("");
  }
  const validateFlowsTags = (flows) => {
    let tagFlow = null;
    let updatedFlowId = null;

    // Buscar el flujo con 'tags' en su id
    for (let flow of flows) {
      if (flow.id.includes('tags')) {
        tagFlow = flow;
        break;
      }
    }

    if (!tagFlow) {
      return flows;  // No se encontró el flujo de tags, devolvemos el array original
    }

    // Si `transitions` no está vacío
    if (tagFlow.transitions && tagFlow.transitions.length > 0) {
      const nextFlowId = tagFlow.transitions[0].next_flow_id;

      // Buscar el siguiente flujo por `next_flow_id`
      for (let flow of flows) {
        if (flow.id === nextFlowId) {
          // Concatenar `actions` del siguiente flujo
          flow.actions = flow.actions.concat(tagFlow.actions);
          updatedFlowId = flow.id;
          break;
        }
      }

      // Eliminar solo el flujo de 'tags' específico
      flows = flows.filter(flow => flow.id !== tagFlow.id);
    }

    // Actualizar las transiciones de los otros flujos si contienen el id del flujo eliminado
    if (updatedFlowId) {
      for (let flow of flows) {
        if (flow.transitions) {
          for (let transition of flow.transitions) {
            if (transition.next_flow_id === tagFlow.id) {
              transition.next_flow_id = updatedFlowId;
            }
          }
        }
      }
    }

    return flows;
  }
  const validateFlowsSaveAnswer = (flows) => {
    let saveAnswerFlow = null;
    let updatedFlowId = null;

    // Buscar el flujo con 'saveAnswer' en su id
    for (let flow of flows) {
      if (flow.id.includes('saveAnswer')) {
        saveAnswerFlow = flow;
        break;
      }
    }

    if (!saveAnswerFlow) {
      return flows;  // No se encontró el flujo de saveAnswer, devolvemos el array original
    }

    // Si `transitions` no está vacío
    if (saveAnswerFlow.transitions && saveAnswerFlow.transitions.length > 0) {
      const nextFlowId = saveAnswerFlow.transitions[0].next_flow_id;

      // Buscar el siguiente flujo por `next_flow_id`
      for (let flow of flows) {
        if (flow.id === nextFlowId) {
          // Concatenar `actions` del siguiente flujo
          flow.actions = flow.actions.concat(saveAnswerFlow.actions);
          updatedFlowId = flow.id;
          break;
        }
      }

      // Eliminar solo el flujo de 'saveAnswer' específico
      flows = flows.filter(flow => flow.id !== saveAnswerFlow.id);
    }

    // Actualizar las transiciones de los otros flujos si contienen el id del flujo eliminado
    if (updatedFlowId) {
      for (let flow of flows) {
        if (flow.transitions) {
          for (let transition of flow.transitions) {
            if (transition.next_flow_id === saveAnswerFlow.id) {
              transition.next_flow_id = updatedFlowId;
            }
          }
        }
      }
    }

    return flows;
  }
  const findMoreTrigger = (edges, nodes, nodeId) => {
    const filterEdges = edges.filter(edge => edge.target == nodeId && edge.targetHandle.includes('target-return'))
    let triggers = []
    if(filterEdges.length) {
      filterEdges.forEach(edge => {
        const findNode = nodes.find(node => node.id == edge.source)
        if(findNode) {
          let sHandle = edge.sourceHandle
          sHandle = sHandle.replace(/source-/, '');;
          sHandle = sHandle.replace(/-\d+$/, '');
          const findOption = findNode.data.options.find(opt => opt.id == sHandle)

          if(findOption){
            triggers.push({
              type: 'message',
              pattern: findOption.label,
            })
          }
        }
      })
    }
    return triggers
  }

  // RESOURCES
  const getAreas = async () => {
    try {
      const url = urlAPI_2 + "getAreasByCompany";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
        setListAreas([])
      }else{
        const opts = result.data.map(v => {
          return {
            label: v.description,
            value: v._id,
            type: "area"
          }
        })
        setListAreas(opts);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAgents = async () => {
    try {
      const url = urlAPI_2 + "usersbyArea";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (result.message === "Tu petición no tiene cabecera de autorización") {
        Swal.fire({
          title: "¡Atención!",
          text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
          icon: "info",
        });
        navigate("/");
      } else if (result.data.length <= 0) {
        setListAdvisers([]);
      } else {
        const opts = result.data.map(v => {
          return {
            label: v.name,
            value: v._id,
            type: "agent"
          }
        })
        setListAdvisers(opts);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getTags = async () => {
    try {
      const url = urlAPI_1 + `tags`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if(!result.data) {
        setListTags([]);
      } else {
        setListTags(result.data);
      }
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };
  const getCorresp = async () => {
    try {
      const url = urlAPI_2 + "getDataEmail";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      let data = result.data
      // if(data.length) {
      //   data = data.filter(v => v.field !== "phone")
      // }
      setCorrespondence(data);
      if (result.message === "Tu petición no tiene cabecera de autorización") {
        Swal.fire({
          title: "¡Atención!",
          text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
          icon: "info",
        });
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getFlows = async () => {
    try {
      const url = urlAPI_2 + "flows";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.type === "error") {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      }else if (result.message === 'Tu petición no tiene cabecera de autorización') {
        Swal.fire({
          title: "¡Atención!",
          text: 'Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales',
          icon: 'info',
        });
        navigate('/');
      }  else if (!result.data) {
        setFlows([]);
      } else {
        setFlows(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAssistants = async () => {
    try {
      const url = urlAPI_3 + "assistants";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.type === "error") {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (!result.data) {
        setListAssistants([])
      } else {
        setListAssistants(result.data)
      }
    } catch (error) {
      console.log('error---->', error);
    }
  };
  const getOptionsInactivity = () => {
    let options = []
    for(let i = 1; i <= 15; i++) {
      options.push({
        label: i + ' min',
        value: i
      })
    }
    
    setListInactivity(options) 
  };
  const getOptionsAttempts = () => {
    let options = []
    for(let i = 1; i <= 10; i++) {
      options.push({
        label: i + ' intento/s',
        value: i
      })
    }
    
    setListAttempts(options) 
  };

  // RESOURCES 
  const onFileInput = async (e, type_file) => {

    const conversionFunctions = {
      image: convertBase64,
      video: async (file) => URL.createObjectURL(file),
      document: convertDocumentBase64,
      audio: convertAudioBase64,
    };
    
    const conversionFunction = conversionFunctions[type_file];
    if (conversionFunction) {
      let file = e.target.files[0];
      const base64 = await conversionFunction(file);
      if (base64) {
        let file_save = {
          name: file.name,
        }
        const data_file = await uploadToS3(file)
        if(!data_file.error) {
          file_save['url'] = data_file.data.url
          file_save['type'] = data_file.data.type
        }

        setSelectedNode((prevInputValue) => ({
          ...prevInputValue,
          data: {
            ...prevInputValue.data,
            file: file_save
          },
        }))
      }
    }
  };
  const convertBase64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };
  const convertDocumentBase64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };
  const convertAudioBase64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };
  const getSignedUrl = async (key_name, size) => {
    try {
      key_name = encodeURIComponent(key_name);
      const url = urlAPI_1 + `getSignedURL/${key_name}/${size}`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      if(response.status === 200){
          let url_signed = await response.json();
          if(url_signed.error){
              return false;
          }
          return url_signed;
      }else{
          console.error('Error getting signed URL');
          return false;
      }
    } catch (error) {
      console.error('Error getting signed URL:', error);
      return false;
    }
  };
  const uploadToS3 = async (file) => {
    try {
      setLoading(true);
      const max_size = checkMaxSize(file);
      if (max_size) {
        setLoading(false);
        return {error: true, description: "tam_limit_error", data: {name: file.name, size: file.size, file: file, type: file.type}};
      }else {
        const signedUrl = await getSignedUrl(file.name, file.size);
        if(signedUrl){
          const url = await upload_s3(file, signedUrl);
          setLoading(false);
          return {error: false,  data: {url: url, name: file.name, size: file.size, file: file, type: file.type}};
        }
        setLoading(false);
        return {error: true, description: "signed_url_error", data: {name: file.name, size: file.size, file: file, type: file.type}};
      }
    } catch (error) {
      setLoading(false);
      return {error: true, description: "unknown_error", data: error, data: {name: file.name, size: file.size, file: file, type: file.type}};
    }
  };
  const checkMaxSize = (file) => {
    let max_size = false;
    if(file.type.includes("audio") && file.size > ((1024 * 1024) * 16)){
      max_size = true;
      Swal.fire({
        title: "¡Atención!",
        text: `El archivo ${file.name} supera el peso máximo permitido para audio: 16MB. Intente enviarlo como documento una vez finalizado el proceso de carga.`,
        icon: "info",
      });
    }
    if(file.type.includes("video") && file.size > ((1024 * 1024) * 16)){
      max_size = true;
      Swal.fire({
        title: "¡Atención!",
        text: `El archivo ${file.name} supera el peso máximo permitido para video: 16MB. Intente enviarlo como documento una vez finalizado el proceso de carga.`,
        icon: "info",
      });
    }
    if(file.type.includes("image") && file.size > ((1024 * 1024) * 5)){
      max_size = true;
      Swal.fire({
        title: "¡Atención!",
        text: `El archivo ${file.name} supera el peso máximo permitido para imagen: 5MB. Intente enviarlo como documento una vez finalizado el proceso de carga.`,
        icon: "info",
      });
    }
    if(file.type.includes("document") && file.size > ((1024 * 1024) * 100)){
      max_size = true;
      Swal.fire({
        title: "¡Atención!",
        text: `El archivo ${file.name} supera el peso máximo permitido para documentos: 100MB. Intente enviarlo como documento una vez finalizado el proceso de carga.`,
        icon: "info",
      });
    }
    return max_size;
  }
  const getIsDark = () => {
    const dark = localStorage.getItem("THEME");
    setIsDark(dark);
  }
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightblue' : '#fff',
      color: state.isSelected ? '#333' : '#333',
      '&:hover': {
        backgroundColor: 'lightgray',
        color: '#333',
        cursor: 'pointer',
      },
    }),
  };
  const customStylesDark = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#455a64' : '#202c33',
      color: state.isSelected ? 'white' : 'white',
      '&:hover': {
        backgroundColor: '#374954',
        color: 'white',
        cursor: 'pointer',
      },
    }),
  };

  const onAditionalHeaders = () => {
    if (selectHeaders) {
      setSelectHeaders(false);
    } else {
      setSelectHeaders(true);
    }
  };
  const onAditionalRequest = () => {
    if (selectRequest) {
      setSelectRequest(false);
    } else {
      setSelectRequest(true);
    }
  };
  const onAditionalRequestBody = () => {
    if (selectRequestBody) {
      setSelectRequestBody(false);
    } else {
      setSelectRequestBody(true);
    }
  };
  const onAditionalResponse = () => {
    if (selectResponse) {
      setSelectResponse(false);
    } else {
      setSelectResponse(true);
    }
  };
  const onAditionalTimeInactivity = () => {
    if (selectTimeInactivity) {
      setSelectTimeInactivity(false);
    } else {
      setSelectTimeInactivity(true);
      setSelectCompanySchedule(false);
      setSelectControlMessage(false);
      setSelectSOS(false);
    }
  };
  const onAditionalSOS = () => {
    if (selectSOS) {
      setSelectSOS(false);
    } else {
      setSelectSOS(true);
      setSelectTimeInactivity(false);
      setSelectCompanySchedule(false);
      setSelectControlMessage(false);
    }
  };
  const onAdvancedOptions = () => {
    if (selectAdvancedOptions) {
      setSelectAdvancedOptions(false);
    } else {
      setSelectAdvancedOptions(true);
    }
  };
  const onAditionalCompanySchedule = () => {
    if (selectCompanySchedule) {
      setSelectCompanySchedule(false);
    } else {
      setSelectCompanySchedule(true);
      setSelectTimeInactivity(false);
      setSelectControlMessage(false);
    }
  };
  const onAditionalControlMessage = () => {
    if (selectControlMessage) {
      setSelectControlMessage(false);
    } else {
      setSelectControlMessage(true);
      setSelectCompanySchedule(false);
      setSelectTimeInactivity(false);
      setSelectSOS(false);
    }
  };

  const requestApiNode = async () => {
    setLoading(true);
    let url = selectedNode.data.url_api;
    let options = {
      method: selectedNode.data.protocol.id,
      headers: {}
    };

    if (connectionTestData?.headers?.length) {
      options.headers = connectionTestData.headers.reduce((acc, header) => {
        acc[header.key] = header.value;
        return acc;
      }, {});
    }

    if (selectedNode.data.protocol.id !== 'GET') {
      options.body = JSON.stringify({});
      if (connectionTestData?.data?.length) {
        options.body = JSON.stringify(
          connectionTestData.data.reduce((acc, param) => {
            acc[param.key] = param.value;
            return acc;
          }, {})
        );
      }
    }

    if (connectionTestData?.params?.length) {
      const params = new URLSearchParams();
      connectionTestData.params.forEach(param => {
          params.append(param.key, param.value);
      });
      url += `?${params.toString()}`;
    }

    try {
      const response = await fetch(url, options);
    
      if (!response.ok) {
        throw new Error('Error en la respuesta de la consulta');
      }
    
      const blob = await response.blob();
      let result;
    
      try {
        const text = await blob.text();
        result = JSON.parse(text);
        const res = convertToTree(result, "response")
        const res2 = convertToTreeResToNode(result, "response")
        setListRequestParams([res]);
        setListOptionsResToNode([res2])
      } catch (e) {
        result = await blob.text();
      }
  
      if (!result || (Array.isArray(result) && result.length <= 0)) {
        Swal.fire({
          title: "Atención!",
          text: "Ocurrió un error al realizar la consulta, por favor valida los datos necesarios para realizar la consulta",
          icon: "error",
          type: "error",
          confirmButtonText: "Aceptar",
        });
      } else {
        setRequestResponse(result);
      }
    } catch (error) {
      console.log("error: ", error);
      Swal.fire({
        title: "Atención!",
        text: "Ocurrió un error al realizar la consulta, por favor valida los datos necesarios para realizar la consulta",
        icon: "error",
        type: "error",
        confirmButtonText: "Aceptar",
      });
    } finally {
      setLoading(false);
    }
    
  };
  const convertToTree = (item, key) => {
    if (item === null || item === undefined) {
      return { id: key, label: key };
    }

    if (Array.isArray(item)) {
      const children = item.map((subItem, index) => convertToTree(subItem, `${key}[${index}]`));
      return { id: key, label: key, children };
    } else if (typeof item === "object") {
      const children = Object.keys(item).map((subKey) => convertToTree(item[subKey], `${key}.${subKey}`));
      return { id: key, label: key, children };
    } else {
      return { id: key, label: key };
    }
  };
  const convertToTreeResToNode = (item, key, parentKey = '') => {
    const uniqueKey = parentKey ? `${parentKey}.${key}` : key;
  
    if (item === null) {
      return { id: uniqueKey, label: `${key}: null` };
    }
  
    if (item === undefined) {
      return { id: uniqueKey, label: `${key}: undefined` };
    }
  
    if (Array.isArray(item)) {
      const children = item.map((subItem, index) => convertToTreeResToNode(subItem, `${key}[${index}]`, uniqueKey));
      return { id: uniqueKey, label: key, children };
    } else if (typeof item === "object") {
      const children = Object.keys(item).map((subKey) => convertToTreeResToNode(item[subKey], subKey, uniqueKey));
      return { id: uniqueKey, label: key, children };
    } else {
      return { id: uniqueKey, label: `${key}: ${item}` };
    }
  };
  const handleItemValSelect = (event, ids) => {
    setResponseParams((prev) => ({
      ...prev,
      key: ids
    }))
  };
  const nodeColor = (node) => {
    if(node.id.includes('basicAnswer')) {
      return nodeTypesColors['basicAnswer']
    }
    if(node.id.includes('bottomNode')) {
      return nodeTypesColors['bottomNode']
    }
    if(node.id.includes('transferAgent')) {
      return nodeTypesColors['transferAgent']
    }
    if(node.id.includes('tags')) {
      return nodeTypesColors['tags']
    }
    if(node.id.includes('assistantAI')) {
      return nodeTypesColors['assistantAI']
    }
    if(node.id.includes('saveAnswer')) {
      return nodeTypesColors['saveAnswer']
    }
    if(node.id.includes('flowsWhatsApp')) {
      return nodeTypesColors['flowsWhatsApp']
    }
    if(node.id.includes('queryApi')) {
      return nodeTypesColors['queryApi']
    }
  }

  const handleClickModalVariables = (event, typeValue, type) => {
    setAnchorEl(event.currentTarget);
    setVariablesModal(true);
    setTypeInputValue(typeValue);
    setTypeInput(type);
  };
  const validateLengthOptions = () => {
    const some = selectedNode.data.options.some(v => v.label.length > (selectedNode.data.options.length > 3 ? 24 : 20))
    if(some) {
      return true;
    } else {
      return false;
    }
  };

  const responseToNode = (event) => {
    if(Array.isArray(requestResponse)) {
      setAnchorEl2(event.currentTarget);
      setResponseToNodeModal(true);
    } else {
      Swal.fire({
        title: "Atención!",
        text: "La respuesta del api no es una lista",
        icon: "error",
        type: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };
  const findNodeById = (nodes, id) => {
    for (let node of nodes) {
      if (node.id === id) {
        return node;
      }
      if (node.children) {
        const childResult = findNodeById(node.children, id);
        if (childResult) {
          return childResult;
        }
      }
    }
    return null;
  };
  const convertToNode = () => {
    const position = screenToFlowPosition({ x: 250, y: 15 });

    const newNode = {
      id: getId() + "-basicAnswer",
      type: 'basicAnswer',
      data: { 
        title: 'Respues Básica',
        bot_response: listToNodeData.description,
        user_response: "*",
        options: listToNodeData.options,
        file: null,
        variables: null,
        saved: true
      },
      position,
    };

    setNodes((nds) => [...nds, newNode]);
    let newNodes = [...nodes, newNode]
    setTimeout(() => {
      addNewNode(editFlow, newNodes, newNode)
    }, 500);

    setResponseToNodeModal(false);
    setVariablesOptionsModal(false);
    setListToNodeData({
      options: [],
      description: "",
    });
    setConnectionTestData({
      headers: [],
      params: [],
      data: []
    });
  };

  // Validations of advanced options
  const validateNodeAcceptTerms = () => {
    const find = nodes.find(node => node?.data?.advanced?.isAcceptTerms?.id === true);
    return find ? find.id == selectedNode?.id : false;
  };
  const validateNodeRequestName = () => {
    const find = nodes.find(node => node?.data?.advanced?.request_name?.id === true);
    return find ? find.id !== selectedNode?.id : false;
  }; 
  const validateNodeConfirmName = () => {
    const find = nodes.find(node => node?.data?.advanced?.confirm_name?.id === true);
    return find ? find.id !== selectedNode?.id : false;
  }; 
  const validateNodeEditName = () => {
    const find = nodes.find(node => node?.data?.advanced?.edit_name?.id === true);
    return find ? find.id !== selectedNode?.id : false;
  }; 

  useEffect(() => {
    getIsDark();
    getOptionsInactivity();
    getOptionsAttempts();

    const fetchData = async () => {
      try {
        await Promise.all([
          getAgents(),
          getBotsTemplates(),
          getAreas(),
          getTags(),
          getCorresp(),
          getFlows(),
          getAssistants()
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [mode]);
  
  useEffect(() => {
    setNodes((nds) => 
      nds.map((node) => {
        if (selectedNode && node.id === selectedNode.id) {
          node.data = {
            ...selectedNode.data
          };
        }

        return node;
      })
    )
  }, [selectedNode]);


  return (
    <>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      {mode === "list" && (
        <>
          <div className="m-auto container-fluid p-0 h-100">
            <div className="col-xs-9 heading">
              <div className="mt-2">
                <h1>Constructor de Bots</h1>
                <input
                  type="button"
                  className="btn btn-default"
                  value="Nuevo Bot"
                  onClick={() => {
                    setSelectedTemplateModal(true)
                  }}
                />
              </div>

              <Header />
            </div>

            <header>
              <div style={{ float: "right" }}>
                <form className="form-inline">
                  <div className="su-control my-1">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Búsqueda"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <button
                      className="btn btn-info"
                      type="button"
                      id="buttonSearchProductBack"
                      style={{ height: "34px" }}
                      onClick={handleSearch}
                    >
                      <BsSearch />
                    </button>
                  </div>
                </form>
              </div>
            </header>

            <div className="container-fluid mt-3 px-0" style={{ height:"75%", overflowY:"auto", overflowX:"none" }}>
              <table className="table table-striped table-campaigns">
                <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
                  <tr>
                    <th>Acciones</th>
                    <th>Activar/Desactivar</th>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Estado</th>
                    <th>Creación</th>
                  </tr>
                </thead>
                <tbody>
                  {listBots.length > 0 && listBots.slice((page - 1) * byPage, (page - 1) * byPage + byPage).map((bot) => (
                    <tr key={bot._id}>
                      <td>
                        <div className="row justify-content-around">
                          <div className="dropdown">
                            <button
                              className="bg-transparent border-0"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <FaEllipsisVertical className="text-success f-24 pointer" />
                            </button>
                            <div
                              className="dropdown-menu p-2"
                              aria-labelledby="dropdownMenuButton"
                            > 
                              <button
                                onClick={() => {
                                  setMode("create")
                                  setNodes(bot.nodes)
                                  setEdges(bot.edges)
                                  setEditFlow(bot)
                                  setNameBot(bot.name)
                                  setDescriptionBot(bot.description)
                                }}
                                className="text-primary border-0 bg-transparent d-block pointer mt-2"
                              >
                                <BsPencilFill style={{ fontSize: "18px" }}/>
                                <span className="text-dark ml-2 f-14">
                                  Editar
                                </span>
                              </button>
                              <button className="text-danger border-0 bg-transparent d-block pointer mt-2" onClick={() => deleteBot(bot._id)}>
                                <BsTrash3 style={{ fontSize: "18px" }}/>
                                <span className="text-dark ml-2 f-14">
                                  Eliminar
                                </span>
                              </button>
                              <button className="text-muted border-0 bg-transparent d-block pointer mt-2" onClick={() => {
                                console.log("bot: ", bot);
                                console.log(bot.inactivityMessages != null);
                                setConfigBot({
                                  inactivityTime: listInactivity.find(v => v.value == bot.inactivityTime) || null,
                                  inactivityTimeClose: listInactivity.find(v => v.value == bot.inactivityTimeClose) || null,
                                  inactivityMessages: bot.inactivityMessages?.length ? bot.inactivityMessages[0]?.text?.body : null,
                                  companySchedule: companyScheduleOptions.find(v => v.value == bot.companySchedule) || null,
                                  controlMessage: bot.controlMessage ?? null,
                                  attemptsSOS: bot.attemptsSOS ?? null,
                                  waitingMessageSOS: bot.waitingMessageSOS ?? null,
                                  transferSOS: bot.transferSOS ?? null,
                                  listSOSMessages: bot.listSOSMessages ?? null,
                                  bot: bot
                                })
                                setConfigModal(true)
                              }}>
                                <CiSettings style={{ fontSize: "25px" }}/>
                                <span className="text-dark ml-2 f-14">
                                  Configuración
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center align-items-center">
                          <input
                            type="checkbox"
                            value={bot.enabled}
                            className="cmn-toggle cmn-toggle-round-flat"
                            name={bot._id}
                            id={bot._id}
                            defaultChecked={bot.enabled}
                            onChange={(e) => {
                              handleBotIsActive(e, bot)
                            }}
                          />
                          <label className="m-0" htmlFor={bot._id}></label>
                        </div>
                      </td>
                      <td>
                        {bot?.name}
                      </td>
                      <td>
                        {
                          bot?.description.length >= 35 ? 
                            bot?.description.substr(0, 35) + "..."
                            : bot?.description
                        }
                      </td>
                      <td className="status-template">
                        <p>
                          <a
                            href="javascript:;"
                            className={
                              bot.enabled
                                ? "green"
                                : "red"
                            }
                          >
                            {bot.enabled ? 'ACTIVO' : 'INACTIVO'}
                          </a>
                        </p>
                      </td>
                      <td>
                        Creada el{" "}
                        {dayjs(bot.created)
                          .locale("es")
                          .format("DD MMMM YYYY")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {validation && (
                <div className="text-center mt-3">
                  <div className="empty">
                    <FaRobot style={{ fontSize: "55px" }}/>
                    <h1>No tienes Bots</h1>
                    <input
                      type="button"
                      className="btn btn-default"
                      value="Agregar Nuevo"
                      onClick={() => {
                        setSelectedTemplateModal(true)
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            <footer>
              <div className="row">
                <div className="col-md-8"></div>

                <div className="col-md-4">
                  {/* Creación de la paginación simple */}
                  <Pagination
                    totalData={listBots.length}
                    byPage={byPage}
                    setPage={setPage}
                  />
                </div>
              </div>
            </footer>
          </div>
        </>
      )}

      {mode === "create" && (
        <>
          <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-2" style={{height:"6%"}}>
            <div style={{ height: "45px", lineHeight: "95px" }}>
              <button
                type="button"
                className="btn btn-left col-1-sm-3 my-auto position-absolute d-flex"
                onClick={() => {
                  setMode("list");
                  setFlows([]);
                  reset();
                  getBots();
                }}
              >
                <FaAngleLeft className="mr-1" /> Bots
              </button>
            </div>

            <Header />
          </div>
          <section className="module-create-user" style={{ width: "100%", height:"90%" }}>
            <header className="message" style={{ height:"10%" }}>
              <div className="row justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-left col-1-sm-3 d-flex align-items-center"
                    onClick={() => {
                      setMode("list");
                      setFlows([]);
                      reset();
                      getBots();
                    }}
                  >
                    <FaAngleLeft className="mr-1" /> {nameBot} 
                  </button>

                  <button
                    type="button"
                    className="btn btn-left col-1-sm-3 d-flex align-items-center"
                    onClick={() => setShowEditName(true)}
                  >
                    <BsPencilFill onClick={() => {
                      setShowEditName(true);
                      setEditName(nameBot);
                    }}/>
                  </button>
                </div>
              </div>
            </header>
            <div style={{ height: '90%' }} className="reactflow-wrapper" ref={reactFlowWrapper}>
              <ReactFlow 
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onReconnect={onReconnect}
                onReconnectStart={onReconnectStart}
                onReconnectEnd={onReconnectEnd}
                onDrop={onDrop}
                onDragOver={onDragOver}
                nodeTypes={memoizedNodeTypes}
                onNodeClick={handleNodeClick}
                minZoom={0.2}
                maxZoom={2}
                fitView
              > 
                <Background color="#5BC0DE" variant={BackgroundVariant.Background}/>
                <MiniMap nodeStrokeWidth={3} pannable zoomable nodeColor={nodeColor}/>
                <Controls/>

                <div 
                  className="card ml-5 mt-4 border border-12 border-black" 
                  style={{width: '300px', maxHeight: "460px", zIndex: 100, boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                    {selectedNode ?
                     (
                      <>
                        {/* Basic Anwser */}
                        {selectedNode.type == 'basicAnswer' && (
                          <>
                            <div className="d-flex align-items-center justify-content-between text-white" style={{height: "50px", padding: "10px", borderBottom: '1px solid #CED4DA', backgroundColor: '#00A884'}}>
                              <div className="d-flex align-items-center w-100 text-truncate">
                                <div className="d-flex align-items-center">
                                  <FiMessageSquare className="mr-1" style={{fontSize: "24px"}}/>
                                </div>
                                <span style={{fontSize: "22px"}} >
                                  {selectedNode.data.title}
                                </span>
                              </div>
                              {!selectedNode.data.first && (
                                <button className="text-white border-0 bg-transparent d-block pointer mt-2" onClick={() => {
                                  removeNode(selectedNode.id)
                                }}>
                                  <BsTrash3 style={{ fontSize: "18px" }}/>
                                </button>
                              )}
                            </div>
                            <div id="content-list-chats" className="card-body" style={{overflowY: 'auto'}}>
                              <div>
                                <div className="col p-0">
                                  <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                  <div>
                                    <input
                                      id="title"
                                      name="title"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.title || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.title) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              title: 'Título base' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            title: e.target.value
                                          },
                                        }))
                                      }
                                    />
    
                                    {!selectedNode.data.title ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Título es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Título identificativo de la respuesta para el diagrama de flujo.
                                      </p>
                                    )}
                                  </div>
                                </div>
                                {!selectedNode.data.first && (
                                  <div className="col p-0 mt-4">
                                    <label htmlFor="user_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del cliente: </label>
                                    <div>
                                      <textarea
                                        id="user_response"
                                        name="user_response"
                                        type="text"
                                        style={{width: "100%", height: "50px", borderRadius: "4px"}}
                                        value={selectedNode.data.user_response || ""}
                                        onChange={(e) => 
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              user_response: e.target.value
                                            },
                                          }))
                                        }
                                      />
                                      {!selectedNode.data.user_response ? (
                                        <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                          Campo Respuesta del cliente es obligatorio
                                        </p>
                                      ): (
                                        <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                          Mensaje que envía el cliente para activar la respuesta.
                                        </p>
                                      )}
                                      <div className="p-1" style={{backgroundColor: 'rgba(0, 168, 132, 0.3)', borderRadius: '4px'}}>
                                        <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                          <span style={{fontSize: 12, fontWeight: 600}}>Importante:</span> Los nodos se pueden activar con multiples respuestas según esten configuradas, 
                                          para ello debe tener el siguiente formato: Respuesta 1 / Respuesta 2 / Respuesta 3
                                          configurado en dicho apartado.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="col p-0 mt-4">
                                  <label htmlFor="bot_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del Bot: </label>
                                  <div>
                                    <textarea
                                      id="bot_response"
                                      name="bot_response"
                                      placeholder="Escribe la respuesta del Bot"
                                      style={{width: "100%", height: "90px", borderRadius: "4px"}}
                                      value={selectedNode.data.bot_response || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.bot_response) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              bot_response: '¡Hola! ¿Cómo puedo ayudarte hoy?' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            bot_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    <div className="d-flex align-items-center">
                                      <div className="pt-2">
                                        <button 
                                          className="text-dark border-0 bg-transparent d-block pointer" 
                                          type="button"
                                          onClick={(event) => handleClickModalVariables(event, 'bot_response', 'node')}
                                        >
                                          <VscVariableGroup style={{ fontSize: "18px" }}/>
                                        </button>
                                      </div>
                                      <div className="picker-container pt-4" style={{width: "100%"}}>
                                        <i
                                          className={
                                            showPicker
                                              ? "icon-smile emoji-icon active"
                                              : "icon-smile emoji-icon"
                                          }
                                          style={{ fontSize: "22px" }}
                                          onClick={() => setShowPicker((val) => !val)}
                                        />

                                        {showPicker && (
                                          <Picker
                                            pickerStyle={{
                                              width: "100%",
                                              top: "30px",
                                              position: "absolute",
                                              zIndex: "999",
                                            }}
                                            onEmojiClick={onEmojiClick}
                                            groupNames={{
                                              smileys_people: "Emoticones y personas",
                                              animals_nature: "Animales y naturaleza",
                                              food_drink: "Alimentos y bebidas",
                                              travel_places: "Viajes y lugares",
                                              activities: "Actividades",
                                              objects: "Objetos",
                                              symbols: "Símbolos",
                                              flags: "Banderas",
                                              recently_used: "Recientes",
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                    
                                    {!selectedNode.data.bot_response || selectedNode.data.bot_response.length > 1024 ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        {!selectedNode.data.bot_response ? 'Campo Respuesta del Bot es obligatorio' 
                                        : 'El campo Respuesta del Bot no debe ser mayor a 1024 caracteres'}
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Respuesta que se le envía al cliente.
                                      </p>
                                    )}
                                  </div>
                                  <div className="row px-3">
                                    <div 
                                      className="mr-2"
                                      style={{
                                        width: "120px",
                                        backgroundColor: '#fff',
                                        border: '1px solid #00A884',
                                        borderRadius: '4px',
                                        color: '#00A884',
                                        padding: '2px',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <input
                                        type="file"
                                        name="image"
                                        id="images"
                                        accept="image/gif,image/jpeg,image/jpg,image/png"
                                        style={{
                                          display: 'none',
                                        }}
                                        onChange={(e) => onFileInput(e, "image")}
                                      />
                                      <label htmlFor="images" title="Imagenes" className="pointer d-flex m-0 pt-1 pl-3">
                                        <FaImages className="mr-2 icon-picture" style={{ fontSize: "20px", color: "#00A884" }}/>
                                        Imagen
                                      </label>
                                    </div>
                                    <div 
                                      style={{
                                        width: "120px",
                                        backgroundColor: '#fff',
                                        border: '1px solid #00A884',
                                        borderRadius: '4px',
                                        color: '#00A884',
                                        padding: '2px',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <input
                                        type="file"
                                        name="videos"
                                        id="videos"
                                        accept="video/*"
                                        style={{ display: "none" }}
                                        onChange={(e) => onFileInput(e, "video")}
                                        multiple
                                      />
                                      <label htmlFor="videos" title="Videos" className="pointer d-flex m-0 pt-1 pl-3">
                                        <BsFillCameraVideoFill className="mr-2 icon-file-video" style={{ fontSize: "20px", color: "#00A884" }}/>
                                        Video
                                      </label>
                                    </div>
                                    <div 
                                      className="mr-2 mt-2"
                                      style={{
                                        width: "120px",
                                        backgroundColor: '#fff',
                                        border: '1px solid #00A884',
                                        borderRadius: '4px',
                                        color: '#00A884',
                                        padding: '2px',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <input
                                        type="file"
                                        name="document"
                                        id="documents"
                                        accept=".pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/plain, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        style={{ display: "none" }}
                                        onChange={(e) => onFileInput(e, "document")}
                                      />
                                      <label htmlFor="documents" title="Documentos" className="pointer d-flex m-0 pt-1 px-1">
                                        <BsFillFileEarmarkTextFill className="mr-1 icon-doc" style={{ fontSize: "18px", color: "#00A884" }}/>
                                        Documento
                                      </label>
                                    </div>
                                    <div 
                                      className="mt-2"
                                      style={{
                                        width: "120px",
                                        backgroundColor: '#fff',
                                        border: '1px solid #00A884',
                                        borderRadius: '4px',
                                        color: '#00A884',
                                        padding: '2px',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <input
                                        type="file"
                                        name="audio"
                                        id="audios"
                                        accept="audio/*"
                                        style={{ display: "none" }}
                                        onChange={(e) => onFileInput(e, "audio")}
                                        multiple
                                      />
                                      <label htmlFor="audios" title="Audios" className="pointer d-flex m-0 pt-1 pl-3">
                                        <FaHeadphones className="mr-2 icon-headphones" style={{ fontSize: "20px", color: "#00A884" }}/>
                                        Audio
                                      </label>
                                    </div>
                                    <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                      Multimedia que puedes cargar en la respuesta.
                                    </p>

                                    {selectedNode.data.file && Object.keys(selectedNode.data.file).length > 0 && (
                                      <button
                                        type="button"
                                        style={{
                                          backgroundColor: '#00A884',
                                          border: 0,
                                          borderRadius: '4px',
                                          color: '#fff',
                                          cursor: 'pointer',
                                          fontFamily: 'Helvetica, Arial, sans-serif',
                                          fontSize: '14px',
                                          fontWeight: 'bold',
                                          height: '30px',
                                          width: '100%'
                                        }}
                                        onClick={() => 
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              file: null
                                            },
                                          }))
                                        }
                                      > 
                                        <BsTrash3 className="mr-1"/>
                                        Eliminar Multimedia
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="col p-0 mt-2">
                                  <div>
                                    {selectedNode.data.options && selectedNode.data.options.map((option, index) => {
                                      return (
                                        <div className="col p-0 mt-2" key={index}>
                                          <div>
                                            <div className={`d-flex ${selectedNode?.data?.options?.length > 3 ? 'd-flex align-items-center' : 'align-items-end'}`}>
                                              <div>
                                                <div>
                                                  <label htmlFor={option.name} style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                                  <input
                                                    id={option.name}
                                                    name={option.name}
                                                    type="text"
                                                    style={{width: selectedNode.data.options.length > 3  ? "95%" : "100%", height: "35px"}}
                                                    value={option.label || ""}
                                                    onChange={(e) => {
                                                      setSelectedNode((prevInputValue) => {
                                                        const length = prevInputValue.data.options.length
                                                        prevInputValue.data.options[index].label = e.target.value
                                                        prevInputValue.data.options[index].id = selectedNode.id+'-'+(length+1)+'-'+e.target.value.toLowerCase() 
                                                        .replace(/[^a-z0-9]+/g, '-') 
                                                        .replace(/(^-|-$)/g, '');

                                                        return {
                                                          ...prevInputValue,
                                                          data: {
                                                            ...prevInputValue.data,
                                                            options: prevInputValue.data.options,
                                                          }
                                                        }
                                                      })
                                                    }}
                                                  />
                                                </div>
                                                {selectedNode.data.options.length > 3 && (
                                                  <div className="mt-2">
                                                    <label htmlFor={`${option.name}_description`} style={{color: '#6C757D', fontWeight: 200}}>Descripción (opcional): </label>
                                                    <textarea
                                                      id={`${option.name}_description`}
                                                      name={`${option.name}_description`}
                                                      style={{width: selectedNode.data.options.length > 3  ? "95%" : "100%", height: "35px"}}
                                                      value={option?.description || ""}
                                                      maxLength={76}
                                                      onChange={(e) => {
                                                        setSelectedNode((prevInputValue) => {
                                                          prevInputValue.data.options[index].description = e.target.value

                                                          return {
                                                            ...prevInputValue,
                                                            data: {
                                                              ...prevInputValue.data,
                                                              options: prevInputValue.data.options,
                                                            }
                                                          }
                                                        })
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                              <button 
                                                className="text-danger border-0 bg-transparent d-block pointer" 
                                                onClick={() => {
                                                  const newOptions = selectedNode.data.options.filter((v, i) => i !== index)
                                                  setSelectedNode((prevInputValue) => {
                                                    return {
                                                      ...prevInputValue,
                                                      data: {
                                                        ...prevInputValue.data,
                                                        options: newOptions,
                                                      }
                                                    }
                                                  })
                                                  const newNodes = nodes.filter(v => v.id !== option.idNode)
                                                  setNodes(newNodes)
                                                }}
                                              >
                                                <BsTrash3 style={{ fontSize: "18px" }}/>
                                              </button>
                                            </div>
                                            {!option.label || option.label.length > (selectedNode.data.options.length > 3 ? 24 : 20) ? (
                                              <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                                {!option.label? 'Campo para el botón es obligatorio' 
                                                : `El campo para el botón no debe ser mayor a ${selectedNode.data.options.length > 3 ? "24" : "20"} caracteres`}
                                              </p>
                                            ): (
                                              <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                                Mensaje que envía el cliente para activar la respuesta.
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>

                                  <button
                                    type="button"
                                    style={{
                                      backgroundColor: '#00A884',
                                      border: 0,
                                      borderRadius: '4px',
                                      color: '#fff',
                                      cursor: 'pointer',
                                      fontFamily: 'Helvetica, Arial, sans-serif',
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      height: '30px',
                                      width: '100%'
                                    }}
                                    onClick={() => {
                                      if(selectedNode.data.options.length >= 10) {
                                        return Swal.fire({
                                          title: "¡Atencíon!",
                                          text: "Solo se pueden agregar 10 botones u opciones como máximo.",
                                          icon: "info",
                                          confirmButtonText: "Etendido",
                                        });
                                      }

                                      setSelectedNode((prevValue) => {
                                        const length = prevValue.data.options.length
                                        const item = {
                                          label: 'Opción ' + (length +1),
                                          id: selectedNode.id+'-'+(length+1)+'-Opción-' + (length +1),
                                          name: 'titulo',
                                        }
                                        prevValue.data.options.push(item)

                                        if(length == 3) {
                                          prevValue.data.options = prevValue.data.options.map(v => {
                                            return {
                                              ...v,
                                              description: ""
                                            }
                                          })
                                        }

                                        return {
                                          ...prevValue,
                                          data: {
                                            ...prevValue.data,
                                            options: prevValue.data.options,
                                          }
                                        }
                                      })
                                    }}
                                  > 
                                    <AiOutlineSelect className="mr-1"/>
                                    Añadir botón interactivo
                                  </button>
                                  <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                    Al ingresar más de tres botones se enviarán como lista.
                                  </p>
                                </div>
                                <div className="col p-0 mt-2">
                                  <div className="d-flex align-items-center">
                                    <input
                                      name="dynamic_buttons"
                                      id="dynamic_buttons"
                                      type="checkbox"
                                      value={selectedNode.data?.advanced?.dynamic_buttons || false}
                                      className="cmn-toggle cmn-toggle-round-flat"
                                      checked={selectedNode.data?.advanced?.dynamic_buttons || false}
                                      onChange={(e) => {
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            advanced: {
                                              ...prevInputValue.data?.advanced,
                                              dynamic_buttons: e.target.checked,
                                            },
                                          },
                                        }));
                                      }}
                                    />
                                    <label className="mr-2 mb-0" htmlFor="dynamic_buttons"></label>

                                    <div style={{fontSize: 12, color: '#6C757D', fontWeight: 200}}>
                                      Botones dinámicos
                                    </div>
                                  </div>
                                  {selectedNode.data?.advanced?.dynamic_buttons && (
                                    <div>
                                      <input
                                        className="form-control"
                                        id="key"
                                        name="key"
                                        type="text"
                                        value={selectedNode?.data?.advanced?.dynamic_buttons_variable || null}
                                        onChange={(e) => 
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              advanced: {
                                                ...prevInputValue.data?.advanced,
                                                dynamic_buttons_variable: e.target.value
                                              },
                                            },
                                          }))
                                        }
                                      />
                                      <RichTreeView 
                                        items={listRequestParams}
                                        onSelectedItemsChange={(event, ids) => 
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              advanced: {
                                                ...prevInputValue.data?.advanced,
                                                dynamic_buttons_variable: ids,
                                              },
                                            },
                                          }))
                                        }
                                      />
                                    </div>
                                  )}
                                  <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                    Permite generar botones interactivos de forma dinámica 
                                    a partir de una respuesta (API).
                                  </p>
                                </div>
                                <div className="col p-0 mt-2 panel-group collapsible" id="user-collapsible">
                                  <div className="panel panel-default">
                                    <div 
                                      className="panel-heading p-2" 
                                      style={{
                                        border: "1px solid #4F4F4F",
                                        borderRadius: "4px"
                                      }}>
                                      <div id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={onAdvancedOptions}>
                                        <span>
                                          Opciones avanzadas
                                        </span>
                                        {selectAdvancedOptions ?
                                          <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                                          <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                                        }
                                      </div>
                                    </div>

                                    {selectAdvancedOptions ? (
                                      <div 
                                        className="panel-collapse" 
                                        style={{
                                          backgroundColor: "#f9f9f9",
                                          borderRadius: "4px"
                                        }}
                                      >
                                        {selectedNode.data?.first && (
                                          <>
                                            <div className="col p-2 mt-2">
                                              <label>Terminos y condiciones: </label>
                                              <div className="su-control-group d-flex">
                                                <Select
                                                  styles={isDark == 'true' ? customStylesDark : customStyles}
                                                  classNamePrefix="select"
                                                  className="form-control basic-multi-select"
                                                  placeholder="Seleccione..."
                                                  value={selectedNode?.data?.advanced?.isAcceptTerms || false}
                                                  name="isAcceptTerms"
                                                  onChange={(term) => {
                                                    setSelectedNode((prevInputValue) => ({
                                                      ...prevInputValue,
                                                      data: {
                                                        ...prevInputValue.data,
                                                        advanced: {
                                                          ...prevInputValue.data?.advanced,
                                                          isAcceptTerms: term,
                                                        },
                                                      },
                                                    }));
                                                  }}
                                                  options={[
                                                    { label: 'Si', id: true },
                                                    { label: 'No', id: false },
                                                  ]}
                                                  noOptionsMessage={() => 'Sin opciones'}
                                                  getOptionLabel={(option) => (
                                                    <div className="option d-flex" style={{ fontSize: '15px' }}>
                                                      {option.label}
                                                    </div>
                                                  )}
                                                  getOptionValue={(option) => option.id}
                                                />
                                              </div>
                                              <p style={{fontSize: 11, color: '#6C757D'}}>
                                                <strong>Importante:</strong> Si el nodo requiere <strong>Aceptación de terminos y condiciones</strong>,
                                                la opción o botón que indica que estos han sido aceptados debe contener de manera obligatoria 
                                                en el título la palabra <strong>Acepto</strong> o <strong>Accept</strong>.
                                              </p>
                                            </div>
                                            
                                            <div className="col p-2">
                                              <label>Campañas: </label>
                                              <div className="su-control-group d-flex">
                                                <Select
                                                  styles={isDark == 'true' ? customStylesDark : customStyles}
                                                  classNamePrefix="select"
                                                  className="form-control basic-multi-select"
                                                  placeholder="Seleccione..."
                                                  value={selectedNode?.data?.advanced?.campaign || false}
                                                  name="campaign"
                                                  onChange={(term) => {
                                                    setSelectedNode((prevInputValue) => ({
                                                      ...prevInputValue,
                                                      data: {
                                                        ...prevInputValue.data,
                                                        advanced: {
                                                          ...prevInputValue.data?.advanced,
                                                          campaign: term,
                                                        },
                                                      },
                                                    }));
                                                  }}
                                                  options={[
                                                    { label: 'Si', id: true },
                                                    { label: 'No', id: false },
                                                  ]}
                                                  noOptionsMessage={() => 'Sin opciones'}
                                                  getOptionLabel={(option) => (
                                                    <div className="option d-flex" style={{ fontSize: '15px' }}>
                                                      {option.label}
                                                    </div>
                                                  )}
                                                  getOptionValue={(option) => option.id}
                                                />
                                              </div>
                                              {/* LISTA DE BOTONES PARA CAMPAÑAS */}
                                              {selectedNode?.data?.advanced?.campaign?.id == true && (
                                                <div className="col p-0 mt-2">
                                                  <div>
                                                    {selectedNode?.data?.advanced?.campaign_options?.map((option, index) => {
                                                      return (
                                                        <div className="col p-0 mt-2" key={index}>
                                                          <label htmlFor={`campaign-option-${index}`} style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                                          <div>
                                                            <div className="d-flex">
                                                              <input
                                                                id={`campaign-option-${index}`}
                                                                name={`campaign-option-${index}`}
                                                                type="text"
                                                                style={{width: "100%", height: "35px"}}
                                                                value={option.label || ""}
                                                                onChange={(e) => {
                                                                  setSelectedNode((prevInputValue) => {
                                                                    const length = prevInputValue.data.advanced.campaign_options.length
                                                                    prevInputValue.data.advanced.campaign_options[index].label = e.target.value
                                                                    prevInputValue.data.advanced.campaign_options[index].id = selectedNode.id+'-campaign_option-'+(length+1)+'-'+e.target.value.toLowerCase() 
                                                                    .replace(/[^a-z0-9]+/g, '-') 
                                                                    .replace(/(^-|-$)/g, '');
                
                                                                    return {
                                                                      ...prevInputValue,
                                                                      data: {
                                                                        ...prevInputValue.data,
                                                                        advanced: {
                                                                          ...prevInputValue.data?.advanced,
                                                                          campaign_options: prevInputValue.data?.advanced?.campaign_options,
                                                                        }
                                                                      }
                                                                    }
                                                                  })
                                                                }}
                                                              />
                                                              <button 
                                                                className="text-danger border-0 bg-transparent d-block pointer" 
                                                                onClick={() => {
                                                                  const newOptions = selectedNode?.data?.advanced?.campaign_options?.filter((v, i) => i !== index)
                                                                  setSelectedNode((prevInputValue) => {
                                                                    return {
                                                                      ...prevInputValue,
                                                                      data: {
                                                                        ...prevInputValue.data,
                                                                        options: newOptions,
                                                                      }
                                                                    }
                                                                  })
                                                                  const newNodes = nodes.filter(v => v.id !== option.idNode)
                                                                  setNodes(newNodes)
                                                                }}
                                                              >
                                                                <BsTrash3 style={{ fontSize: "18px" }}/>
                                                              </button>
                                                            </div>
                                                            {!option.label || option.label.length > (selectedNode?.data?.advanced?.campaign_options.length > 3 ? 24 : 20) && (
                                                              <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                                                {!option.label? 'Campo para el botón es obligatorio' 
                                                                : `El campo para el botón no debe ser mayor a ${selectedNode?.data?.advanced?.campaign_options.length > 3 ? "24" : "20"} caracteres`}
                                                              </p>
                                                            )}
                                                          </div>
                                                        </div>
                                                      )
                                                    })}
                                                  </div>
                
                                                  <button
                                                    type="button"
                                                    className="mt-2"
                                                    style={{
                                                      backgroundColor: '#00A884',
                                                      border: 0,
                                                      borderRadius: '4px',
                                                      color: '#fff',
                                                      cursor: 'pointer',
                                                      fontFamily: 'Helvetica, Arial, sans-serif',
                                                      fontSize: '14px',
                                                      fontWeight: 'bold',
                                                      height: '30px',
                                                      width: '100%'
                                                    }}
                                                    onClick={() => {
                                                      setSelectedNode((prevValue) => {
                                                        const length = prevValue.data?.advanced?.campaign_options?.length || 0;
                                                        const item = {
                                                          label: 'Opción ' + (length + 1),
                                                          id: selectedNode.id + '-campaign_option-' + (length + 1) + '-Opción-' + (length + 1)
                                                        };
                                                        
                                                        if (!prevValue.data?.advanced?.campaign_options) {
                                                          prevValue.data = prevValue.data || {};
                                                          prevValue.data.advanced = prevValue.data.advanced || {};
                                                          prevValue.data.advanced.campaign_options = [];
                                                        }
                                                        
                                                        prevValue.data.advanced.campaign_options.push(item);
                                                        return {
                                                          ...prevValue,
                                                          data: {
                                                            ...prevValue.data,
                                                            advanced: {
                                                              ...prevValue.data.advanced,
                                                              campaign_options: prevValue.data.advanced.campaign_options,
                                                            }
                                                          }
                                                        };
                                                      });
                                                    }}                                                    
                                                  > 
                                                    <AiOutlineSelect className="mr-1"/>
                                                    Añadir botón de campaña
                                                  </button>
                                                  <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                                    <strong>Importante: </strong>Estos botones solo se muestran en el constructor de bots como guía
                                                    del flujo que sigue un bot si la respuesta viene a partir de una campaña, por ende los titulos de cada uno
                                                    deben ser <strong>iguales</strong> a las respuesta que se esperan.
                                                  </p>
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        )}
                                        {!selectedNode.data?.first && (
                                          <div className="col p-2">
                                            <label>Solicitud de nombre: </label>
                                            <div className="su-control-group d-flex">
                                              <Select
                                                styles={isDark == 'true' ? customStylesDark : customStyles}
                                                className="form-control basic-multi-select"
                                                placeholder="Solicitar nombre"
                                                value={selectedNode.data?.advanced?.request_name || false}
                                                name="request_name"
                                                onChange={(value) => {
                                                  setSelectedNode((prevInputValue) => ({
                                                    ...prevInputValue,
                                                    data: {
                                                      ...prevInputValue.data,
                                                      advanced: {
                                                        ...prevInputValue.data?.advanced,
                                                        request_name: value,
                                                      },
                                                    },
                                                  }));
                                                }}
                                                options={[
                                                  {label: 'Si', id: true},
                                                  {label: 'No', id: false}
                                                ]}
                                                classNamePrefix="select"
                                                noOptionsMessage={() => "Sin opciones"}
                                                getOptionLabel={(option) => (
                                                  <div className="option d-flex" style={{fontSize: "15px"}}>
                                                    {option.label} 
                                                  </div>
                                                )}
                                                getOptionValue={(option) => option.id}
                                                isDisabled={validateNodeRequestName()}
                                              />
                                            </div>
                                            <label>Confirmar nombre: </label>
                                            <div className="su-control-group d-flex">
                                              <Select
                                                styles={isDark == 'true' ? customStylesDark : customStyles}
                                                className="form-control basic-multi-select"
                                                placeholder="Confirmar nombre"
                                                value={selectedNode.data?.advanced?.confirm_name || false}
                                                name="confirm_name"
                                                onChange={(value) => {
                                                  setSelectedNode((prevInputValue) => ({
                                                    ...prevInputValue,
                                                    data: {
                                                      ...prevInputValue.data,
                                                      advanced: {
                                                        ...prevInputValue.data?.advanced,
                                                        confirm_name: value,
                                                      },
                                                    },
                                                  }));
                                                }}
                                                options={[
                                                  {label: 'Si', id: true},
                                                  {label: 'No', id: false}
                                                ]}
                                                classNamePrefix="select"
                                                noOptionsMessage={() => "Sin opciones"}
                                                getOptionLabel={(option) => (
                                                  <div className="option d-flex" style={{fontSize: "15px"}}>
                                                    {option.label} 
                                                  </div>
                                                )}
                                                getOptionValue={(option) => option.id}
                                                isDisabled={!validateNodeRequestName() || validateNodeConfirmName()}
                                              />
                                            </div>
                                            <p style={{fontSize: 11, color: '#6C757D'}}>
                                              <strong>Importante:</strong> Si el nodo requiere <strong>Confirmar Nombre</strong>,
                                              la opción o botón que indica que este ha sido confirmado debe contener de manera obligatoria 
                                              en el título la palabra <strong>Confirmar</strong> o <strong>Confirm</strong>.
                                            </p>
                                            <label>Editar nombre: </label>
                                            <div className="su-control-group d-flex">
                                              <Select
                                                styles={isDark == 'true' ? customStylesDark : customStyles}
                                                className="form-control basic-multi-select"
                                                placeholder="Editar nombre"
                                                value={selectedNode.data?.advanced?.edit_name || false}
                                                name="edit_name"
                                                onChange={(value) => {
                                                  setSelectedNode((prevInputValue) => ({
                                                    ...prevInputValue,
                                                    data: {
                                                      ...prevInputValue.data,
                                                      advanced: {
                                                        ...prevInputValue.data?.advanced,
                                                        edit_name: value,
                                                      },
                                                    },
                                                  }));
                                                }}
                                                options={[
                                                  {
                                                    label: 'Si',
                                                    id: true
                                                  },
                                                  {
                                                    label: 'No',
                                                    id: false
                                                  }
                                                ]}
                                                classNamePrefix="select"
                                                noOptionsMessage={() => "Sin opciones"}
                                                getOptionLabel={(option) => (
                                                  <div className="option d-flex" style={{fontSize: "15px"}}>
                                                    {option.label} 
                                                  </div>
                                                )}
                                                getOptionValue={(option) => option.label}
                                                isDisabled={!validateNodeRequestName() || !validateNodeConfirmName() || validateNodeEditName()}
                                              />
                                            </div>
                                            <p style={{fontSize: 11, color: '#6C757D'}}>
                                              <strong>Importante:</strong> Si el nodo requiere <strong>Editar Nombre</strong>,
                                              la opción o botón que indica que este ha sera editado debe contener de manera obligatoria 
                                              en el título la palabra <strong>Editar</strong> o <strong>Edit</strong>.
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#00A884",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="mr-2"
                                  onClick={async() => {
                                    const validate = validateLengthOptions();
                                    if(validate) {
                                      return Swal.fire({
                                        title: "Atención!",
                                        text: "Se han encontrado botones que exceden el limite de caracteres.",
                                        icon: "warning",
                                        confirmButtonText: "Aceptar",
                                      });
                                    }

                                    if(!selectedNode.data.title || 
                                      (!selectedNode.data.first && !selectedNode.data.user_response) ||
                                      !selectedNode.data.bot_response
                                    ) {
                                      return Swal.fire({
                                        title: "Atención!",
                                        text: "Hay campos requeridos vacíos",
                                        icon: "warning",
                                        confirmButtonText: "Aceptar",
                                      });
                                    }

                                    setLoadingUpdate(true);
                                    setSelectedNode((prevInputValue) => ({
                                      ...prevInputValue,
                                      data: {
                                        ...prevInputValue.data,
                                        saved: true
                                      },
                                    }))
                                    setTimeout(async () => {
                                      await updateBot(editFlow, edges, nodes)
                                      setLoadingUpdate(false);
                                    }, 500)
                                  }}
                                >  
                                  {loadingUpdate ? (
                                    <FaSpinner
                                      className="fa-spin"
                                      style={{ fontSize: "25px" }}
                                    />
                                  ) : (
                                    <span>Guardar</span>
                                  )}
                                </button>

                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#00A884",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="ml-2"
                                  onClick={async() => {
                                    setTimeout(() => {
                                      setSelectedNode(null);
                                    }, 200);
                                  }}
                                >   
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {/* Transfer Agent */}
                        {selectedNode.type == 'transferAgent' && (
                          <>
                            <div className="d-flex align-items-center justify-content-between text-white" style={{height: "50px", padding: "10px", borderBottom: '1px solid #CED4DA', backgroundColor: '#F56F89'}}>
                              <div className="d-flex align-items-center">
                                <FiMessageSquare className="mr-1" style={{fontSize: "24px"}}/>
                                <span style={{fontSize: "22px", whiteSpace: 'nowrap'}} >
                                    {selectedNode.data.title}
                                </span>
                              </div>
                              <button className="text-white border-0 bg-transparent d-block pointer mt-2" onClick={() => {
                                  removeNode(selectedNode.id)
                                }}>
                                  <BsTrash3 style={{ fontSize: "18px" }}/>
                              </button>
                            </div>
                            <div id="content-list-chats" className="card-body" style={{overflowY: 'auto'}}>
                              <div>
                                <div className="col p-0">
                                  <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                  <div>
                                    <input
                                      id="title"
                                      name="title"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.title || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.title) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              title: 'Título base' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            title: e.target.value
                                          },
                                        }))
                                      }
                                    />
    
                                    {!selectedNode.data.title ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Título es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Título identificativo de la respuesta para el diagrama de flujo.
                                      </p>
                                    )}
                                    
                                  </div>
                                </div>
                                {!selectedNode.data.first && (
                                  <div className="col p-0 mt-4">
                                    <label htmlFor="user_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del cliente: </label>
                                    <div>
                                      <textarea
                                        id="user_response"
                                        name="user_response"
                                        type="text"
                                        style={{width: "100%", height: "50px", borderRadius: "4px"}}
                                        value={selectedNode.data.user_response || ""}
                                        onChange={(e) => 
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              user_response: e.target.value
                                            },
                                          }))
                                        }
                                      />
                                      {!selectedNode.data.user_response ? (
                                        <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                          Campo Respuesta del cliente es obligatorio
                                        </p>
                                      ): (
                                        <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                          Mensaje que envía el cliente para activar la respuesta.
                                        </p>
                                      )}
                                      <div className="p-1" style={{backgroundColor: 'rgba(245, 111, 137, 0.3)', borderRadius: '4px'}}>
                                        <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                          <span style={{fontSize: 12, fontWeight: 600}}>Importante:</span> Los nodos se pueden activar con multiples respuestas según esten configuradas, 
                                          para ello debe tener el siguiente formato: Respuesta 1 / Respuesta 2 / Respuesta 3
                                          configurado en dicho apartado.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="col p-0 mt-4">
                                  <label htmlFor="bot_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del Bot: </label>
                                  <div>
                                    <textarea
                                      id="bot_response"
                                      name="bot_response"
                                      placeholder="Escribe la respuesta del Bot"
                                      style={{width: "100%", height: "90px", borderRadius: "4px"}}
                                      value={selectedNode.data.bot_response || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.bot_response) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              bot_response: '¡Hola! ¿Cómo puedo ayudarte hoy?' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            bot_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    <div className="d-flex align-items-center">
                                      <div className="pt-2">
                                        <button 
                                          className="text-dark border-0 bg-transparent d-block pointer" 
                                          type="button"
                                          onClick={(event) => handleClickModalVariables(event, 'bot_response', 'node')}
                                        >
                                          <VscVariableGroup style={{ fontSize: "18px" }}/>
                                        </button>
                                      </div>
                                      <div className="picker-container pt-4" style={{width: "100%"}}>
                                        <i
                                          className={
                                            showPicker
                                              ? "icon-smile emoji-icon active"
                                              : "icon-smile emoji-icon"
                                          }
                                          style={{ fontSize: "22px" }}
                                          onClick={() => setShowPicker((val) => !val)}
                                        />

                                        {showPicker && (
                                          <Picker
                                            pickerStyle={{
                                              width: "100%",
                                              top: "30px",
                                              position: "absolute",
                                              zIndex: "999",
                                            }}
                                            onEmojiClick={onEmojiClick}
                                            groupNames={{
                                              smileys_people: "Emoticones y personas",
                                              animals_nature: "Animales y naturaleza",
                                              food_drink: "Alimentos y bebidas",
                                              travel_places: "Viajes y lugares",
                                              activities: "Actividades",
                                              objects: "Objetos",
                                              symbols: "Símbolos",
                                              flags: "Banderas",
                                              recently_used: "Recientes",
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>

                                    {!selectedNode.data.bot_response || selectedNode.data.bot_response.length > 1024 ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        {!selectedNode.data.bot_response ? 'Campo Respuesta del Bot es obligatorio' 
                                        : 'El campo Respuesta del Bot no debe ser mayor a 1024 caracteres'}
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Respuesta que se le envía al cliente.
                                      </p>
                                    )}
                                  </div>

                                  <div className="p-1" style={{backgroundColor: 'rgba(245, 111, 137, 0.3)', borderRadius: '4px'}}>
                                    <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                      <span style={{fontSize: 12, fontWeight: 600}}>Importante:</span> Ten en cuenta que las transferencias a agentes o áreas se realizarán
                                      únicamente durante el horario comercial. El cliente recibirá el mensaje previamente
                                      configurado en dicho apartado.
                                    </p>
                                  </div>
                                </div>

                                <div className="col p-0 mt-4">
                                  <div className="d-flex mb-2">
                                    <div className="d-flex pointer mr-1">
                                      <input
                                        style={{accentColor: '#5BC0DE'}}
                                        type="radio"
                                        value="areas"
                                        className="pointer"
                                        checked={typeTransfer === "areas"}
                                        onChange={(e) => {
                                          setTypeTransfer(e.target.value)
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              agent_area: null
                                            },
                                          }))
                                        }}
                                      />
                                      <label
                                        className="d-flex mb-0 ml-1 pointer d-flex align-items-center"
                                        style={{ padding: "5px 2px 5px" }}
                                      >
                                        Areas
                                      </label>
                                    </div>
                                    <div className="d-flex pointer mr-1">
                                      <input
                                        style={{accentColor: '#5BC0DE'}}
                                        type="radio"
                                        value="agents"
                                        className="pointer"
                                        checked={typeTransfer === "agents"}
                                        onChange={(e) => {
                                          setTypeTransfer(e.target.value)
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              agent_area: null
                                            },
                                          }))
                                        }}
                                      />
                                      <label
                                        className="d-flex mb-0 ml-1 pointer d-flex align-items-center"
                                        style={{ padding: "5px 2px 5px" }}
                                      >
                                        Agentes
                                      </label>
                                    </div>
                                    <div className="d-flex pointer">
                                      <input
                                        style={{accentColor: '#5BC0DE'}}
                                        type="radio"
                                        value="automatic"
                                        className="pointer"
                                        checked={typeTransfer === "automatic"}
                                        onChange={(e) => setTypeTransfer(e.target.value)}
                                      />
                                      <label
                                        className="d-flex mb-0 ml-1 pointer d-flex align-items-center"
                                        style={{ padding: "5px 2px 5px" }}
                                      >
                                        Automático
                                      </label>
                                    </div>
                                  </div>

                                  {typeTransfer == 'areas' && (
                                    <div>
                                      <label>Areas: </label>
                                      <div className="su-control-group d-flex">
                                        <Select
                                          styles={isDark == 'true' ? customStylesDark : customStyles}
                                          className="form-control basic-multi-select"
                                          placeholder="Seleccione..."
                                          name="areas"
                                          value={selectedNode.data.agent_area}
                                          onChange={(area) => {
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                agent_area: area
                                              },
                                            }))
                                          }}
                                          options={listAreas}
                                          classNamePrefix="select"
                                          noOptionsMessage={() => "Sin opciones"}
                                          getOptionLabel={(option) => (
                                            <div className="option d-flex" style={{fontSize: "15px"}}>
                                              {option.label} 
                                            </div>
                                          )}
                                          getOptionValue={(option) => option.value}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {typeTransfer == 'agents' && (
                                    <div>
                                      <label>Agentes: </label>
                                      <div className="su-control-group d-flex">
                                        <Select
                                          styles={isDark == 'true' ? customStylesDark : customStyles}
                                          className="form-control basic-multi-select"
                                          placeholder="Seleccione..."
                                          name="agents"
                                          value={selectedNode.data.agent_area}
                                          onChange={(agent) => {
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                agent_area: agent
                                              },
                                            }))
                                          }}
                                          options={listAdvisers}
                                          classNamePrefix="select"
                                          noOptionsMessage={() => "Sin opciones"}
                                          getOptionLabel={(option) => (
                                            <div className="option d-flex" style={{fontSize: "15px"}}>
                                              {option.label} 
                                            </div>
                                          )}
                                          getOptionValue={(option) => option.value}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {typeTransfer == 'automatic' && (
                                    <div>
                                      <label>Automático: </label>
                                      <div className="su-control-group d-flex">
                                        <Select
                                          styles={isDark == 'true' ? customStylesDark : customStyles}
                                          className="form-control basic-multi-select"
                                          placeholder="Seleccione..."
                                          name="automatics"
                                          onChange={(auto) => {
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                agent_area: auto.id
                                              },
                                            }))
                                          }}
                                          options={[
                                            {
                                              label: "Area",
                                              id: "areas"
                                            },
                                            {
                                              label: "Agente",
                                              id: "agents"
                                            }
                                          ]}
                                          classNamePrefix="select"
                                          noOptionsMessage={() => "Sin opciones"}
                                          getOptionLabel={(option) => (
                                            <div className="option d-flex" style={{fontSize: "15px"}}>
                                              {option.label} 
                                            </div>
                                          )}
                                          getOptionValue={(option) => option.label}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#F56F89",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="mr-2"
                                  onClick={async() => {
                                    if(!selectedNode.data.title || 
                                      !selectedNode.data.user_response ||
                                      !selectedNode.data.bot_response || 
                                      !selectedNode.data.agent_area
                                    ) {
                                      return Swal.fire({
                                        title: "Atención!",
                                        text: "Hay campos requeridos vacíos",
                                        icon: "warning",
                                        confirmButtonText: "Aceptar",
                                      });
                                    }

                                    setLoadingUpdate(true);
                                    setSelectedNode((prevInputValue) => ({
                                      ...prevInputValue,
                                      data: {
                                        ...prevInputValue.data,
                                        saved: true
                                      },
                                    }))
                                    setTimeout(async () => {
                                      await updateBot(editFlow, edges, nodes)
                                      setLoadingUpdate(false);
                                    }, 500)
                                  }}
                                >   
                                  {loadingUpdate ? (
                                    <FaSpinner
                                      className="fa-spin"
                                      style={{ fontSize: "25px" }}
                                    />
                                  ) : (
                                    <span>Guardar</span>
                                  )}
                                </button>

                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#F56F89",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="ml-2"
                                  onClick={async() => {
                                    setTimeout(() => {
                                      setSelectedNode(null);
                                    }, 200);
                                  }}
                                >   
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {/* Tags */}
                        {selectedNode.type == 'tags' && (
                          <>
                            <div className="d-flex align-items-center justify-content-between text-white" style={{height: "50px", padding: "10px", borderBottom: '1px solid #CED4DA', backgroundColor: '#5BC0DE'}}>
                              <div className="d-flex align-items-center">
                                <LuTags className="mr-1" style={{fontSize: "24px"}}/>
                                <span style={{fontSize: "22px", whiteSpace: 'nowrap'}} >
                                    {selectedNode.data.title}
                                </span>
                              </div>
                              <button className="text-white border-0 bg-transparent d-block pointer mt-2" onClick={() => {
                                  removeNode(selectedNode.id)
                                }}>
                                  <BsTrash3 style={{ fontSize: "18px" }}/>
                              </button>
                            </div>
                            <div id="content-list-chats" className="card-body" style={{overflowY: 'auto'}}>
                              <div>
                                <div className="col p-0">
                                  <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                  <div>
                                    <input
                                      id="title"
                                      name="title"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.title || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.title) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              title: 'Título base' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            title: e.target.value
                                          },
                                        }))
                                      }
                                    />
    
                                    {!selectedNode.data.title ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Título es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Título identificativo de la respuesta para el diagrama de flujo.
                                      </p>
                                    )}
                                    
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="user_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del cliente: </label>
                                  <div>
                                    <textarea
                                      id="user_response"
                                      name="user_response"
                                      type="text"
                                      style={{width: "100%", height: "50px", borderRadius: "4px"}}
                                      value={selectedNode.data.user_response || ""}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            user_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    {!selectedNode.data.user_response ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Respuesta del cliente es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Mensaje que envía el cliente para activar la respuesta.
                                      </p>
                                    )}
                                  </div>

                                  <div className="p-1" style={{backgroundColor: 'rgba(91, 192, 222, 0.3)', borderRadius: '4px'}}>
                                    <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                      <span style={{fontSize: 12, fontWeight: 600}}>Importante:</span> Con el signo * el etiquetado funcionará 
                                      para cuaquier respuesta que de el cliente.
                                    </p>
                                  </div>

                                  <div className="p-1 mt-2" style={{backgroundColor: 'rgba(91, 192, 222, 0.3)', borderRadius: '4px'}}>
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        <span style={{fontSize: 12, fontWeight: 600}}>Importante:</span> Los nodos se pueden activar con multiples respuestas según esten configuradas, 
                                        para ello debe tener el siguiente formato: Respuesta 1 / Respuesta 2 / Respuesta 3
                                        configurado en dicho apartado.
                                      </p>
                                    </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label>Etiquetas: </label>
                                  <div className="su-control-group d-flex">
                                    <Select
                                      styles={isDark == 'true' ? customStylesDark : customStyles}
                                      className="form-control basic-multi-select"
                                      isMulti
                                      placeholder="Seleccione..."
                                      value={selectedNode.data.tags}
                                      name="tags"
                                      onChange={(tag) => {
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            tags: tag
                                          },
                                        }))
                                      }}
                                      options={listTags}
                                      classNamePrefix="select"
                                      noOptionsMessage={() => "Sin opciones"}
                                      getOptionLabel={(option) => (
                                        <div className="option d-flex" style={{fontSize: "15px"}}>
                                          {option.title} 
                                        </div>
                                      )}
                                      getOptionValue={(option) => option.title}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#5BC0DE",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="mr-2"
                                  onClick={async() => {
                                    if(!selectedNode.data.title || 
                                      !selectedNode.data.user_response ||
                                      !selectedNode.data.tags.length
                                    ) {
                                      return Swal.fire({
                                        title: "Atención!",
                                        text: "Hay campos requeridos vacíos",
                                        icon: "warning",
                                        confirmButtonText: "Aceptar",
                                      });
                                    }

                                    setLoadingUpdate(true);
                                    setSelectedNode((prevInputValue) => ({
                                      ...prevInputValue,
                                      data: {
                                        ...prevInputValue.data,
                                        saved: true
                                      },
                                    }))
                                    setTimeout(async () => {
                                      await updateBot(editFlow, edges, nodes)
                                      setLoadingUpdate(false);
                                    }, 500)
                                  }}
                                >   
                                  {loadingUpdate ? (
                                    <FaSpinner
                                      className="fa-spin"
                                      style={{ fontSize: "25px" }}
                                    />
                                  ) : (
                                    <span>Guardar</span>
                                  )}
                                </button>

                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#5BC0DE",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="ml-2"
                                  onClick={async() => {
                                    setTimeout(() => {
                                      setSelectedNode(null);
                                    }, 200);
                                  }}
                                >   
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {/* Assistant AI */}
                        {selectedNode.type == 'assistantAI' && (
                          <>
                            <div className="d-flex align-items-center justify-content-between text-white" style={{height: "50px", padding: "10px", borderBottom: '1px solid #CED4DA', backgroundColor: '#A7ADA7'}}>
                              <div className="d-flex align-items-center">
                                <FaBrain className="mr-1" style={{fontSize: "24px"}}/>
                                <span style={{fontSize: "22px", whiteSpace: 'nowrap'}} >
                                    {selectedNode.data.title}
                                </span>
                              </div>
                              <button className="text-white border-0 bg-transparent d-block pointer mt-2" onClick={() => {
                                  removeNode(selectedNode.id)
                                }}>
                                  <BsTrash3 style={{ fontSize: "18px" }}/>
                              </button>
                            </div>
                            <div id="content-list-chats" className="card-body" style={{overflowY: 'auto'}}>
                              <div>
                                <div className="col p-0">
                                  <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                  <div>
                                    <input
                                      id="title"
                                      name="title"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.title || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.title) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              title: 'Título base' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            title: e.target.value
                                          },
                                        }))
                                      }
                                    />
    
                                    {!selectedNode.data.title ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Título es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Título identificativo de la respuesta para el diagrama de flujo.
                                      </p>
                                    )}
                                    
                                  </div>
                                </div>
                                <div className="col p-0">
                                  <label htmlFor="assistant" style={{color: '#6C757D', fontWeight: 200}}>Asistente: </label>
                                  <div className="su-control-group d-flex">
                                    <Select
                                      styles={isDark == 'true' ? customStylesDark : customStyles}
                                      className="form-control basic-multi-select"
                                      placeholder="Seleccione..."
                                      name="assistants"
                                      value={selectedNode.data.assistant || ""}
                                      onChange={(assis) => {
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            assistant: assis
                                          },
                                        }))
                                        setHasAssistantAi(true);
                                        console.log('🤖🤖🤖🤖🤖')
                                      }}
                                      options={listAssistants}
                                      classNamePrefix="select"
                                      noOptionsMessage={() => "Sin opciones"}
                                      getOptionLabel={(option) => (
                                        <div className="option d-flex" style={{fontSize: "15px"}}>
                                          {option.name} 
                                        </div>
                                      )}
                                      getOptionValue={(option) => option.name}
                                    />
                                  </div>
                                  {/* <span>{selectedNode.data.assistant}</span> */}
                                  {!hasAssistantAi && !selectedNode.data.saved && !selectedNode.data.assistant && (
                                    <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                      Debe seleccionar un asistente
                                    </p>
                                  )}
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="user_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del cliente: </label>
                                  <div>
                                    <textarea
                                      id="user_response"
                                      name="user_response"
                                      type="text"
                                      style={{width: "100%", height: "50px", borderRadius: "4px"}}
                                      value={selectedNode.data.user_response || ""}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            user_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    {!selectedNode.data.user_response ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Respuesta del cliente es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Mensaje que envía el cliente para activar la respuesta.
                                      </p>
                                    )}
                                    <div className="p-1" style={{backgroundColor: 'rgba(167, 173, 167, 0.3)', borderRadius: '4px'}}>
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        <span style={{fontSize: 12, fontWeight: 600}}>Importante:</span> Los nodos se pueden activar con multiples respuestas según esten configuradas, 
                                        para ello debe tener el siguiente formato: Respuesta 1 / Respuesta 2 / Respuesta 3
                                        configurado en dicho apartado.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="bot_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del Bot: </label>
                                  <div>
                                    <textarea
                                      id="bot_response"
                                      name="bot_response"
                                      placeholder="Escribe la respuesta del Bot"
                                      style={{width: "100%", height: "90px", borderRadius: "4px"}}
                                      value={selectedNode.data.bot_response || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.bot_response) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              bot_response: '¡Hola! ¿Cómo puedo ayudarte hoy?' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            bot_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    <div className="picker-container pt-4" style={{width: "100%"}}>
                                      <i
                                        className={
                                          showPicker
                                            ? "icon-smile emoji-icon active"
                                            : "icon-smile emoji-icon"
                                        }
                                        style={{ fontSize: "22px" }}
                                        onClick={() => setShowPicker((val) => !val)}
                                      />

                                      {showPicker && (
                                        <Picker
                                          pickerStyle={{
                                            width: "100%",
                                            top: "30px",
                                            position: "absolute",
                                            zIndex: "999",
                                          }}
                                          onEmojiClick={onEmojiClick}
                                          groupNames={{
                                            smileys_people: "Emoticones y personas",
                                            animals_nature: "Animales y naturaleza",
                                            food_drink: "Alimentos y bebidas",
                                            travel_places: "Viajes y lugares",
                                            activities: "Actividades",
                                            objects: "Objetos",
                                            symbols: "Símbolos",
                                            flags: "Banderas",
                                            recently_used: "Recientes",
                                          }}
                                        />
                                      )}
                                    </div>
                                    
                                    {!selectedNode.data.bot_response || selectedNode.data.bot_response.length > 1024 ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        {!selectedNode.data.bot_response ? 'Campo Respuesta del Bot es obligatorio' 
                                        : 'El campo Respuesta del Bot no debe ser mayor a 1024 caracteres'}
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Respuesta que se le envía al cliente.
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#A7ADA7",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="mr-2"
                                  onClick={async() => {
                                    if(!selectedNode.data.title || 
                                      !selectedNode.data.assistant ||
                                      !selectedNode.data.user_response ||
                                      !selectedNode.data.bot_response
                                    ) {
                                      return Swal.fire({
                                        title: "Atención!",
                                        text: "Hay campos requeridos vacíos",
                                        icon: "warning",
                                        confirmButtonText: "Aceptar",
                                      });
                                    }

                                    setLoadingUpdate(true);
                                    setSelectedNode((prevInputValue) => ({
                                      ...prevInputValue,
                                      data: {
                                        ...prevInputValue.data,
                                        saved: true
                                      },
                                    }))
                                    setTimeout(async () => {
                                      await updateBot(editFlow, edges, nodes)
                                      setLoadingUpdate(false);
                                    }, 500)
                                  }}
                                >   
                                  {loadingUpdate ? (
                                    <FaSpinner
                                      className="fa-spin"
                                      style={{ fontSize: "25px" }}
                                    />
                                  ) : (
                                    <span>Guardar</span>
                                  )}
                                </button>

                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#A7ADA7",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="ml-2"
                                  onClick={async() => {

                                    // if(!selectedNode.data.first && !selectedNode.data.saved) {
                                    //   Swal.fire({
                                    //     title: "¿Estas seguro que deseas cerrar sin guardar?",
                                    //     text: "Se eliminará el nodo.",
                                    //     icon: "warning",
                                    //     showCancelButton: true,
                                    //     confirmButtonColor: "#3085d6",
                                    //     cancelButtonColor: "#d33",
                                    //     confirmButtonText: "Seguro",
                                    //     cancelButtonText: "Cancelar",
                                    //   }).then(async (result) => {
                                    //     if (result.isConfirmed) {
                                    //       removeNode(selectedNode.id)
                                    //     }
                                    //   });
                                    // }
                                    setSelectedNode(null);
                                  }}
                                >   
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {/* SaveAnswer */}
                        {selectedNode.type == 'saveAnswer' && (
                          <>
                            <div className="d-flex align-items-center justify-content-between text-white" style={{height: "50px", padding: "10px", borderBottom: '1px solid #CED4DA', backgroundColor: '#D89C7E'}}>
                              <div className="d-flex align-items-center">
                                <LuTags className="mr-1" style={{fontSize: "24px"}}/>
                                <span style={{fontSize: "22px", whiteSpace: 'nowrap'}} >
                                    {selectedNode.data.title}
                                </span>
                              </div>
                              <button className="text-white border-0 bg-transparent d-block pointer mt-2" onClick={() => {
                                  removeNode(selectedNode.id)
                                }}>
                                  <BsTrash3 style={{ fontSize: "18px" }}/>
                              </button>
                            </div>
                            <div id="content-list-chats" className="card-body" style={{overflowY: 'auto'}}>
                              <div>
                                <div className="col p-0">
                                  <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                  <div>
                                    <input
                                      id="title"
                                      name="title"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.title || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.title) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              title: 'Título base' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            title: e.target.value
                                          },
                                        }))
                                      }
                                    />
    
                                    {!selectedNode.data.title ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Título es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Título identificativo de la respuesta para el diagrama de flujo.
                                      </p>
                                    )}
                                    
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="user_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del cliente: </label>
                                  <div>
                                    <textarea
                                      id="user_response"
                                      name="user_response"
                                      type="text"
                                      style={{width: "100%", height: "50px", borderRadius: "4px"}}
                                      value={selectedNode.data.user_response || ""}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            user_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    {!selectedNode.data.user_response ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Respuesta del cliente es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Mensaje que envía el cliente para activar la respuesta.
                                      </p>
                                    )}
                                    <div className="p-1" style={{backgroundColor: 'rgba(216, 156, 126, 0.3)', borderRadius: '4px'}}>
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        <span style={{fontSize: 12, fontWeight: 600}}>Importante:</span> Los nodos se pueden activar con multiples respuestas según esten configuradas, 
                                        para ello debe tener el siguiente formato: Respuesta 1 / Respuesta 2 / Respuesta 3
                                        configurado en dicho apartado.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label>Variable: </label>
                                  <div className="su-control-group d-flex">
                                    <Select
                                      styles={isDark == 'true' ? customStylesDark : customStyles}
                                      isMulti
                                      className="form-control basic-multi-select"
                                      placeholder="Seleccione..."
                                      name="variables"
                                      value={selectedNode.data.variable}
                                      onChange={(val) => {
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            variable: val
                                          },
                                        }))
                                      }}
                                      options={correspondence}
                                      classNamePrefix="select"
                                      noOptionsMessage={() => "Sin opciones"}
                                      getOptionLabel={(option) => (
                                        <div className="option d-flex" style={{fontSize: "15px"}}>
                                          {option.fieldName} 
                                        </div>
                                      )}
                                      getOptionValue={(option) => option.fieldName}
                                    />
                                  </div>

                                  <p style={{fontSize: 12, color: '#6C757D', fontWeight: 800}} className="pt-2">
                                    Ejecuta la acción y salta al siguiente nodo.
                                  </p>

                                  <div className="p-1" style={{backgroundColor: 'rgba(216, 156, 126, 0.3)', borderRadius: '4px'}}>
                                    <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        <span style={{fontSize: 12, fontWeight: 600}}>Importante:</span> Ten en cuenta que en las respuestas de flujos (Flujos de WhatsApp)
                                        de acuerdo al json que retorne y según el orden de sus datos asi se guardará segun el orden de las variables en las que se desea almacenar.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#D89C7E",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="mr-2"
                                  onClick={async() => {
                                    if(!selectedNode.data.title ||
                                      !selectedNode.data.user_response ||
                                      !selectedNode.data.variable
                                    ) {
                                      return Swal.fire({
                                        title: "Atención!",
                                        text: "Hay campos requeridos vacíos",
                                        icon: "warning",
                                        confirmButtonText: "Aceptar",
                                      });
                                    }

                                    setLoadingUpdate(true);
                                    setSelectedNode((prevInputValue) => ({
                                      ...prevInputValue,
                                      data: {
                                        ...prevInputValue.data,
                                        saved: true
                                      },
                                    }))
                                    setTimeout(async () => {
                                      await updateBot(editFlow, edges, nodes)
                                      setLoadingUpdate(false);
                                    }, 500)
                                  }}
                                >   
                                  {loadingUpdate ? (
                                    <FaSpinner
                                      className="fa-spin"
                                      style={{ fontSize: "25px" }}
                                    />
                                  ) : (
                                    <span>Guardar</span>
                                  )}
                                </button>

                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#D89C7E",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="ml-2"
                                  onClick={async() => {
                                    setTimeout(() => {
                                      setSelectedNode(null);
                                    }, 200);
                                  }}
                                >   
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {/* FlowsWhatsApp */}
                        {selectedNode.type == 'flowsWhatsApp' && (
                          <>
                            <div className="d-flex align-items-center justify-content-between text-white" style={{height: "50px", padding: "10px", borderBottom: '1px solid #CED4DA', backgroundColor: '#B85C7C'}}>
                              <div className="d-flex align-items-center">
                                <TiFlowMerge className="mr-1" style={{fontSize: "24px"}}/>
                                <span style={{fontSize: "22px", whiteSpace: 'nowrap'}} >
                                    {selectedNode.data.title}
                                </span>
                              </div>
                              <button className="text-white border-0 bg-transparent d-block pointer mt-2" onClick={() => {
                                  removeNode(selectedNode.id)
                                }}>
                                  <BsTrash3 style={{ fontSize: "18px" }}/>
                              </button>
                            </div>
                            <div id="content-list-chats" className="card-body" style={{overflowY: 'auto'}}>
                              <div>
                                <div className="col p-0">
                                  <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                  <div>
                                    <input
                                      id="title"
                                      name="title"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.title || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.title) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              title: 'Título base' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            title: e.target.value
                                          },
                                        }))
                                      }
                                    />
    
                                    {!selectedNode.data.title ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Título es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Título identificativo de la respuesta para el diagrama de flujo.
                                      </p>
                                    )}
                                    
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="user_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del cliente: </label>
                                  <div>
                                    <textarea
                                      id="user_response"
                                      name="user_response"
                                      type="text"
                                      style={{width: "100%", height: "50px", borderRadius: "4px"}}
                                      value={selectedNode.data.user_response || ""}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            user_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    {!selectedNode.data.user_response ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Respuesta del cliente es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Mensaje que envía el cliente para activar la respuesta.
                                      </p>
                                    )}
                                  </div>

                                  <div className="p-1" style={{backgroundColor: 'rgba(91, 16, 77, 0.3)', borderRadius: '4px'}}>
                                    <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                      <span style={{fontSize: 12, fontWeight: 600}}>Importante:</span> Con el signo * funcionará 
                                      para cuaquier respuesta que de el cliente.
                                    </p>
                                  </div>


                                  <div className="p-1 mt-2" style={{backgroundColor: 'rgba(91, 16, 77, 0.3)', borderRadius: '4px'}}>
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        <span style={{fontSize: 12, fontWeight: 600}}>Importante:</span> Los nodos se pueden activar con multiples respuestas según esten configuradas, 
                                        para ello debe tener el siguiente formato: Respuesta 1 / Respuesta 2 / Respuesta 3
                                        configurado en dicho apartado.
                                      </p>
                                    </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label>Flujos: </label>
                                  <div className="su-control-group d-flex">
                                    <Select
                                      styles={isDark == 'true' ? customStylesDark : customStyles}
                                      className="form-control basic-multi-select"
                                      placeholder="Seleccione..."
                                      name="flows"
                                      value={flows.find((option) => option._id === selectedNode.data.flow) || ""}
                                      onChange={(flow) => {
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            flow: flow._id
                                          },
                                        }))
                                      }}
                                      options={flows}
                                      classNamePrefix="select"
                                      noOptionsMessage={() => "Sin opciones"}
                                      getOptionLabel={(option) => (
                                        <div className="option d-flex" style={{fontSize: "15px"}}>
                                          {option.name} 
                                        </div>
                                      )}
                                      getOptionValue={(option) => option._id}
                                    />
                                  </div>
                                </div>
                                {selectedNode.data.flow && (
                                  <>
                                    <div className="col p-0 mt-4">
                                      <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Encabezado (Opcional): </label>
                                      <div>
                                        <input
                                          id="title"
                                          name="title"
                                          type="text"
                                          style={{width: "100%", height: "35px"}}
                                          value={selectedNode.data.flow_data?.header || ""}
                                          onChange={(e) => 
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                flow_data: {
                                                  ...prevInputValue.data.flow_data,
                                                  header: e.target.value
                                                }
                                              },
                                            }))
                                          }
                                        />

                                        <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                          Encabezado para enviar el flujo.
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col p-0">
                                      <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Cuerpo: </label>
                                      <div>
                                        <input
                                          id="title"
                                          name="title"
                                          type="text"
                                          style={{width: "100%", height: "35px"}}
                                          value={selectedNode.data.flow_data?.body || ""}
                                          onChange={(e) => 
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                flow_data: {
                                                  ...prevInputValue.data.flow_data,
                                                  body: e.target.value
                                                }
                                              },
                                            }))
                                          }
                                        />
        
                                        {!selectedNode.data.flow_data?.body ? (
                                          <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                            Campo cuerpo es obligatorio
                                          </p>
                                        ): (
                                          <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                            Cuerpo para enviar flujo.
                                          </p>
                                        )}
                                        
                                      </div>
                                    </div>
                                    <div className="col p-0">
                                      <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Pie: </label>
                                      <div>
                                        <input
                                          id="title"
                                          name="title"
                                          type="text"
                                          style={{width: "100%", height: "35px"}}
                                          value={selectedNode.data.flow_data?.footer || ""}
                                          onChange={(e) => 
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                flow_data: {
                                                  ...prevInputValue.data.flow_data,
                                                  footer: e.target.value
                                                }
                                              },
                                            }))
                                          }
                                        />
        
                                        {!selectedNode.data.flow_data?.footer ? (
                                          <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                            Campo pie es obligatorio
                                          </p>
                                        ): (
                                          <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                            Pie para enviar flujo.
                                          </p>
                                        )}
                                        
                                      </div>
                                    </div>
                                    <div className="col p-0">
                                      <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Título de Botón: </label>
                                      <div>
                                        <input
                                          id="title"
                                          name="title"
                                          type="text"
                                          style={{width: "100%", height: "35px"}}
                                          value={selectedNode.data.flow_data?.button || ""}
                                          maxLength={20}
                                          onChange={(e) => 
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                flow_data: {
                                                  ...prevInputValue.data.flow_data,
                                                  button: e.target.value
                                                }
                                              },
                                            }))
                                          }
                                        />
        
                                        {!selectedNode.data.flow_data?.button ? (
                                          <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                            Campo Título de botón es obligatorio
                                          </p>
                                        ): (
                                          <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                            Título de botón para enviar flujo (Máx 20).
                                          </p>
                                        )}
                                        
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#B85C7C",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="mr-2"
                                  onClick={async() => {
                                    if(!selectedNode.data.title ||
                                      !selectedNode.data.user_response ||
                                      !selectedNode.data.flow || 
                                      !selectedNode.data.flow_data?.body || 
                                      !selectedNode.data.flow_data?.footer ||
                                      !selectedNode.data.flow_data?.button 
                                    ) {
                                      return Swal.fire({
                                        title: "Atención!",
                                        text: "Hay campos requeridos vacíos",
                                        icon: "warning",
                                        confirmButtonText: "Aceptar",
                                      });
                                    }

                                    setLoadingUpdate(true);
                                    setSelectedNode((prevInputValue) => ({
                                      ...prevInputValue,
                                      data: {
                                        ...prevInputValue.data,
                                        saved: true
                                      },
                                    }))
                                    setTimeout(async () => {
                                      await updateBot(editFlow, edges, nodes)
                                      setLoadingUpdate(false);
                                    }, 500)
                                  }}
                                >   
                                  {loadingUpdate ? (
                                    <FaSpinner
                                      className="fa-spin"
                                      style={{ fontSize: "25px" }}
                                    />
                                  ) : (
                                    <span>Guardar</span>
                                  )}
                                </button>

                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#B85C7C",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="ml-2"
                                  onClick={async() => {
                                    setTimeout(() => {
                                      setSelectedNode(null);
                                    }, 200);
                                  }}
                                >   
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {/* Transfer QueryApi */}
                        {selectedNode.type == 'queryApi' && (
                          <>
                            <div className="d-flex align-items-center justify-content-between text-white" style={{height: "50px", padding: "10px", borderBottom: '1px solid #CED4DA', backgroundColor: '#7ECCD8'}}>
                              <div className="d-flex align-items-center">
                                <BsDatabaseFillAdd className="mr-1" style={{fontSize: "24px"}}/>
                                <span style={{fontSize: "22px", whiteSpace: 'nowrap'}} >
                                    {selectedNode.data.title}
                                </span>
                              </div>
                              <button className="text-white border-0 bg-transparent d-block pointer mt-2" onClick={() => {
                                  removeNode(selectedNode.id)
                                }}>
                                  <BsTrash3 style={{ fontSize: "18px" }}/>
                              </button>
                            </div>
                            <div id="content-list-chats" className="card-body" style={{overflowY: 'auto'}}>
                              <div>
                                <div className="col p-0">
                                  <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                  <div>
                                    <input
                                      id="title"
                                      name="title"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.title || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.title) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              title: 'Título base' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            title: e.target.value
                                          },
                                        }))
                                      }
                                    />
    
                                    {!selectedNode.data.title ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Título es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Título identificativo de la respuesta para el diagrama de flujo.
                                      </p>
                                    )}
                                    
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="user_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del cliente: </label>
                                  <div>
                                    <textarea
                                      id="user_response"
                                      name="user_response"
                                      type="text"
                                      style={{width: "100%", height: "50px", borderRadius: "4px"}}
                                      value={selectedNode.data.user_response || ""}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            user_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    {!selectedNode.data.user_response ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Respuesta del cliente es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Mensaje que envía el cliente para activar la respuesta.
                                      </p>
                                    )}
                                  </div>
                                  <div className="p-1" style={{backgroundColor: 'rgba(126, 204, 216, 0.3)', borderRadius: '4px'}}>
                                    <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                      <span style={{fontSize: 12, fontWeight: 600}}>Importante:</span> Con el signo * funcionará 
                                      para cuaquier respuesta que de el cliente.
                                    </p>
                                  </div>
                                  <div className="p-1 mt-2" style={{backgroundColor: 'rgba(126, 204, 216, 0.3)', borderRadius: '4px'}}>
                                    <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                      <span style={{fontSize: 12, fontWeight: 600}}>Importante:</span> Los nodos se pueden activar con multiples respuestas según esten configuradas, 
                                      para ello debe tener el siguiente formato: Respuesta 1 / Respuesta 2 / Respuesta 3
                                      configurado en dicho apartado.
                                    </p>
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="url_api" style={{color: '#6C757D', fontWeight: 200}}>URL API externa: </label>
                                  <div>
                                    <input
                                      id="url_api"
                                      name="url_api"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.url_api || ""}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            url_api: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    {!selectedNode.data.url_api ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo URL API es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        URL a la cual se va a realizar la petición.
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="protocol" style={{color: '#6C757D', fontWeight: 200}}>Protocolo HTTP: </label>
                                  <div>
                                    <Select
                                      styles={isDark == 'true' ? customStylesDark : customStyles}
                                      className="form-control basic-multi-select"
                                      placeholder="Seleccione..."
                                      name="protocol"
                                      value={selectedNode.data.protocol}
                                      onChange={(protocol) => {
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            protocol: protocol
                                          },
                                        }))
                                      }}
                                      options={[
                                        {
                                          label: 'GET',
                                          id: 'GET'
                                        },
                                        {
                                          label: 'POST',
                                          id: 'POST'
                                        }
                                      ]}
                                      classNamePrefix="select"
                                      noOptionsMessage={() => "Sin opciones"}
                                      getOptionLabel={(option) => (
                                        <div className="option d-flex" style={{fontSize: "15px"}}>
                                          {option.label} 
                                        </div>
                                      )}
                                      getOptionValue={(option) => option.label}
                                    />
                                    {!selectedNode.data.protocol ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Protocolo HTTP es obligatorio.
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Protocolo HTTP para realiza la petición.
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="auth" style={{color: '#6C757D', fontWeight: 200}}>Tipo de autenticación: </label>
                                  <div>
                                    <Select
                                      styles={isDark == 'true' ? customStylesDark : customStyles}
                                      className="form-control basic-multi-select"
                                      placeholder="Seleccione..."
                                      name="auth"
                                      value={selectedNode.data.auth}
                                      onChange={(area) => {
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            auth: area
                                          },
                                        }))
                                      }}
                                      options={[
                                        {
                                          label: 'Ninguna',
                                          id: 'none'
                                        },
                                        {
                                          label: 'Usuario/Contraseña',
                                          id: 'user_password'
                                        },
                                      ]}
                                      classNamePrefix="select"
                                      noOptionsMessage={() => "Sin opciones"}
                                      getOptionLabel={(option) => (
                                        <div className="option d-flex" style={{fontSize: "15px"}}>
                                          {option.label} 
                                        </div>
                                      )}
                                      getOptionValue={(option) => option.label}
                                    />
                                    {!selectedNode.data.auth ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Tipo de autenticación es obligatorio.
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Seguridad del webhook.
                                      </p>
                                    )}
                                  </div>
                                </div>
                                {selectedNode.data.auth && selectedNode.data.auth == 'user_password' && (
                                  <>
                                    <div className="col p-0">
                                      <label htmlFor="user" style={{color: '#6C757D', fontWeight: 200}}>Usuario: </label>
                                      <div>
                                        <input
                                          id="user"
                                          name="user"
                                          type="text"
                                          style={{width: "100%", height: "35px"}}
                                          value={selectedNode.data.user || ""}
                                          onChange={(e) => 
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                user: e.target.value
                                              },
                                            }))
                                          }
                                        />
        
                                        {selectedNode.data.auth == 'user_password' && !selectedNode.data.user && (
                                          <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                            Campo Usuario es obligatorio.
                                          </p>
                                        )}
                                        
                                      </div>
                                    </div>
                                    <div className="col p-0">
                                      <label htmlFor="password" style={{color: '#6C757D', fontWeight: 200}}>Contraseña: </label>
                                      <div>
                                        <input
                                          id="password"
                                          name="password"
                                          type="text"
                                          style={{width: "100%", height: "35px"}}
                                          value={selectedNode.data.password || ""}
                                          onChange={(e) => 
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                password: e.target.value
                                              },
                                            }))
                                          }
                                        />
                                        {selectedNode.data.auth == 'user_password' && !selectedNode.data.password && (
                                          <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                            Campo Contraseña es obligatorio.
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                                <div className="col p-0 mt-4">
                                  <div className="panel-group collapsible" id="user-collapsible">
                                    <div className="panel panel-default">
                                      <div 
                                        className="panel-heading p-2" 
                                        style={{
                                          border: "1px solid #4F4F4F",
                                          borderRadius: "4px"
                                        }}>
                                        <div id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={onAditionalHeaders}>
                                          <span>
                                            Cabeceras (Opcional)
                                          </span>
                                          {selectHeaders ?
                                            <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                                            <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                                          }
                                        </div>
                                      </div>

                                      {selectHeaders ? (
                                        <div 
                                          className="panel-collapse pt-2" 
                                          style={{
                                            backgroundColor: "#F1F1F1",
                                            borderRadius: "4px"
                                          }}>
                                          <div className="panel-body" style={{paddingInline: "0px"}}>
                                            <div className="text-center">
                                              <button
                                                type="button"
                                                style={{
                                                  border: 0,
                                                  borderRadius: "4px",
                                                  color: "#7ECCD8",
                                                  backgroundColor: "rgba(126, 204, 216, 0.3)",
                                                  cursor: "pointer",
                                                  fontFamily: "Helvetica, Arial, sans-serif",
                                                  fontSize: "12px",
                                                  fontWeight: "bold",
                                                  width: "150px",
                                                  zIndex: 100,
                                                }}
                                                onClick={() => setModalHeaders(true)}
                                              > 
                                                <IoMdAdd/>
                                                Agregar Cabecera
                                              </button>
                                            </div>
                                            <div className="mt-2">
                                              {selectedNode.data.headers.length > 0 && selectedNode.data.headers.map((head, index) => (
                                                <div className="d-flex justify-content-around">
                                                  <div>
                                                    <div style={{fontWeight:"bolder"}}>{head.key}</div>
                                                    <div>{head.value}</div>
                                                  </div>
                                                  <div className="d-flex justify-content-end">
                                                    <button
                                                      onClick={() => {
                                                        setEditModal(true)
                                                        setModalHeaders(true)
                                                        setHeaderParams({
                                                          key: head.key,
                                                          value: head.value,
                                                          index: index
                                                        })
                                                      }}
                                                      className="text-primary border-0 bg-transparent d-block pointer mt-2"
                                                    >
                                                      <BsPencilFill style={{ fontSize: "16px" }}/>
                                                    </button>
                                                    <button 
                                                      className="text-danger border-0 bg-transparent d-block pointer mt-2" 
                                                      onClick={() => {
                                                        const filter = selectedNode.data.headers.filter(v => v.key !== head.key)
                                                        setSelectedNode((prevInputValue) => ({
                                                          ...prevInputValue,
                                                          data: {
                                                            ...prevInputValue.data,
                                                            headers: filter
                                                          },
                                                        }))
                                                      }}
                                                    >
                                                      <BsTrash3 style={{ fontSize: "16px" }}/>
                                                    </button>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <div className="panel-group collapsible" id="user-collapsible">
                                    <div className="panel panel-default">
                                      <div 
                                        className="panel-heading p-2" 
                                        style={{
                                          border: "1px solid #4F4F4F",
                                          borderRadius: "4px"
                                        }}>
                                        <div id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={onAditionalRequest}>
                                          <span>
                                            Parámetros de petición (Opcional)
                                          </span>
                                          {selectRequest ?
                                            <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                                            <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                                          }
                                        </div>
                                      </div>

                                      {selectRequest ? (
                                        <div 
                                          className="panel-collapse pt-2" 
                                          style={{
                                            backgroundColor: "#F1F1F1",
                                            borderRadius: "4px"
                                          }}>
                                          <div className="panel-body" style={{paddingInline: "0px"}}>
                                            <div className="text-center">
                                              <button
                                                type="button"
                                                style={{
                                                  border: 0,
                                                  borderRadius: "4px",
                                                  color: "#7ECCD8",
                                                  backgroundColor: "rgba(126, 204, 216, 0.3)",
                                                  cursor: "pointer",
                                                  fontFamily: "Helvetica, Arial, sans-serif",
                                                  fontSize: "12px",
                                                  fontWeight: "bold",
                                                  width: "150px",
                                                  zIndex: 100,
                                                }}
                                                onClick={() => setModalRequestParams(true)}
                                              > 
                                                <IoMdAdd/>
                                                Agregar Parámetro
                                              </button>
                                            </div>
                                            <div className="mt-2">
                                              {selectedNode.data.requestParams.length > 0 && selectedNode.data.requestParams.map((req, index) => (
                                                <div className="d-flex justify-content-around">
                                                  <div>
                                                    <div style={{fontWeight:"bolder"}}>{req.key}</div>
                                                    <div>{req.value}</div>
                                                  </div>
                                                  <div className="d-flex justify-content-end">
                                                    <button
                                                      onClick={() => {
                                                        setEditModal(true)
                                                        setModalRequestParams(true)
                                                        setRequestParams({
                                                          key: req.key,
                                                          value: req.value,
                                                          index: index
                                                        })
                                                      }}
                                                      className="text-primary border-0 bg-transparent d-block pointer mt-2"
                                                    >
                                                      <BsPencilFill style={{ fontSize: "16px" }}/>
                                                    </button>
                                                    <button 
                                                      className="text-danger border-0 bg-transparent d-block pointer mt-2" 
                                                      onClick={() => {
                                                        const filter = selectedNode.data.requestParams.filter(v => v.key !== req.key)
                                                        setSelectedNode((prevInputValue) => ({
                                                          ...prevInputValue,
                                                          data: {
                                                            ...prevInputValue.data,
                                                            requestParams: filter
                                                          },
                                                        }))
                                                      }}
                                                    >
                                                      <BsTrash3 style={{ fontSize: "16px" }}/>
                                                    </button>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <div className="panel-group collapsible" id="user-collapsible">
                                    <div className="panel panel-default">
                                      <div 
                                        className="panel-heading p-2" 
                                        style={{
                                          border: "1px solid #4F4F4F",
                                          borderRadius: "4px"
                                        }}>
                                        <div id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={onAditionalRequestBody}>
                                          <span>
                                            Parámetros de cuerpo (Opcional)
                                          </span>
                                          {selectRequestBody ?
                                            <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                                            <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                                          }
                                        </div>
                                      </div>

                                      {selectRequestBody ? (
                                        <div 
                                          className="panel-collapse pt-2" 
                                          style={{
                                            backgroundColor: "#F1F1F1",
                                            borderRadius: "4px"
                                          }}>
                                          <div className="panel-body" style={{paddingInline: "0px"}}>
                                            <div className="text-center">
                                              <button
                                                type="button"
                                                style={{
                                                  border: 0,
                                                  borderRadius: "4px",
                                                  color: "#7ECCD8",
                                                  backgroundColor: "rgba(126, 204, 216, 0.3)",
                                                  cursor: "pointer",
                                                  fontFamily: "Helvetica, Arial, sans-serif",
                                                  fontSize: "12px",
                                                  fontWeight: "bold",
                                                  width: "150px",
                                                  zIndex: 100,
                                                }}
                                                onClick={() => setModalRequestParamsBody(true)}
                                              > 
                                                <IoMdAdd/>
                                                Agregar Parámetro
                                              </button>
                                            </div>
                                            <div className="mt-2">
                                              {selectedNode.data.requestParamsBody.length > 0 && selectedNode.data.requestParamsBody.map((req, index) => (
                                                <div className="d-flex justify-content-around">
                                                  <div>
                                                    <div style={{fontWeight:"bolder"}}>{req.key}</div>
                                                    <div>{req.value}</div>
                                                  </div>
                                                  <div className="d-flex justify-content-end">
                                                    <button
                                                      onClick={() => {
                                                        setEditModal(true)
                                                        setModalRequestParamsBody(true)
                                                        setRequestParamsBody({
                                                          key: req.key,
                                                          value: req.value,
                                                          index: index
                                                        })
                                                      }}
                                                      className="text-primary border-0 bg-transparent d-block pointer mt-2"
                                                    >
                                                      <BsPencilFill style={{ fontSize: "16px" }}/>
                                                    </button>
                                                    <button 
                                                      className="text-danger border-0 bg-transparent d-block pointer mt-2" 
                                                      onClick={() => {
                                                        const filter = selectedNode.data.requestParamsBody.filter(v => v.key !== req.key)
                                                        setSelectedNode((prevInputValue) => ({
                                                          ...prevInputValue,
                                                          data: {
                                                            ...prevInputValue.data,
                                                            requestParamsBody: filter
                                                          },
                                                        }))
                                                      }}
                                                    >
                                                      <BsTrash3 style={{ fontSize: "16px" }}/>
                                                    </button>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <div className="panel-group collapsible" id="user-collapsible">
                                    <div className="panel panel-default">
                                      <div 
                                        className="panel-heading p-2" 
                                        style={{
                                          border: "1px solid #4F4F4F",
                                          borderRadius: "4px"
                                        }}>
                                        <div id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={onAditionalResponse}>
                                          <span>
                                            Parámetros de respuesta (Opcional)
                                          </span>
                                          {selectResponse ?
                                            <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                                            <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                                          }
                                        </div>
                                      </div>

                                      {selectResponse ? (
                                        <div 
                                          className="panel-collapse pt-2" 
                                          style={{
                                            backgroundColor: "#F1F1F1",
                                            borderRadius: "4px"
                                          }}>
                                          <div className="panel-body" style={{paddingInline: "0px"}}>
                                            <div className="text-center">
                                              <button
                                                type="button"
                                                style={{
                                                  border: 0,
                                                  borderRadius: "4px",
                                                  color: "#7ECCD8",
                                                  backgroundColor: "rgba(126, 204, 216, 0.3)",
                                                  cursor: "pointer",
                                                  fontFamily: "Helvetica, Arial, sans-serif",
                                                  fontSize: "12px",
                                                  fontWeight: "bold",
                                                  width: "150px",
                                                  zIndex: 100,
                                                }}
                                                onClick={() => setModalResponseParams(true)}
                                              > 
                                                <IoMdAdd/>
                                                Agregar Respuesta
                                              </button>
                                            </div>
                                            <div className="mt-2">
                                              {selectedNode.data.responseParams.length > 0 && selectedNode.data.responseParams.map((res, index) => (
                                                <div className="d-flex justify-content-around">
                                                  <div>
                                                    <div style={{fontWeight:"bolder"}}>{res.key}</div>
                                                    <div>{res.value}</div>
                                                  </div>
                                                  <div className="d-flex justify-content-end">
                                                    <button
                                                      onClick={() => {
                                                        setEditModal(true)
                                                        setModalResponseParams(true)
                                                        setResponseParams({
                                                          key: res.key,
                                                          value: res.value,
                                                          index: index
                                                        })
                                                      }}
                                                      className="text-primary border-0 bg-transparent d-block pointer mt-2"
                                                    >
                                                      <BsPencilFill style={{ fontSize: "16px" }}/>
                                                    </button>
                                                    <button 
                                                      className="text-danger border-0 bg-transparent d-block pointer mt-2" 
                                                      onClick={() => {
                                                        const filter = selectedNode.data.responseParams.filter(v => v.key !== res.key)
                                                        setSelectedNode((prevInputValue) => ({
                                                          ...prevInputValue,
                                                          data: {
                                                            ...prevInputValue.data,
                                                            responseParams: filter
                                                          },
                                                        }))
                                                      }}
                                                    >
                                                      <BsTrash3 style={{ fontSize: "16px" }}/>
                                                    </button>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col p-0 mt-2">
                                  <button
                                    type="button"
                                    style={{
                                      border: 0,
                                      borderRadius: "4px",
                                      color: "#fff",
                                      backgroundColor: "#7ECCD8",
                                      cursor: "pointer",
                                      fontFamily: "Helvetica, Arial, sans-serif",
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      width: "150px",
                                      height: "30px",
                                      zIndex: 100,
                                    }}
                                    onClick={() => {
                                      setConnectionTestData({});
                                      setModalConnectionTest(true);
                                    }}
                                  > 
                                    <div className="d-flex align-items-center">
                                      <BiTransfer className="mr-1" style={{fontSize: "15px"}}/>
                                      <span>
                                        Consultar API
                                      </span>
                                    </div>
                                  </button>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="bot_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del Bot: </label>
                                  <div>
                                    <textarea
                                      id="bot_response"
                                      name="bot_response"
                                      placeholder="Escribe la respuesta del Bot"
                                      style={{width: "100%", height: "90px", borderRadius: "4px"}}
                                      value={selectedNode.data.bot_response || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.bot_response) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              bot_response: '¡Hola! ¿Cómo puedo ayudarte hoy?' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            bot_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    <div className="d-flex align-items-center">
                                      <div className="pt-2">
                                        <button 
                                          className="text-dark border-0 bg-transparent d-block pointer" 
                                          type="button"
                                          onClick={(event) => handleClickModalVariables(event, 'bot_response', 'node')}
                                        >
                                          <VscVariableGroup style={{ fontSize: "18px" }}/>
                                        </button>
                                      </div>
                                      <div className="picker-container pt-4" style={{width: "100%"}}>
                                        <i
                                          className={
                                            showPicker
                                              ? "icon-smile emoji-icon active"
                                              : "icon-smile emoji-icon"
                                          }
                                          style={{ fontSize: "22px" }}
                                          onClick={() => setShowPicker((val) => !val)}
                                        />

                                        {showPicker && (
                                          <Picker
                                            pickerStyle={{
                                              width: "100%",
                                              top: "30px",
                                              position: "absolute",
                                              zIndex: "999",
                                            }}
                                            onEmojiClick={onEmojiClick}
                                            groupNames={{
                                              smileys_people: "Emoticones y personas",
                                              animals_nature: "Animales y naturaleza",
                                              food_drink: "Alimentos y bebidas",
                                              travel_places: "Viajes y lugares",
                                              activities: "Actividades",
                                              objects: "Objetos",
                                              symbols: "Símbolos",
                                              flags: "Banderas",
                                              recently_used: "Recientes",
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>

                                    {!selectedNode.data.bot_response || selectedNode.data.bot_response.length > 1024 ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        {!selectedNode.data.bot_response ? 'Campo Respuesta del Bot es obligatorio' 
                                        : 'El campo Respuesta del Bot no debe ser mayor a 1024 caracteres'}
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Respuesta que se le envía al cliente.
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div 
                                  className="col p-2 mt-4"
                                  style={{
                                    border: "1px solid #000",
                                    borderRadius: "4px",
                                    height: "auto"
                                  }}
                                > 
                                  <div className="col p-0">
                                    <span style={{fontWeight: "bolder"}}>
                                      Estados de la Solicitud
                                    </span>
                                  </div>
                                  <div className="col p-0">
                                    <label htmlFor="succes" style={{color: '#6C757D', fontWeight: 200}}>Solicitud exitosa: </label>
                                    <div className="d-flex align-items-center">
                                      <FaCheckCircle className="mr-1" style={{fontSize: "22px", color: "#94CF96"}}/>
                                      <input
                                        id="succes"
                                        name="succes"
                                        type="text"
                                        style={{width: "100%", height: "35px"}}
                                        value={selectedNode.data.succes || ""}
                                        onChange={(e) => {
                                          setSelectedNode((prevInputValue) => {
                                            return {
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                succes: e.target.value
                                              },
                                            }
                                          })
                                        }}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                  <div className="col p-0 mt-2">
                                    <label htmlFor="failed" style={{color: '#6C757D', fontWeight: 200}}>Solicitud fallida: </label>
                                    <div className="d-flex align-items-center">
                                      <BiSolidError className="mr-1" style={{fontSize: "22px", color: "#F88E86"}}/>
                                      <input
                                        id="failed"
                                        name="failed"
                                        type="text"
                                        style={{width: "100%", height: "35px"}}
                                        value={selectedNode.data.failed || ""}
                                        onChange={(e) => {
                                          setSelectedNode((prevInputValue) => {
                                            return {
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                failed: e.target.value
                                              },
                                            }
                                          })
                                        }}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#7ECCD8",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="mr-2"
                                  onClick={async() => {
                                    if(!selectedNode.data.title ||
                                      !selectedNode.data.user_response ||
                                      !selectedNode.data.url_api ||
                                      !selectedNode.data.protocol ||
                                      !selectedNode.data.auth
                                    ) {
                                      return Swal.fire({
                                        title: "Atención!",
                                        text: "Hay campos requeridos vacíos",
                                        icon: "warning",
                                        confirmButtonText: "Aceptar",
                                      });
                                    }

                                    setLoadingUpdate(true);
                                    setSelectedNode((prevInputValue) => ({
                                      ...prevInputValue,
                                      data: {
                                        ...prevInputValue.data,
                                        saved: true
                                      },
                                    }))
                                    setTimeout(async () => {
                                      await updateBot(editFlow, edges, nodes)
                                      setLoadingUpdate(false);
                                    }, 500)
                                  }}
                                >   
                                  {loadingUpdate ? (
                                    <FaSpinner
                                      className="fa-spin"
                                      style={{ fontSize: "25px" }}
                                    />
                                  ) : (
                                    <span>Guardar</span>
                                  )}
                                </button>

                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#7ECCD8",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="ml-2"
                                  onClick={async() => {
                                    setTimeout(() => {
                                      setSelectedNode(null);
                                    }, 200);
                                  }}
                                >   
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                     )
                    : (
                      <div id="content-list-chats" className="card-body" style={{overflowY: "auto"}}>
                        <div style={{fontSize: "1.2rem", fontWeight: "bold"}}>
                          Opciones de Respuestas
                        </div>
                        <div className="mt-2">
                          <ul className="list-group list-group-flush py-2">
                            <li
                              style={{cursor: 'grab', backgroundColor: '#00A884', borderRadius: "4px"}}
                              className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'BasicAnswer')} 
                              draggable
                            >
                              <div className="d-flex align-items-center">
                                <FiMessageSquare style={{ fontSize: "20px", fontWeight: "bold" }}/>
                                <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Respuestas Básicas</span>
                              </div>
                            </li>
                            <li
                              style={{cursor: 'grab', backgroundColor: '#F56F89', marginTop: '6px', borderRadius: "4px"}}
                              className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'TransferAgent')} 
                              draggable
                            >
                              <div className="d-flex align-items-center">
                                <MdOutlineSupportAgent style={{ fontSize: "20px", fontWeight: "bold" }}/>
                                <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Transferir Area/Agente</span>
                              </div>
                            </li>
                            <li
                              style={{cursor: 'grab', backgroundColor: '#5BC0DE', marginTop: '6px', borderRadius: "4px"}}
                              className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'Tags')} 
                              draggable
                            >
                              <div className="d-flex align-items-center">
                                <LuTags style={{ fontSize: "20px", fontWeight: "bold" }}/>
                                <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Etiquetas</span>
                              </div>
                            </li>
                            <li
                              style={{cursor: 'grab', backgroundColor: '#D89C7E', marginTop: '6px', borderRadius: "4px"}}
                              className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'SaveAnswer')} 
                              draggable
                            >
                              <div className="d-flex align-items-center">
                                <HiOutlineSave style={{ fontSize: "20px", fontWeight: "bold" }}/>
                                <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Guardar Respuestas</span>
                              </div>
                            </li>
                            <li
                              style={{cursor: 'grab', backgroundColor: '#B85C7C', marginTop: '6px', borderRadius: "4px"}}
                              className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'FlowsWhatsApp')} 
                              draggable
                            >
                              <div className="d-flex align-items-center">
                                <TiFlowMerge style={{ fontSize: "20px", fontWeight: "bold" }}/>
                                <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Flujos de Whatsapp</span>
                              </div>
                            </li>
                            <li
                              style={{
                                cursor: 'grab',
                                backgroundColor: '#7ECCD8',
                                marginTop: '6px',
                                borderRadius: '4px',
                              }}
                              className="grab list-group-item dndnode text-white"
                              onDragStart={(event) => onDragStart(event, 'QueryApi')} 
                              draggable
                            >
                              <div className="d-flex align-items-center">
                                <BsDatabaseFillAdd style={{ fontSize: "20px", fontWeight: "bold" }}/>
                                <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Consulta API</span>
                              </div>
                            </li>
                            <li
                              style={{
                                cursor: 'grab',
                                backgroundColor: '#A7ADA7',
                                marginTop: '6px',
                                borderRadius: '4px',
                                position: 'relative',
                                overflow: 'hidden',
                              }}
                              className="grab list-group-item dndnode text-white"
                              onDragStart={(event) => onDragStart(event, 'AssitantAI')}
                              draggable
                            >
                              <div className="d-flex align-items-center">
                                <FaBrain style={{ fontSize: '20px', fontWeight: 'bold' }} />
                                <span className="pl-2" style={{ fontSize: '14px', fontWeight: 'bold' }}>Asistente con IA</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )
                  }
                </div>
              </ReactFlow>
            </div>
          </section>
        </>
      )}

      {/* MODAL CREATE BOT */}
      <Modal
        status={modalCreate}
        changeStatus={setModalCreate}
        title="Crear Bot"
        action={() => null}
        width="550px"
      >
        <div 
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
            <p>
              Ingresa un nombre para el bot
            </p>
            <div>
              <input
                className="form-control"
                type="text"
                name="name"
                id="name"
                value={nameBot}
                onChange={(e) => {
                  setNameBot(e.target.value)
                }}
              />
              {!nameBot.length && (
                <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                  Nombre del bot es requerido
                </p>
              )}
            </div>
            <p className="pt-4">
              Ingresa una descripción para el bot
            </p>
            <div>
              <input
                className="form-control"
                type="text"
                name="description"
                id="description"
                value={descriptionBot}
                onChange={(e) => {
                  setDescriptionBot(e.target.value)
                }}
              />
              {!descriptionBot.length && (
                <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                  La descripción del bot es requerida
                </p>
              )}
            </div>
          </div>

          <div className="row justify-content-end bd col-12 mt-4">
            <a
              href="javascript:;"
              className="btn btn-lg btn-default mr-1"
              onClick={() => {
                setModalCreate(false);
              }}
            >
              Cerrar
            </a>
            <a
              href="javascript:;"
              className="btn btn-lg btn-info mr-1"
              onClick={() => {
                if(nameBot && descriptionBot) {
                  setModalCreate(false);
                  setMode("create");
                  createBot()
                }
              }}
            >
              Crear Bot
            </a> 
          </div>
        </div>
      </Modal>
      
      {/* MODAL HEADERS PARAMS */}
      <Modal
        status={modalHeaders}
        changeStatus={setModalHeaders}
        title="Cabecera"
        width={"450px"}
      >
        <div className="justify-content-center">
          <div className="justify-content-center scrollable-content">
            <div className="col">
              <label htmlFor="key">Clave de la Cabecera: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="key"
                  name="key"
                  type="text"
                  value={headerParams.key}
                  onChange={(e) => 
                    setHeaderParams((prev) => ({
                      ...prev,
                      key: e.target.value
                    }))
                  }
                />
              </div>
            </div>
            <div className="col mt-4">
              <label htmlFor="value">Valor de la Cabecera: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="value"
                  name="value"
                  type="text"
                  value={headerParams.value}
                  onChange={(e) => 
                    setHeaderParams((prev) => ({
                      ...prev,
                      value: e.target.value
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-2">
            <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => {
              setModalHeaders(false)
              setEditModal(false)
              setHeaderParams({})
            }}>
              Volver
            </a>
            <input className="btn btn-lg btn-info" style={{width: "150px"}} value="Guardar" 
              onClick={() => {
                if(!editModal) { 
                  const data = [
                    ...selectedNode.data.headers, 
                    headerParams
                  ]
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      headers: data
                    },
                  }))
                } else {
                  let data = selectedNode.data.headers
                  data[headerParams.index].key = headerParams.key
                  data[headerParams.index].value = headerParams.value
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      headers: data
                    },
                  }))
                }
                setModalHeaders(false)
                setEditModal(false)
                setHeaderParams({})
              }}
            />
          </div>
        </div>
      </Modal>
      
      {/* MODAL REQUEST PARAMS */}
      <Modal
        status={modalRequestParams}
        changeStatus={setModalRequestParams}
        title="Parámetros de petición"
        width={"450px"}
      >
        <div className="justify-content-center">
          <div className="justify-content-center scrollable-content">
            <div className="col">
              <label htmlFor="key">Parámetro: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="key"
                  name="key"
                  type="text"
                  value={requestParams.key}
                  onChange={(e) => 
                    setRequestParams((prev) => ({
                      ...prev,
                      key: e.target.value
                    }))
                  }
                />
              </div>
            </div>
            <div className="col mt-4">
              <label htmlFor="value">Valor: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="value"
                  name="value"
                  type="text"
                  value={requestParams.value}
                  onChange={(e) => 
                    setRequestParams((prev) => ({
                      ...prev,
                      value: e.target.value
                    }))
                  }
                />
              </div>
              <div className="pt-2">
                <button 
                  className="text-dark border-0 bg-transparent d-block pointer" 
                  type="button"
                  onClick={(event) => handleClickModalVariables(event, 'value', 'modalRequestParams')}
                >
                  <VscVariableGroup style={{ fontSize: "18px" }}/>
                </button>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-2">
            <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => {
              setModalRequestParams(false)
              setEditModal(false)
              setRequestParams({})
            }}>
              Volver
            </a>
            <button className="btn btn-lg btn-info" style={{width: "150px"}} 
              onClick={() => {
                if(!editModal) { 
                  const data = [
                    ...selectedNode.data.requestParams, 
                    requestParams
                  ]
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      requestParams: data
                    },
                  }))
                } else {
                  let data = selectedNode.data.requestParams
                  data[requestParams.index].key = requestParams.key
                  data[requestParams.index].value = requestParams.value
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      requestParams: data
                    },
                  }))
                }
                setModalRequestParams(false)
                setEditModal(false)
                setRequestParams({})
              }}
              disabled={!requestParams.key || !requestParams.value}
            >
              Guardar
            </button>
          </div>
        </div>
      </Modal>

      {/* MODAL REQUEST PARAMS BODY */}
      <Modal
        status={modalRequestParamsBody}
        changeStatus={setModalRequestParamsBody}
        title="Parámetros de petición Body"
        width={"450px"}
      >
        <div className="justify-content-center">
          <div className="justify-content-center scrollable-content">
            <div className="col">
              <label htmlFor="key">Parámetro: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="key"
                  name="key"
                  type="text"
                  value={requestParamsBody.key}
                  onChange={(e) => 
                    setRequestParamsBody((prev) => ({
                      ...prev,
                      key: e.target.value
                    }))
                  }
                />
              </div>
            </div>
            <div className="col mt-4">
              <label htmlFor="value">Valor: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="value"
                  name="value"
                  type="text"
                  value={requestParamsBody.value}
                  onChange={(e) => 
                    setRequestParamsBody((prev) => ({
                      ...prev,
                      value: e.target.value
                    }))
                  }
                />
              </div>
              <div className="pt-2">
                <button 
                  className="text-dark border-0 bg-transparent d-block pointer" 
                  type="button"
                  onClick={(event) => handleClickModalVariables(event, 'value', 'modalRequestParamsBody')}
                >
                  <VscVariableGroup style={{ fontSize: "18px" }}/>
                </button>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-2">
            <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => {
              setModalRequestParamsBody(false)
              setEditModal(false)
              setRequestParamsBody({})
            }}>
              Volver
            </a>
            <button className="btn btn-lg btn-info" style={{width: "150px"}} 
              onClick={() => {
                if(!editModal) { 
                  const data = [
                    ...selectedNode.data.requestParamsBody, 
                    requestParamsBody
                  ]
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      requestParamsBody: data
                    },
                  }))
                } else {
                  let data = selectedNode.data.requestParamsBody
                  data[requestParamsBody.index].key = requestParamsBody.key
                  data[requestParamsBody.index].value = requestParamsBody.value
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      requestParamsBody: data
                    },
                  }))
                }
                setModalRequestParamsBody(false)
                setEditModal(false)
                setRequestParamsBody({})
              }}
              disabled={!requestParamsBody.key || !requestParamsBody.value}
            >
              Guardar
            </button>
          </div>
        </div>
      </Modal>
      
      {/* MODAL RESPONSE PARAMS */}
      <Modal
        status={modalResponseParams}
        changeStatus={setModalResponseParams}
        title="Parámetros de respuesta"
        width={"450px"}
      >
        <div className="justify-content-center">
          <div className="justify-content-center scrollable-content">
            <div className="col">
              <label htmlFor="key">Parámetro: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="key"
                  name="key"
                  type="text"
                  value={responseParams.key}
                  onChange={(e) => 
                    setResponseParams((prev) => ({
                      ...prev,
                      key: e.target.value
                    }))
                  }
                />
                <RichTreeView 
                  items={listRequestParams}
                  onSelectedItemsChange={handleItemValSelect}
                />
                <p className="mt-2 p-2" style={{backgroundColor: "#F1F1F1", borderRadius: "4px"}}>
                  Para que se listen opciones de variables, 
                  debe realizar una consulta previa a la Api para listar los datos.
                </p>
              </div>
            </div>
            <div className="col mt-4">
              <label htmlFor="value">Variable: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="value"
                  name="value"
                  type="text"
                  value={responseParams.value}
                  readOnly 
                />
                <div className="pt-2">
                  <button 
                    className="text-dark border-0 bg-transparent d-block pointer" 
                    type="button"
                    onClick={(event) => handleClickModalVariables(event, 'value', 'modalResponseParams')}
                  >
                    <VscVariableGroup style={{ fontSize: "18px" }}/>
                  </button>
                </div>
                <p className="mt-1">
                  Variable en la que se desea guardar la respuesta (Opcional).
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-2">
            <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => {
              setModalResponseParams(false)
              setEditModal(false)
              setResponseParams({})
            }}>
              Volver
            </a>
            <button className="btn btn-lg btn-info" style={{width: "150px"}}
              onClick={() => {
                if(!editModal) { 
                  const data = [
                    ...selectedNode.data.responseParams, 
                    responseParams
                  ]
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      responseParams: data
                    },
                  }))
                } else {
                  let data = selectedNode.data.responseParams
                  data[responseParams.index].key = responseParams.key
                  data[responseParams.index].value = responseParams.value
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      responseParams: data
                    },
                  }))
                }
                setModalResponseParams(false)
                setEditModal(false)
                setResponseParams({})
              }}
              disabled={!responseParams.key}
            >
              Guardar
            </button>
          </div>
        </div>
      </Modal>

      {/* MODAL REQUEST RESPONSE API */}
      <Modal
        status={modalConnectionTest}
        changeStatus={setModalConnectionTest}
        title="Prueba de Conexión"
        width={"550px"}
      >
        <div 
          className="justify-content-center"
          style={{
            maxHeight: "450px",
            overflowY: "auto"
          }}
        >
          <div 
            className="p-2" 
            style={{
              backgroundColor: 'rgba(126, 204, 216, 0.3)', 
              borderRadius: '4px',
              fontSize: 11,
            }}
          >
            <span style={{fontWeight: 600}}>Importante: </span> 
            Esta prueba funcionará con los datos ya previamente configurados como URL de la api y Protocol HTTP. 
            Por otro lado, los parametros como cabecera, parametros de petición entre otros, deben configurarse
            en la prueba directamente.
          </div>
          <div 
            className="mt-4 py-2 px-3"
            style={{
              border: "1px solid #545454",
              borderRadius: "4px"
            }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div>
                Cabeceras (Opcional)
              </div>
              <div>
                <button
                  type="button"
                  style={{
                    border: 0,
                    borderRadius: "4px",
                    color: "#7ECCD8",
                    backgroundColor: "rgba(126, 204, 216, 0.3)",
                    cursor: "pointer",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "150px",
                    zIndex: 100,
                  }}
                  onClick={() => {
                    const newHeader = { key: "", value: "" };
                    setConnectionTestData((prev) => ({
                      ...prev,
                      headers: [...(prev.headers || []), newHeader],
                    }));
                  }}                                   
                > 
                  <IoMdAdd/>
                  Agregar Cabecera
                </button>
              </div>
            </div>
            {connectionTestData?.headers?.length > 0 && connectionTestData.headers.map((res, index) => (
              <div className="d-flex justify-content-around mt-2" key={index}>
                <div className="d-flex align-items-center">
                  <div className="mr-2">
                    <input
                      type="text"
                      style={{width: "100%", height: "35px"}}
                      value={res.key || ""}
                      onChange={(e) => {
                        const newHeaders = [...connectionTestData.headers];
                        newHeaders[index].key = e.target.value;
                        setConnectionTestData((prev) => ({
                          ...prev,
                          headers: newHeaders,
                        }));
                      }}
                    />
                  </div>
                  <div className="ml-2">
                    <input
                      type="text"
                      style={{width: "100%", height: "35px"}}
                      value={res.value || ""}
                      onChange={(e) => {
                        const newHeaders = [...connectionTestData.headers];
                        newHeaders[index].value = e.target.value;
                        setConnectionTestData((prev) => ({
                          ...prev,
                          headers: newHeaders,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button 
                    className="text-danger border-0 bg-transparent d-block pointer mt-2" 
                    onClick={() => {
                      setConnectionTestData((prev) => ({
                        ...prev,
                        headers: prev.headers.filter((_, i) => i !== index),
                      }));
                    }}
                  >
                    <BsTrash3 style={{ fontSize: "16px" }}/>
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div 
            className="mt-4 py-2 px-3"
            style={{
              border: "1px solid #545454",
              borderRadius: "4px"
            }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div>
                Parametros (Opcional)
              </div>
              <div>
                <button
                  type="button"
                  style={{
                    border: 0,
                    borderRadius: "4px",
                    color: "#7ECCD8",
                    backgroundColor: "rgba(126, 204, 216, 0.3)",
                    cursor: "pointer",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "150px",
                    zIndex: 100,
                  }}
                  onClick={() => {
                    const newParam = { key: "", value: "" };
                    setConnectionTestData((prev) => ({
                      ...prev,
                      params: [...(prev.params || []), newParam],
                    }));
                  }}                                   
                > 
                  <IoMdAdd/>
                  Agregar Parametro
                </button>
              </div>
            </div>
            {connectionTestData?.params?.length > 0 && connectionTestData.params.map((res, index) => (
              <div className="d-flex justify-content-around mt-2" key={index}>
                <div className="d-flex align-items-center">
                  <div className="mr-2">
                    <input
                      type="text"
                      style={{width: "100%", height: "35px"}}
                      value={res.key || ""}
                      onChange={(e) => {
                        const newParams = [...connectionTestData.params];
                        newParams[index].key = e.target.value;
                        setConnectionTestData((prev) => ({
                          ...prev,
                          params: newParams,
                        }));
                      }}
                    />
                  </div>
                  <div className="ml-2">
                    <input
                      type="text"
                      style={{width: "100%", height: "35px"}}
                      value={res.value || ""}
                      onChange={(e) => {
                        const newParams = [...connectionTestData.params];
                        newParams[index].value = e.target.value;
                        setConnectionTestData((prev) => ({
                          ...prev,
                          params: newParams,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button 
                    className="text-danger border-0 bg-transparent d-block pointer mt-2" 
                    onClick={() => {
                      setConnectionTestData((prev) => ({
                        ...prev,
                        params: prev.params.filter((_, i) => i !== index),
                      }));
                    }}
                  >
                    <BsTrash3 style={{ fontSize: "16px" }}/>
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div 
            className="mt-4 py-2 px-3"
            style={{
              border: "1px solid #545454",
              borderRadius: "4px"
            }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div>
                Cuerpo (Opcional)
              </div>
              <div>
                <button
                  type="button"
                  style={{
                    border: 0,
                    borderRadius: "4px",
                    color: "#7ECCD8",
                    backgroundColor: "rgba(126, 204, 216, 0.3)",
                    cursor: "pointer",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "150px",
                    zIndex: 100,
                  }}
                  onClick={() => {
                    const newData = { key: "", value: "" };
                    setConnectionTestData((prev) => ({
                      ...prev,
                      data: [...(prev.data || []), newData],
                    }));
                  }}                                   
                > 
                  <IoMdAdd/>
                  Agregar Dato
                </button>
              </div>
            </div>
            {connectionTestData?.data?.length > 0 && connectionTestData.data.map((res, index) => (
              <div className="d-flex justify-content-around mt-2" key={index}>
                <div className="d-flex align-items-center">
                  <div className="mr-2">
                    <input
                      type="text"
                      style={{width: "100%", height: "35px"}}
                      value={res.key || ""}
                      onChange={(e) => {
                        const newData = [...connectionTestData.data];
                        newData[index].key = e.target.value;
                        setConnectionTestData((prev) => ({
                          ...prev,
                          data: newData,
                        }));
                      }}
                    />
                  </div>
                  <div className="ml-2">
                    <input
                      type="text"
                      style={{width: "100%", height: "35px"}}
                      value={res.value || ""}
                      onChange={(e) => {
                        const newData = [...connectionTestData.data];
                        newData[index].value = e.target.value;
                        setConnectionTestData((prev) => ({
                          ...prev,
                          data: newData,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button 
                    className="text-danger border-0 bg-transparent d-block pointer mt-2" 
                    onClick={() => {
                      setConnectionTestData((prev) => ({
                        ...prev,
                        data: prev.data.filter((_, i) => i !== index),
                      }));
                    }}
                  >
                    <BsTrash3 style={{ fontSize: "16px" }}/>
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4 d-flex align-items-center">
            <button
              type="button"
              style={{
                border: 0,
                borderRadius: "4px",
                color: "#fff",
                backgroundColor: "#7ECCD8",
                cursor: "pointer",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontSize: "12px",
                fontWeight: "bold",
                width: "80px",
                height: "30px",
                zIndex: 100,
              }}
              onClick={() => requestApiNode()}
              disabled={!selectedNode?.data?.url_api || !selectedNode?.data?.protocol}
            > 
              <div className="d-flex align-items-center">
                <BiTransfer className="mr-1" style={{fontSize: "15px"}}/>
                <span>
                  Probar
                </span>
              </div>
            </button>
            {Object.keys(requestResponse).length > 0 && (
              <button
                type="button"
                className="ml-2"
                style={{
                  border: 0,
                  borderRadius: "4px",
                  color: "#fff",
                  backgroundColor: "#7ECCD8",
                  cursor: "pointer",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "150px",
                  height: "30px",
                  zIndex: 100,
                }}
                onClick={(event) => {responseToNode(event)}}
              > 
                <span>
                  Respuesta a Nodo
                </span>
              </button>
            )}
          </div>
          <div className="scrollable-content">
            <AceEditor
              mode="json"
              theme={isDark == 'true' ? 'github_dark' : 'github'}
              value={JSON.stringify(requestResponse, null, 2)}
              readOnly
              editorProps={{ $blockScrolling: true }}
              setOptions={{ useWorker: false }}
              style={{ width: '100%', height: '200px' }}
            />
          </div>
        </div>
      </Modal>

      {/* MODAL CONFIG BOT*/}
      <Modal
        status={configModal}
        changeStatus={setConfigModal}
        title="Configuración"
        width={"450px"}
      >
        <div 
          className="justify-content-center"
          style={{
            maxHeight: "450px",
            overflowY: "auto"
          }}
        >
          <div>
            <div className="col p-0 mt-4">
              <div className="panel-group collapsible" id="user-collapsible">
                <div className="panel panel-default">
                  <div 
                    className="panel-heading p-2" 
                    style={{
                      border: "1px solid #4F4F4F",
                      borderRadius: "4px"
                    }}>
                    <div id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={onAditionalTimeInactivity}>
                      <span>
                        Tiempo de inactividad
                      </span>
                      {selectTimeInactivity ?
                        <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                        <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                      }
                    </div>
                  </div>

                  {selectTimeInactivity ? (
                    <div 
                      className="panel-collapse pt-2" 
                      style={{
                        backgroundColor: "#F1F1F1",
                        borderRadius: "4px"
                      }}>
                        <div className="p-4">
                          <label>Tiempo para enviar mensaje de ausencia: </label>
                          <div className="su-control-group d-flex">
                            <Select
                              styles={isDark == 'true' ? customStylesDark : customStyles}
                              className="form-control basic-multi-select"
                              placeholder="Seleccione..."
                              name="inactivity"
                              value={configBot.inactivityTime}
                              onChange={(option) => {
                                setConfigBot((prev) => ({
                                  ...prev,
                                  inactivityTime: option,
                                }))
                              }}
                              options={listInactivity}
                              classNamePrefix="select"
                              noOptionsMessage={() => "Sin opciones"}
                              getOptionLabel={(option) => (
                                <div className="option d-flex" style={{fontSize: "15px"}}>
                                  {option.label} 
                                </div>
                              )}
                              getOptionValue={(option) => option.value}
                            />
                          </div>
                        </div>
                        <div className="p-4">
                          <label>Tiempo para cerrar la conversacion: </label>
                          <div className="su-control-group d-flex">
                            <Select
                              styles={isDark == 'true' ? customStylesDark : customStyles}
                              className="form-control basic-multi-select"
                              placeholder="Seleccione..."
                              name="inactivity"
                              value={configBot.inactivityTimeClose}
                              onChange={(option) => {
                                if(option.value > configBot?.inactivityTime.value) {
                                  setConfigBot((prev) => ({
                                    ...prev,
                                    inactivityTimeClose: option,
                                  }))
                                }
                              }}
                              options={listInactivity}
                              classNamePrefix="select"
                              noOptionsMessage={() => "Sin opciones"}
                              getOptionLabel={(option) => (
                                <div className="option d-flex" style={{fontSize: "15px"}}>
                                  {option.label} 
                                </div>
                              )}
                              getOptionValue={(option) => option.value}
                            />
                          </div>
                          <p style={{fontSize: 11, fontWeight: 200}}>
                            Ten en cuenta que el tiempo seleccionado siempre debe ser 
                            <strong> MAYOR</strong> al <strong>Tiempo para enviar mensaje de ausencia</strong>
                          </p>
                        </div>
                        <div className="col p-0 px-4">
                          <div className="su-control">
                            <label htmlFor="companySchedule_message" style={{color: '#6C757D', fontWeight: 200}}>Mensaje de ausencia: </label>
                            <div className="su-control-group">
                              <textarea
                                className="form-control"
                                id="companySchedule_message"
                                name="companySchedule_message"
                                placeholder="Escribe el mensaje"
                                style={{width: "100%", height: "90px", borderRadius: "4px"}}
                                value={configBot?.inactivityMessages || ""}
                                onChange={(e) => {
                                  setConfigBot((prev) => ({
                                    ...prev,
                                    inactivityMessages: e.target.value
                                  }))
                                  setValidateMessageConfig(false)
                                }}
                              />

                                <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                  Mensaje de ausencia que se envía al cliente.
                                </p>

                                {validateMessageConfig && (
                                  <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                    Por favor escribe un mensaje de ausencia.
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col p-0 mt-4">
              <div className="panel-group collapsible" id="user-collapsible">
                <div className="panel panel-default">
                  <div 
                    className="panel-heading p-2" 
                    style={{
                      border: "1px solid #4F4F4F",
                      borderRadius: "4px"
                    }}>
                    <div id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={onAditionalControlMessage}>
                      <span>
                        Mensaje de control
                      </span>
                      {selectControlMessage ?
                        <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                        <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                      }
                    </div>
                  </div>

                  {selectControlMessage ? (
                    <div 
                      className="panel-collapse pt-2" 
                      style={{
                        backgroundColor: "#F1F1F1",
                        borderRadius: "4px"
                      }}>
                        <div className="col p-0 px-4">
                          <label htmlFor="controlMessage_message" style={{color: '#6C757D', fontWeight: 200}}>Mensaje de control: </label>
                          <div>
                            <textarea
                              className="form-control"
                              id="controlMessage_message"
                              name="controlMessage_message"
                              placeholder="Escribe el mensaje"
                              style={{width: "100%", height: "90px", borderRadius: "4px"}}
                              value={configBot?.controlMessage || ""}
                              onChange={(e) => {
                                setConfigBot((prev) => ({
                                  ...prev,
                                  controlMessage: e.target.value
                                }))
                                setValidateMessageConfig(false);
                              }}
                            />

                              <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                Mensaje de control que se envía al cliente si la respuesta u 
                                opción que seleccione o escriba no es la esperada por el Bot.
                              </p>

                              {validateMessageConfig && (
                                <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                  Por favor escribe un mensaje de control.
                                </p>
                              )}
                          </div>
                        </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col p-0 mt-4">
              <div className="panel-group collapsible" id="user-collapsible">
                <div className="panel panel-default">
                  <div 
                    className="panel-heading p-2" 
                    style={{
                      border: "1px solid #4F4F4F",
                      borderRadius: "4px"
                    }}>
                    <div id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={onAditionalSOS}>
                      <span>
                        Mensajes SOS
                      </span>
                      {selectSOS ?
                        <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                        <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                      }
                    </div>
                  </div>

                  {selectSOS ? (
                    <div 
                      className="panel-collapse pt-2" 
                      style={{
                        backgroundColor: "#F1F1F1",
                        borderRadius: "4px"
                      }}>

                        <div className="px-4 py-2">
                          <label htmlFor="list_sos_messages">Mensajes de ayuda: </label>
                          <div>
                            <textarea
                              className="form-control"
                              id="list_sos_messages"
                              name="list_sos_messages"
                              placeholder="Escribe el mensaje"
                              style={{ width: "100%", height: "90px", borderRadius: "4px" }}
                              value={(configBot?.listSOSMessages || "")}
                              onChange={(e) => {
                                let value = e.target.value;
                                setConfigBot((prev) => ({
                                  ...prev,
                                  listSOSMessages: value
                                }));
                              }}
                            />
                            <p style={{fontSize: 11, fontWeight: 200}}>
                              Este campo guardará las palabras como una lista de palabras de ayuda,
                              para poder lograr esto, se debe agregar una coma (,) luego de cada palabra, ejemplo
                              (Help, Ayuda, Agente, Agente).
                            </p>
                          </div>
                        </div>
                        <div className="px-4 py-2">
                          <label>Intentos para controlar ayuda: </label>
                          <div className="su-control-group d-flex">
                            <Select
                              styles={isDark == 'true' ? customStylesDark : customStyles}
                              className="form-control basic-multi-select"
                              placeholder="Seleccione..."
                              name="inactivity"
                              value={configBot.attemptsSOS}
                              onChange={(option) => {
                                setConfigBot((prev) => ({
                                  ...prev,
                                  attemptsSOS: option,
                                }))
                              }}
                              options={listAttempts}
                              classNamePrefix="select"
                              noOptionsMessage={() => "Sin opciones"}
                              getOptionLabel={(option) => (
                                <div className="option d-flex" style={{fontSize: "15px"}}>
                                  {option.label} 
                                </div>
                              )}
                              getOptionValue={(option) => option.value}
                            />
                          </div>
                          <p style={{fontSize: 11, fontWeight: 200}}>
                            Este número de intentos se refiere a la cantidad de repeticiones
                            del mensaje de control de error, es decir según los intentos que se seleccionen, será 
                            el máximo de mensajes de control o error que se mostrarán y posteriormente realizár transferencia de ayuda.
                          </p>
                        </div>
                        <div className="px-4 py-2">
                          <label>Transferencia de ayuda: </label>
                          <div className="d-flex mb-2">
                            <div className="d-flex pointer mr-1">
                              <input
                                style={{accentColor: '#5BC0DE'}}
                                type="radio"
                                value="areas"
                                className="pointer"
                                checked={configBot?.transferSOS?.type === "areas"}
                                onChange={(e) => {
                                  setConfigBot((prev) => ({
                                    ...prev,
                                    transferSOS: {
                                      ...prev?.transferSOS,
                                      type: e.target.value
                                    },
                                  }))
                                }}
                              />
                              <label
                                className="d-flex mb-0 ml-1 pointer d-flex align-items-center"
                                style={{ padding: "5px 2px 5px" }}
                              >
                                Areas
                              </label>
                            </div>
                            <div className="d-flex pointer mr-1">
                              <input
                                style={{accentColor: '#5BC0DE'}}
                                type="radio"
                                value="agents"
                                className="pointer"
                                checked={configBot?.transferSOS?.type === "agents"}
                                onChange={(e) => {
                                  setConfigBot((prev) => ({
                                    ...prev,
                                    transferSOS: {
                                      ...prev?.transferSOS,
                                      type: e.target.value
                                    },
                                  }))
                                }}
                              />
                              <label
                                className="d-flex mb-0 ml-1 pointer d-flex align-items-center"
                                style={{ padding: "5px 2px 5px" }}
                              >
                                Agentes
                              </label>
                            </div>
                          </div>

                          {configBot?.transferSOS?.type == 'areas' && (
                            <div>
                              <label>Areas: </label>
                              <div className="su-control-group d-flex">
                                <Select
                                  styles={isDark == 'true' ? customStylesDark : customStyles}
                                  className="form-control basic-multi-select"
                                  placeholder="Seleccione..."
                                  name="areas"
                                  value={configBot?.transferSOS?.agent_area}
                                  onChange={(area) => {
                                    setConfigBot((prev) => ({
                                      ...prev,
                                      transferSOS: {
                                        ...prev?.transferSOS,
                                        agent_area: area
                                      },
                                    }))
                                  }}
                                  options={listAreas}
                                  classNamePrefix="select"
                                  noOptionsMessage={() => "Sin opciones"}
                                  getOptionLabel={(option) => (
                                    <div className="option d-flex" style={{fontSize: "15px"}}>
                                      {option.label} 
                                    </div>
                                  )}
                                  getOptionValue={(option) => option.value}
                                />
                              </div>
                            </div>
                          )}
                          {configBot?.transferSOS?.type == 'agents' && (
                            <div>
                              <label>Agentes: </label>
                              <div className="su-control-group d-flex">
                                <Select
                                  styles={isDark == 'true' ? customStylesDark : customStyles}
                                  className="form-control basic-multi-select"
                                  placeholder="Seleccione..."
                                  name="agents"
                                  value={configBot?.transferSOS?.agent_area}
                                  onChange={(agent) => {
                                    setConfigBot((prev) => ({
                                      ...prev,
                                      transferSOS: {
                                        ...prev?.transferSOS,
                                        agent_area: agent
                                      },
                                    }))
                                  }}
                                  options={listAdvisers}
                                  classNamePrefix="select"
                                  noOptionsMessage={() => "Sin opciones"}
                                  getOptionLabel={(option) => (
                                    <div className="option d-flex" style={{fontSize: "15px"}}>
                                      {option.label} 
                                    </div>
                                  )}
                                  getOptionValue={(option) => option.value}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="px-4 py-2">
                          <label>Mensajes de espera: </label>
                          <div>
                            <textarea
                              className="form-control"
                              placeholder="Escribe el mensaje"
                              style={{ width: "100%", height: "90px", borderRadius: "4px" }}
                              value={(configBot?.waitingMessageSOS || "")}
                              onChange={(e) => {
                                let value = e.target.value;
                                setConfigBot((prev) => ({
                                  ...prev,
                                  waitingMessageSOS: value
                                }));
                              }}
                            />
                            <p style={{fontSize: 11, fontWeight: 200}}>
                              Mensaje que se envía una vez realizada la transferenciade ayuda
                              para comunicar al usuario la acción que se esta realizando.
                            </p>
                          </div>
                        </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {/* HORARIO COMERCIAL */}
            {/* <div className="col p-0 mt-4">
              <div className="panel-group collapsible" id="user-collapsible">
                <div className="panel panel-default">
                  <div 
                    className="panel-heading p-2" 
                    style={{
                      border: "1px solid #4F4F4F",
                      borderRadius: "4px",
                      backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    }}>
                    <div 
                      style={{
                        cursor: 'not-allowed'
                      }}
                      title="Próximamente"
                      id="title-aditionalDates" 
                      className="panel-title d-flex justify-content-between align-items-center pointer" 
                      // onClick={onAditionalCompanySchedule}
                    >
                      <span>
                        Horario comercial
                      </span>
                      {selectCompanySchedule ?
                        <IoIosArrowDropup className="mr-3" style={{ fontSize: "20px", cursor: 'not-allowed'}}/> :
                        <IoIosArrowDropdown className=" mr-3" style={{ fontSize: "20px", cursor: 'not-allowed'}}/>
                      }
                    </div>
                  </div>

                  {selectCompanySchedule ? (
                    <div 
                      className="panel-collapse pt-2" 
                      style={{
                        backgroundColor: "#F1F1F1",
                        borderRadius: "4px"
                      }}
                    >
                      <div className="p-4">
                          <label>Horario: </label>
                          <div className="su-control-group d-flex">
                            <Select
                              styles={isDark == 'true' ? customStylesDark : customStyles}
                              className="form-control basic-multi-select"
                              placeholder="Seleccione..."
                              name="companySchedule"
                              value={configBot.companySchedule}
                              onChange={(option) => {
                                setConfigBot((prev) => ({
                                  ...prev,
                                  companySchedule: option,
                                }))
                              }}
                              options={companyScheduleOptions}
                              classNamePrefix="select"
                              noOptionsMessage={() => "Sin opciones"}
                              getOptionLabel={(option) => (
                                <div className="option d-flex" style={{fontSize: "15px"}}>
                                  {option.label} 
                                </div>
                              )}
                              getOptionValue={(option) => option.value}
                            />
                          </div>
                        </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div> */}
          </div>
          <div className="mb-2 mt-3 text-center">
            <input className="btn btn-lg btn-info" style={{width: "150px"}} value="Guardar"  type="button"    
              onClick={() => {
                handleBotConfig()
              }}
            />
          </div>
        </div>
      </Modal>

      {/* MODAL SELECCIONAR PLANTILLA PARA BOT*/}
      <Modal
        status={selectedTemplateModal}
        changeStatus={setSelectedTemplateModal}
        title="Escoge una plantilla para diseñar tu bot"
        width={"90%"}
      >
        <div className="content-list-contacts su-control mt-2 text-center" style={{overflow: "auto", height: "75vh"}}>
          <div className="options-container">
            <div key="01" className="option-item">
              <div>
                <img
                  className="img-answer w-100"
                  src="https://media-appsendingup-us-east-1.s3.amazonaws.com/f7e0bb5-76c4-0a1-dcf-e2b2c73b03/1730476539201.jpeg"
                  style={{ margin: "0 auto" }}
                />
              </div>
              <div>
                <h5>Crear desde cero</h5>
                <p>Si deseas crear un bot desde cero, selecciona esta opción.</p>
              </div>
              <div>
                <input 
                  type="button" 
                  className="btn btn-default" 
                  value="Crear bot🤖"
                  onClick={() => {
                    setNodes(initialNodes);
                    setSelectedTemplateModal(false);
                    setModalCreate(true);
                  }}
                />
              </div>
            </div> 
            {listBotsTemplates && listBotsTemplates.map(item => (
              <div key={item._id} className="option-item">
                <div>
                  <img
                    className="img-answer w-100"
                    src={item.image}
                    style={{ margin: "0 auto" }}
                  />
                </div>
                <div>
                  <h5>{item.name}</h5>
                  <p>{item.description}</p>
                </div>
                <div>
                  <input 
                    type="button" 
                    className="btn btn-default" 
                    value="Crear bot🤖"
                    onClick={() => {
                      let name = item.name;
                      const filter = listBots.filter(v => v.name.includes(name))
                      if(filter.length) {
                        name = name + " " + (filter.length + 1)
                      } 

                      setNameBot(name);
                      setDescriptionBot(item.description);
                      setNodes(item.nodes);
                      setEdges(item.edges);
                      setShowModalCreateTemplate(true);
                    }}
                  />
                </div>
              </div> 
            ))}
          </div>
        </div>
      </Modal>

      {/* MODAL VARIABLES */}
      <Dialog
        open={variablesModal}
        onClose={() => setVariablesModal(false)}
        anchorEl={anchorEl}
        PaperProps={{
          style: {
            position: 'absolute',
            top: anchorEl ? anchorEl.getBoundingClientRect().top - 30 : 0,
            left: anchorEl ? anchorEl.getBoundingClientRect().left : 0,
            minWidth: '200px',
            padding: '10px',
            overflow: 'visible',
            backgroundColor: isDark == 'true' ? '#162229' : '#fff',
            zIndex: 1200
          },
        }}
      > 
        <label id="variables">Variables: </label>
        <div className="su-control-group d-flex">
          <Select
            styles={isDark == 'true' ? {...customStylesDark, menuPortal: base => ({ ...base, zIndex: 1300 })} : {...customStyles, menuPortal: base => ({ ...base, zIndex: 1300 })}}
            className="form-control basic-multi-select"
            placeholder="Seleccione..."
            name="variables"
            onChange={(val) => {
              console.log(typeInput, typeInputValue, 'typeInput')
              if(typeInput == "node") {
                setSelectedNode((prevInputValue) => ({
                  ...prevInputValue,
                  data: {
                    ...prevInputValue.data,
                    [typeInputValue]: prevInputValue.data[typeInputValue]? prevInputValue.data[typeInputValue] + " " + val.fieldCorresp : val.fieldCorresp
                  },
                }))
              } else if(typeInput == "modalRequestParams") {
                setRequestParams((prevInputValue) => ({
                  ...prevInputValue,
                  [typeInputValue]: prevInputValue[typeInputValue] ? prevInputValue[typeInputValue] + " " + val.fieldCorresp : val.fieldCorresp
                }))
              } else if(typeInput == "modalRequestParamsBody") {
                setRequestParamsBody((prevInputValue) => ({
                  ...prevInputValue,
                  [typeInputValue]: prevInputValue[typeInputValue] ? prevInputValue[typeInputValue] + " " + val.fieldCorresp : val.fieldCorresp
                }))
              } else {
                setResponseParams((prevInputValue) => ({
                  ...prevInputValue,
                  [typeInputValue]: prevInputValue[typeInputValue] ? prevInputValue[typeInputValue] + " " + val.fieldCorresp : val.fieldCorresp
                }))
              }
            }}
            options={correspondence}
            classNamePrefix="select"
            noOptionsMessage={() => "Sin opciones"}
            getOptionLabel={(option) => (
                <div className="option d-flex" style={{fontSize: "15px"}}>
                    {option.fieldName}
                </div>
            )}
            getOptionValue={(option) => option.fieldName}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            menuPlacement="auto"
            menuShouldScrollIntoView={false}
          />
        </div>
      </Dialog>

      {/* MODAL EDIT NAME */}
      <Modal
        status={showEditName}
        changeStatus={setShowEditName}
        title="Editar nombre"
        action={() => null}
        width="350px"
      >
        <div 
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
            <div>
              <input
                className="form-control"
                type="text"
                name="name"
                id="name"
                value={editName}
                onChange={(e) => {
                  setEditName(e.target.value)
                }}
              />
            </div>
          </div>

          <div className="row justify-content-end bd col-12 mt-4">
            <a
              href="javascript:;"
              className="btn btn-lg btn-default mr-1"
              onClick={() => {
                setShowEditName(false);
                setEditName("");
              }}
            >
              Cancelar
            </a>
            <button
              href="javascript:;"
              className="btn btn-lg btn-info mr-1"
              onClick={() => updateNameBot(editFlow)}
              disabled={!editName}
            >
              Guardar
            </button> 
          </div>
        </div>
      </Modal>

      {/* MODAL CONFIRM TEMPLATE */}
      <Modal
        status={showModalCreateTemplate}
        changeStatus={setShowModalCreateTemplate}
        title="Confirmar Plantilla"
        action={() => null}
        width="350px"
      >
        <div 
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
            <div className="text-center">
              ¿Estas seguro de crear el bot a partir esta plantilla?
            </div>
          </div>

          <div className="row justify-content-end bd col-12 mt-4">
            <a
              href="javascript:;"
              className="btn btn-lg btn-default mr-1"
              onClick={() => {
                setNameBot("");
                setDescriptionBot("");
                setNodes([]);
                setEdges([]);
                setShowModalCreateTemplate(false);
              }}
            >
              Cancelar
            </a>
            <button
              href="javascript:;"
              className="btn btn-lg btn-info mr-1"
              onClick={() => {
                setShowModalCreateTemplate(false);
                setSelectedTemplateModal(false);
                setMode("create");
                createBot();
              }}
            >
              Guardar
            </button> 
          </div>
        </div>
      </Modal>

      {/* MODAL RESPONSE TO NODE */}
      <Dialog
        open={responseToNodeModal}
        onClose={() => setResponseToNodeModal(false)}
        anchorEl={anchorEl2}
        PaperProps={{
          style: {
            position: 'absolute',
            top: anchorEl2 ? anchorEl2.getBoundingClientRect().top - 150 : 0,
            left: anchorEl2 ? anchorEl2.getBoundingClientRect().left + 100 : 0,
            minWidth: '200px',
            maxHeight: "400px",
            padding: '20px',
            overflow: 'visible',
            overflowY: "auto",
            backgroundColor: isDark == 'true' ? '#162229' : '#fff',
            zIndex: 1200
          },
        }}
      > 
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}> 
            <IoClose onClick={() => {
              setResponseToNodeModal(false);
              setListToNodeData({
                description: null,
                options: []
              });
            }}/>
          </div>
          <div className="text-center pb-2" style={{color: "#545454", fontWeight: 700}}>
            Agregar botones u opciones
          </div>
          <div>
            <textarea
              placeholder="Escribe la respuesta del Bot"
              style={{ width: "100%", height: "80px", borderRadius: "4px" }}
              value={listToNodeData.description || ""}
              onChange={(e) => {
                setListToNodeData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }));
              }}
            />
          </div>
          <div>
            <button
              type="button"
              style={{
                border: 0,
                borderRadius: "4px",
                color: "#7ECCD8",
                backgroundColor: "rgba(126, 204, 216, 0.3)",
                cursor: "pointer",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100%",
                zIndex: 100,
              }}
              onClick={() => {
                const length = listToNodeData.options.length
                const newOption = {
                  label: "",
                  id: selectedNode.id+'-'+(length+1)+'-Opción-' + (length +1),
                  name: 'titulo',
                }
                setListToNodeData((prev) => ({
                  ...prev,
                  options: [...(prev.options || []), newOption],
                }));
              }}  
              disabled={listToNodeData.options.length >= 10}                                 
            > 
              <IoMdAdd/>
              Agregar Opción
            </button>
          </div>
          {listToNodeData?.options?.length > 0 && listToNodeData.options.map((opt, index) => {
            const maxLengthValue = listToNodeData?.options?.length > 3 ? 24 : 20;
            return (
              <div 
                className="d-flex justify-content-around mt-2" 
                key={index}
              >
                <div style={{width: "80%", height: "35px"}}>
                  <input
                    type="text"
                    value={opt.label || ""}
                    onChange={(e) => {
                      const newOption = [...listToNodeData.options];
                      newOption[index].label = e.target.value;
                      setListToNodeData((prev) => ({
                        ...prev,
                        options: newOption,
                      }));
                    }}
                    maxLength={maxLengthValue}
                  />
                </div>
                <div className="ml-2 d-flex align-intems-center">
                  <button 
                    className="border-0 bg-transparent d-block pointer mt-2" 
                    onClick={(event) => {
                      setVariablesOptionsModal(true);
                      setAnchorEl(event.currentTarget);
                      setIndexOptionResToNode(index);
                    }}
                  >
                    <LuListTree style={{ fontSize: "16px" }}/>
                  </button>
                  <button 
                    className="text-danger border-0 bg-transparent d-block pointer mt-2" 
                    onClick={() => {
                      setListToNodeData((prev) => ({
                        ...prev,
                        options: prev.options.filter((_, i) => i !== index),
                      }));
                    }}
                  >
                    <BsTrash3 style={{ fontSize: "16px" }}/>
                  </button>
                </div>
              </div>
            )
          })}
          <div className="mt-2">
            <button
              type="button"
              style={{
                backgroundColor: "#7ECCD8",
                border: 0,
                borderRadius: "4px",
                color: "#fff",
                cursor: "pointer",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontSize: "12px",
                fontWeight: "bold",
                height: "30px",
                width: "100%",
              }}
              className="mr-2"
              onClick={convertToNode}
            >   
              <span>Convertir</span>
            </button>
          </div>
        </div>
      </Dialog>

      {/* MODAL VARIABLES OPTIONS RESPONSE TO NODE*/}
      <Dialog
        open={variablesOptionsModal}
        onClose={() => setVariablesOptionsModal(false)}
        anchorEl={anchorEl}
        PaperProps={{
          style: {
            position: 'absolute',
            top: anchorEl ? anchorEl.getBoundingClientRect().top - 30 : 0,
            left: anchorEl ? anchorEl.getBoundingClientRect().left : 0,
            minWidth: '200px',
            maxHeight: '250px',
            padding: '10px',
            overflow: 'visible',
            overflowY: "auto",
            backgroundColor: isDark == 'true' ? '#162229' : '#fff',
            zIndex: 1200
          },
        }}
      > 
        <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}> 
          <IoClose onClick={() => setVariablesOptionsModal(false)}/>
        </div>
        <RichTreeView 
          items={listOptionsResToNode}
          onSelectedItemsChange={(event, val) => {

            const maxLengthValue = listToNodeData?.options?.length > 3 ? 24 : 20;
            const selectedNode = findNodeById(listOptionsResToNode, val);

            if (selectedNode) {
              const selectedLabel = selectedNode.label;
              let selectedValue = selectedLabel.substring(selectedLabel.indexOf(':') + 2); 

              if (selectedValue.length > maxLengthValue) { 
                selectedValue = selectedValue.substring(0, maxLengthValue);
              }

              const newOption = [...listToNodeData.options];
              newOption[indexOptionResToNode].label = selectedValue; 
              setListToNodeData((prev) => ({
                ...prev,
                options: newOption,
              }));
            }
          }}
       />
      </Dialog>
    </>
  );
};

const FlowWithProvider = () => {
  return (
    <ReactFlowProvider>
      <Flow/>
    </ReactFlowProvider>
  );
}

export default FlowWithProvider;
