import React, { useState, useEffect } from "react";
import Header from "../layout/Header";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Spinner from "../plugins/Spinner";
import Swal from "sweetalert2";
import useAPI from "../../hooks/useAPI";
import Modal from "../layout/Modal";
import dayjs from "dayjs";
import { upload_s3 } from './UploaderS3';
import Select from "react-select";

import { BsSearch, BsPencilFill, BsTrash3 } from "react-icons/bs";
import { FaSortUp, FaSortDown, FaEye } from "react-icons/fa";
import { FaEllipsisVertical } from "react-icons/fa6";
import { GiBrainFreeze } from 'react-icons/gi';
import { SiGooglesheets } from 'react-icons/si';
import Pagination from "../plugins/Pagination";
import usePage from "../../hooks/usePage";


const Assistants = () => {
  const [loading, setLoading] = useState(false);
  const [modalCreateAssistant, setModalCreateAssistant] = useState(false);
  const [listAssistants, setListAssistants] = useState([]);
  const [listBots, setListBots] = useState([]);
  const [validation, setValidation] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState("");
  const [navAdditionalData, setNavAdditionalData] = useState(false);
  const [modeUpdate, setModeUpdate] = useState(false);
  const [idAssistant, setIdAssistant] = useState("");
  const [linkPdf, setLinkPDF] = useState("")
  const [sizeMax, setSizeMax] = useState(false);
  const [isFile, setIsFile] = useState("");
  const [typeArea, setTypeArea] = useState({typeTransfer: ''});
  const [agentArea, setAgentArea] = useState("");
  const [isDark, setIsDark] = useState(false);
  const [listAreas, setListAreas] = useState([]);
  const [listAdvisers, setListAdvisers] = useState([]);

  const { urlAPI_1, urlAPI_2, urlAPI_3, fetchWithAuth } = useAPI();
  const { page, setPage, byPage, resetPage } = usePage();
  const {register, handleSubmit, reset, setValue, formState: { errors }} = useForm({});

  const radioOptions = [
    {id: "1", name: "Digitar instrucciones"},
    {id: "2", name: "Hoja de Google Sheet"}
  ]

  useEffect(() => {
    getAssistants(true);
    getAreas();
    getAgents();
  }, []);

  //#--> Listar asistentes
  const getAssistants = async (getBotsFetch = false) => {
    try {
      setLoading(true);
      const url = urlAPI_3 + "assistants";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Result of the API: getAssistants", result);
      if (result.type === "error") {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (!result.data) {
        setListAssistants([])
        setValidation(true)
      } else {
        setListAssistants(result.data)
        setValidation(false)
      }
      setLoading(false);

      if (getBotsFetch) {
        await getBots();
      }
    } catch (error) {
      console.log('error---->', error);
    }
  };

  //#--> Crear asistente AI
  const postAssistant = async (data) => {
    console.log('DATA a CREAR 🆕:', data)

    let objData = data;
    if(!data.language) {
      objData.role = "Eres un asistente de atención al cliente muy útil, importante y profesional."
      objData.instructions_message = "Tu objetivo es proporcionar respuestas claras y precisas, resolver problemas de manera efectiva y asegurarte de que los clientes se sientan valorados y satisfechos con la atención recibida. Siempre actúas con empatía, cortesía y eficiencia para mejorar la experiencia del cliente."
      objData.language = "1"
    }
    if (objData.file) {
      objData.file = linkPdf
    }

    objData.transfer = agentArea;

    console.log('DATA a CREAR FINALLLLLLLLLLLLLLLLLL🆕:', objData)
    try {
      setLoading(true);
      const url = urlAPI_3 + "createAssistant";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(objData)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Result of the API: 🤖createAssistant:", result);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
      });
      setModalCreateAssistant(false)
      setLoading(false);
      getAssistants()
    } catch (error) {
      console.log('error al crear Asistente---->', error);
    }
  }

  //#--> Actualizar asistente AI
  const putAssistant = async (data) => {
    console.log('DATA a ACTUALIZAR:', data)
    console.log('data.file.length::::', data.file.length)
    console.log('linkPdf::::', linkPdf)
    console.log('isFile::::', isFile)
    
    let objData = data;
    if (objData.file || objData.file.length > 0 || isFile) {
      console.log('::ENTRO AL IF::')
      objData.file = linkPdf.length > 0 ? linkPdf : isFile
    }
    objData.transfer = agentArea;
    console.log('data FINAL::::', objData)
    try {
      setLoading(true);
      const url = urlAPI_3 + "assistant/"+idAssistant;
      const options = { method: "POST", mode: "cors", body: JSON.stringify(objData)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Result of the API: updateAssistant/{id}:", result);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
      });
      setModalCreateAssistant(false)
      setLoading(false);
      getAssistants()
    } catch (error) {
      console.log('error al crear Asistente---->', error);
    }
  }

  //#--> Eliminar asistente AI
  const deleteAssistant = async (id) => {
    const { name } = listAssistants.find(assistant => assistant.id === id);
    
    // Obtengo solo los chatbots que tengan un asistente activo, y que coincidan con el id que se pretende eliminar
    const isAssistantChatbotEnabled = [];
    listBots.forEach(bot => {
      if (bot.nodes?.some(node => node.type === "assistantAI" && node.data.assistant.id === id)) {
        isAssistantChatbotEnabled.push(bot);
      }
    });

    // Si el asistente está siendo usaod por algún BOT, no se puede eliminar
    if (isAssistantChatbotEnabled.length > 0) {
      const namesBotsActive = isAssistantChatbotEnabled.map(bot => (bot.name));
      const messageInfo = `El asistente ${name}, está siendo usado por ${namesBotsActive.length === 1 ? 'el siguiente bot:' : 'los siguientes bots:'} ${namesBotsActive.join(', ')}. No se puede eliminar.`;

      Swal.fire({
        title: "¡Ocurrió un error!",
        // text: messageInfo,
        icon: "warning",
        html: `
          El asistente <b>${name}</b>, está siendo usado por
          ${namesBotsActive.length === 1 ? 'el siguiente bot:' : 'los siguientes bots:'} <b> ${namesBotsActive.join(', ')}</b>.
          No se puede eliminar.
        `,
      });
      return;
    };

    try {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar este asistente?",
        text: "No podrás recuperarlo.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const url = urlAPI_3 + "assistant/"+id;
          const options = { method: "DELETE", mode: "cors"};
          const response = await fetchWithAuth(url, options);          
          const result = await response.json();
          console.log("Result of the API: deleteAssistant/{id}:", result);
          setModalCreateAssistant(false)
          setLoading(false);
          getAssistants()
          Swal.fire({
            title: "¡Bien!",
            title: result.message,
            icon: result.type,
            confirmButtonText: "Aceptar",
          });
        }
      });
    } catch (error) {
      console.log('error al crear Asistente---->', error);
    }    
  }

  //#--> Listar Bots
  const getBots = async () => {
    console.log('trayendo bots')
    try {
      setLoading(true);
      const url = urlAPI_3 + 'chatBot';
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if(result.length <= 0) {
        console.log("no hay data");
        setListBots([]);
        setValidation(true);
      } else {
        console.log("si hay lista", result);
        setListBots(result);
        setValidation(false);
      }
    } catch (error) {
      console.log("error:", error);
      return null;
    } finally {
      console.log('🎁🎁🎁🎁🎁🎁')
      setLoading(false);
    };
  };

  //#--> Funcion al presionar editar un asistente
  const onEditAssistant = async (id) => {
    setModalCreateAssistant(true)
    setModeUpdate(true)
    const filterAssistant = listAssistants.find( a => a.id === id)
    setTypeArea((prev) => ({
      ...prev,
      typeTransfer: filterAssistant.transfer.type
    }));
    setAgentArea(filterAssistant.transfer);
    console.log('----------->', filterAssistant)
    reset({
      name: filterAssistant.name,
      instructions: filterAssistant.url ? "2" : "1",
      url: filterAssistant.url ?? "",
      file: filterAssistant.file,
      content: filterAssistant.content,
      language: filterAssistant.language,
      role: filterAssistant.role,
      instructions_message: filterAssistant.instructions_message,
      transfer: filterAssistant.transfer.type
    })
    setIsFile(filterAssistant.file ?? "")
    setSelectedRadio(filterAssistant.url ? "2" : "1")
    setIdAssistant(id)
  }

  //#--> Funcion al presionar agregar un nuevo asistente
  const onNewAssistant = async () => {
    reset({
      name: "",
      instructions: "",
      content: "",
      url: "",
      role: "Eres un asistente de atención al cliente muy útil, importante y profesional.",
      instructions_message: "Tu objetivo es proporcionar respuestas claras y precisas, resolver problemas de manera efectiva y asegurarte de que los clientes se sientan valorados y satisfechos con la atención recibida. Siempre actúas con empatía, cortesía y eficiencia para mejorar la experiencia del cliente.",
      language: "1"
    })
    setSelectedRadio("")
    setNavAdditionalData(false)
    setModalCreateAssistant(true)
    setModeUpdate(false)
  }

  const getSignedUrl = async (key_name, size) => {
    try {
      key_name = encodeURIComponent(key_name);
      const url = urlAPI_1 + `getSignedURL/${key_name}/${size}`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      if(response.status == 200){
          let url_signed = await response.json();
          if(url_signed.error){
              return false;
          }
          return url_signed;
      }else{
          console.error('Error getting signed URL');
          return false;
      }
      return response;
    } catch (error) {
      console.error('Error getting signed URL:', error);
      return false;
    }
  };

  const uploadToS3 = async (file) => {
    try {
      let max_size = false;
      if(file.type.includes("document") && file.size > ((1024 * 1024) * 100)){
        max_size = true;
        Swal.fire({
          title: "¡Atención!",
          text: "El archivo supera el peso máximo permitido para documentos: 100MB. Intente enviarlo como documento.",
          icon: "info",
        });
      }

      if (max_size) {
        setSizeMax(true);
        return false;
      } else {
        setSizeMax(false);
      }
      setLoading(true);
      const signedUrl = await getSignedUrl(file.name, file.size);
      if(signedUrl){
        const res = await upload_s3(file, signedUrl);
        console.log('res res::', res);
        setLinkPDF(res)
      }
      setLoading(false);
    } catch (error) {
      console.error('Error uploading to s3...', error);
      return false;
    }
  };

  // convertir pdf en base64
  const convertDocumentBase64 = (file) => {
    return new Promise((res, rej) => {
      console.log("Dentro del returno new promise.............");
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  //Convertit documento para guardar
  const onFilePdf = async (e) => {
    var file = e.target.files[0];
    console.log("Recibiendo el archivo", file);
    if (file.type == "application/pdf") {
      // const base64 = await convertDocumentBase64(file);
      // setLinkPDF(base64);
      await uploadToS3(file);
    } else {
      Swal.fire({
        title: "¡Atención!",
        text: "Recuerda que el archivo debe ser pdf.",
        icon: "info",
      });
    }
  };

  // Obtiene la lista de areas
  const getAreas = async () => {
    try {
      const url = urlAPI_2 + "getAreasByCompany";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
        setListAreas([]);
      }else{
        const opts = result.data.map(v => {
          return {
            label: v.description,
            value: v._id,
            type: "area"
          }
        })
        setListAreas(opts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Obtiene la lista de agentes
  const getAgents = async () => {
    try {
      const url = urlAPI_2 + "usersbyArea";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (result.message === "Tu petición no tiene cabecera de autorización") {
        Swal.fire({
          title: "¡Atención!",
          text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
          icon: "info",
        });
        navigate("/");
      } else if (result.data.length <= 0) {
        setListAdvisers([]);
      } else {
        const opts = result.data.map(v => {
          return {
            label: v.name,
            value: v._id,
            type: "agent"
          }
        })
        setListAdvisers(opts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getIsDark = () => {
    const dark = localStorage.getItem("THEME");
    setIsDark(dark);
  }
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightblue' : '#fff',
      color: state.isSelected ? '#333' : '#333',
      '&:hover': {
        backgroundColor: 'lightgray',
        color: '#333',
        cursor: 'pointer',
      },
    }),
  };
  const customStylesDark = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#455a64' : '#202c33',
      color: state.isSelected ? 'white' : 'white',
      '&:hover': {
        backgroundColor: '#374954',
        color: 'white',
        cursor: 'pointer',
      },
    }),
  };

  const formatAssistants = [...listAssistants].reverse();

  return (
    <>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      <div className="m-auto container-fluid p-0 h-100">
        <div className="col-xs-9 heading">
          <div className="mt-2">
            <h1>Asistentes</h1>
            <input
              type="button"
              className="btn btn-default"
              value="Agregar nuevo"
              onClick={() => onNewAssistant()}
            />
          </div>
          <Header />
        </div>

        <header>
          <div style={{ float: "right" }}>
            <form className="form-inline">
              <div className="su-control my-1">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Búsqueda"
                  // value={search}
                  // onChange={(e) => {
                  //   setSearch(e.target.value);
                  //   searchQuery(e.target.value);
                  //   resetPage();
                  // }}
                />
                <button
                  className="btn btn-info"
                  type="button"
                  id="buttonSearchProductBack"
                  style={{ height: "34px" }}
                >
                  <BsSearch />
                </button>
              </div>
            </form>
          </div>
        </header>

        <div className="container-fluid mt-3 px-0" style={{ height: "75%", overflowY: "auto", overflowX: "none" }}>
          <table className="table table-striped">
            <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
              <tr>
                <th>Acciones</th>
                <th>Nombre</th>
                <th>Estado</th>
                <th>Creado</th>
                <th>Actualizado</th>
              </tr>
            </thead>
            <tbody>
              {formatAssistants.length > 0 && formatAssistants.slice((page - 1) * byPage, (page - 1) * byPage + byPage).map((assistant, k) => (
                <tr key={k}>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="dropdown">
                        <button className="bg-transparent border-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <FaEllipsisVertical className="text-success f-24 pointer"/>
                        </button>
                        <div className="dropdown-menu p-2" aria-labelledby="dropdownMenuButton">
                          <button className="text-primary border-0 bg-transparent d-block pointer mt-2" onClick={() => onEditAssistant(assistant.id)}>
                            {/* <i className="icon-pencil" style={{ fontSize: "18px" }} /> */}
                            <BsPencilFill style={{ fontSize: "18px" }}/>
                            <span className="text-dark ml-2 f-14">Editar</span>
                          </button>
                          <button className="text-danger border-0 bg-transparent d-block pointer mt-2" onClick={() => deleteAssistant(assistant.id)}>
                            {/* <i className="icon-trash-empty" style={{ fontSize: "18px" }}/> */}
                            <BsTrash3 style={{ fontSize: "18px" }}/>
                            <span className="text-dark ml-2 f-14">Eliminar</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{assistant.name.length >= 35 ? assistant.name.substr(0, 35) + "..." : assistant.name}</td>
                  <td className="status-template">
                    <p>
                      <a
                        href="javascript:;"
                        className="green"
                      >
                        Activo
                      </a>
                    </p>
                  </td>
                  <td>{dayjs(assistant.created).locale("es").format("DD MMMM YYYY h:mm A")}</td>
                  <td>{dayjs(assistant.updated).locale("es").format("DD MMMM YYYY h:mm A")}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {validation && (
            <div className="text-center mt-3">
              <div className="empty">
                <GiBrainFreeze style={{ fontSize: "55px" }} />
                <h1>No tienes Asistentes</h1>
                <input
                  type="button"
                  className="btn btn-default"
                  value="Agregar nuevo"
                  onClick={() => onNewAssistant()}
                />
              </div>
            </div>
          )}
        </div>

        <footer>
          <div className="row">
            <div className="col-md-8"></div>

            <div className="col-md-4">
              {/* Creación de la paginación simple */}
              <Pagination
                totalData={formatAssistants.length}
                byPage={byPage}
                setPage={setPage}
              />
            </div>
          </div>
        </footer>
      </div>

      <Modal
        status={modalCreateAssistant}
        changeStatus={setModalCreateAssistant}
        title={`${modeUpdate ? "Actualizar" : "Entrenar"} asistente`}
        width={"750px"}
      >
        <Content>
          <form onSubmit={handleSubmit(modeUpdate ? putAssistant : postAssistant)} style={{ width: "100%" }}>
            <div className="scrollable-content scrollable-content-overflow">
              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                <label>Nombre: </label>
                <label className="f-w-100 d-block" >Escribe el nombre de tu asistente (Solo tú lo verás).</label>
                <div className="su-control-group">
                  <input
                    className="form-control px-2"
                    type="text"
                    name="name"
                    {...register("name", {
                      required: {
                        value: true,
                        message:"El nombre es requerido.",
                      }
                    })}
                  />
                </div>
                {errors.name && <Alert>* {errors.name.message}</Alert>}
              </div>

              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                <label>Instrucciones: </label>
                <label className="f-w-100 d-block" >Selecciona como deseas cargar las instrucciones para entrenar tu asistente.</label>
                <div className="su-control mt-2 d-flex justify-content-evenly">
                  {radioOptions.length > 0 && radioOptions.map((radio, k) => (
                    <div key={k} className="d-flex pointer">
                      <input
                        className="pointer"
                        type="radio"
                        name="answerSettings"
                        id={radio.id}
                        value={radio.id}
                        checked={selectedRadio === radio.id}
                        {...register("instructions", {
                          required: {
                            value: true,
                            message:"El campo es requerido.",
                          }
                        })}
                        onChange={(e) => {
                          console.log('-------radio--------', e.target.value)
                          setSelectedRadio(e.target.value);
                          if (e.target.value === 2) {
                            // reset({content: ""})
                            setValue('content', "");
                          } else {
                            // reset({url: ""})
                            setValue('url', "");
                          }
                        }}
                      />
                      <label className="d-flex mb-0 ml-1 pointer" htmlFor={radio.id} key={radio.id} style={{ padding: "5px 2px 5px" }}>
                        {radio.name}
                      </label>
                    </div>
                  ))}
                </div>
                {errors.instructions && <Alert>* {errors.instructions.message}</Alert>}
              </div>

              { selectedRadio === "1" ? (
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <div className="su-control-group">
                    <textarea
                      className="form-control px-2"
                      placeholder="Eres un asistente de gran ayuda..."
                      rows="4"
                      type="text"
                      name="content"
                      // value={inputStr2}
                      {...register("content", {
                          required: {
                            value: true,
                            message:"El campo es requerido.",
                          },
                          maxLength: {
                            value: 240000,
                            message: "El máximo es de 240.000 caracteres.",
                          }
                        })}
                      // onChange={handleChangeQuiclyReply}
                    ></textarea>                    
                  </div>
                  {errors.content && <Alert>* {errors.content.message}</Alert>}
                </div>
              ) : selectedRadio === "2" ? (<>
                <div className="d-flex mt-3 justify-content-center">
                  <a 
                    className="btn btn-lg btn-info d-flex align-items-center justify-content-center" 
                    href="https://docs.google.com/spreadsheets/d/16qe4A5CRCNEcTCyfGy0k6njBvTGlcmx8fownzryQB6I/edit?usp=sharing" 
                    target="_blanck"
                    // style={{width:"70%"}}
                  >
                    <SiGooglesheets className="mr-1" style={{fontSize:"1.5em"}}/>
                    <span>Ver Plantilla de ejemplo (Google Sheets)</span>
                  </a>
                </div>

                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  {/* <label>Nombre: </label> */}
                  <div className="su-control-group">
                    <input
                      className="form-control px-2"
                      placeholder="URL de la Hoja de Google Sheet (Debe estar pública)"
                      type="text"
                      name="url"
                      {...register("url", {
                        required: {
                          value: true,
                          message:"El campo es requerido.",
                        },
                        pattern: {
                          value: /^(https?:\/\/)?(www\.)?docs\.google\.com\/spreadsheets\/[a-zA-Z0-9-_]+\/?.*$/,
                          message: "URL inválida.",
                        }
                      })}
                    />
                  </div>
                  {errors.url && <Alert>* {errors.url.message}</Alert>}
                  <label className="f-w-100" >Aquí debe ir la URL de la Hoja de Google que contiene las instrucciones para el asistente.<br/> 
                    <strong>Impotante:</strong> La hoja debe ser PUBLICA.
                  </label>
                </div>
              </>) : null
              }

              <div className="panel-heading my-3" style={{border: "1px solid #e8e8e8", boxShadow:"2px 2px 0px 0px #b7c6ca"}}>
                <label 
                  id="title-aditionalDates" 
                  className="panel-title d-flex justify-content-between align-items-center pointer mb-0 p-2" 
                  onClick={() => {setNavAdditionalData(!navAdditionalData)}}
                >
                  <a href="javascript:;" className={`d-flex align-items-center a-payment ${navAdditionalData && "active"}`}>
                    Opciones avanzadas (Opcional)
                  </a>
                  {navAdditionalData ?
                    <FaSortUp className="pointer mr-3" style={{ fontSize: "20px", marginTop: "5px" }}/> :
                    <FaSortDown className="pointer mr-3" style={{ fontSize: "20px", marginBottom: "5px" }}/>
                  }
                </label>

                {navAdditionalData ? (
                  <div id="email-welcome" className="panel-collapse">
                    <div className="panel-body">
                      <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable px-4">
                        <div className="mb-2">
                          <label>Idioma del bot: </label>
                          <div className="su-control-group">
                            <select
                              name="language"
                              id="language"
                              className="form-control px-2"
                              {...register("language")}
                            >
                              <option value="1" key="1" >
                                Español
                              </option>
                              <option value="2" key="2" >
                                Ingles
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="mb-2">
                          <label>Rol del Bot: </label>
                          <label className="f-w-100 d-block" >Este será el papel que tomará el asistente. Puedes dejarlo así, si no sabes que escribir.</label>
                          <div className="su-control-group">
                            <textarea
                              className="form-control px-2"
                              placeholder="Eres un asistente de gran ayuda..."
                              rows="3"
                              type="text"
                              name="role"
                              // value={inputStr2}
                              {...register("role", {
                                required: {
                                  value: true,
                                  message: "El campo es requerido.",
                                },
                                maxLength: {
                                  value: 300,
                                  message: "El máximo es de 300 caracteres.",
                                }
                              })}
                            ></textarea>
                            {errors.role && <Alert>* {errors.role.message}</Alert>}                    
                          </div>
                        </div>

                        <div className="mb-2">
                          <label>Instrucciones del Mensaje: </label>
                          <label className="f-w-100 d-block" >Estas son las instrucciones que usamos para que ChatGPT procese el mensaje. Si no tienes una alternativa, por favor, déjalo sin modificar.</label>
                          <div className="su-control-group">
                            <textarea
                              className="form-control px-2"
                              placeholder="Eres un asistente de gran ayuda..."
                              rows="5"
                              type="text"
                              name="instructions_message"
                              // value={inputStr2}
                              {...register("instructions_message", {
                                required: {
                                  value: true,
                                  message: "El campo es requerido.",
                                },
                                maxLength: {
                                  value: 3000,
                                  message: "El máximo es de 3000 caracteres.",
                                }
                              })}
                            ></textarea>              
                            {errors.instructions_message && <Alert>* {errors.instructions_message.message}</Alert>}       
                          </div>
                        </div>  

                        <div className="mb-2">
                          <label>Cargar brochure: </label>
                          <label className="f-w-100 d-block" >Aquí podras cargar tu catálogo si deseas, para que tu asistente tenga mas información de la empresa y productos/servicios, precios, ubicaciones, etc.(Debe ser en pdf)</label>
                          <div className="su-control-group">
                            {modeUpdate && isFile ? (
                              <div className="content-img text-center my-2 d-flex align-items-center" style={{flexDirection:"column"}}>
                                <a className="d-flex align-items-center" href={isFile} alt="pdf" target="_blank" rel="noreferrer">
                                  Ver archivo <FaEye className="ml-2" style={{color: "#ff9317"}} />
                                </a>
                                <a className="d-flex align-items-center" href="javascript:;" alt="pdf" onClick={() => setIsFile("")}>
                                  Cargar nuevo <BsPencilFill className="text-primary ml-2" />
                                </a>
                              </div>
                            ) : (
                              <input
                                type="file"
                                name="image"
                                id="file"
                                className="form-control"
                                accept="application/pdf"
                                {...register("file")}
                                onChange={(e) => onFilePdf(e)}
                              />

                            )}
                          </div>
                        </div>

                        <div className="mb-2">
                          <label>Transferencias automáticas</label>
                          <label className="f-w-100 d-block" >Puedes elegir a que área o agente será transferido el usuario cuando termine o requiera hablar con un agente humano.</label>
                          <div className="su-control-group d-flex">
                            <div className="d-flex pointer mr-1">
                              <input
                                style={{accentColor: '#5BC0DE'}}
                                type="radio"
                                value="area"
                                className="pointer"
                                {...register("transfer", { required: "El campo es requerido."})}
                                onChange={(e) => {
                                  setTypeArea((prev) => ({
                                    ...prev,
                                    typeTransfer: e.target.value
                                  }));
                                }}
                              />
                              <label
                                className="d-flex mb-0 ml-1 pointer d-flex align-items-center"
                                style={{ padding: "5px 2px 5px" }}
                              >
                                Areas
                              </label>
                            </div>
                            <div className="d-flex pointer mr-1">
                              <input
                                style={{accentColor: '#5BC0DE'}}
                                type="radio"
                                value="agent"
                                className="pointer"
                                {...register("transfer", { required: "El campo es requerido."})}
                                onChange={(e) => {
                                  setTypeArea((prev) => ({
                                    ...prev,
                                    typeTransfer: e.target.value
                                  }));
                                }}
                              />
                              <label
                                className="d-flex mb-0 ml-1 pointer d-flex align-items-center"
                                style={{ padding: "5px 2px 5px" }}
                              >
                                Agentes
                              </label>
                            </div>
                          </div>
                          {typeArea.typeTransfer === "area" && (
                            <div className="su-control-group d-flex">
                              <Select
                                styles={isDark == 'true' ? customStylesDark : customStyles}
                                className="form-control basic-multi-select"
                                placeholder="Seleccione..."
                                name="selectAreas"
                                value={agentArea}
                                onChange={(area) => {
                                  setAgentArea(area);
                                }}
                                options={listAreas}
                                classNamePrefix="select"
                                noOptionsMessage={() => "Sin opciones"}
                                getOptionLabel={(option) => (
                                  <div className="option d-flex" style={{fontSize: "15px"}}>
                                    {option.label} 
                                  </div>
                                )}
                                getOptionValue={(option) => option.value}
                              />
                            </div>
                          )}
                          {typeArea.typeTransfer === "agent" && (
                            <div className="su-control-group d-flex">
                              <Select
                                styles={isDark == 'true' ? customStylesDark : customStyles}
                                className="form-control basic-multi-select"
                                placeholder="Seleccione..."
                                name="selectAgents"
                                value={agentArea}
                                onChange={(agent) => {
                                  setAgentArea(agent);
                                }}
                                options={listAdvisers}
                                classNamePrefix="select"
                                noOptionsMessage={() => "Sin opciones"}
                                getOptionLabel={(option) => (
                                  <div className="option d-flex" style={{fontSize: "15px"}}>
                                    {option.label} 
                                  </div>
                                )}
                                getOptionValue={(option) => option.value}
                              />
                            </div>
                          )}
                        </div>
                        {errors.transfer && <Alert>* {errors.transfer.message}</Alert>}   
                      </div>
                    </div>
                  </div>
                ) : null}

              </div>

              
            </div>

            <div className="row justify-content-end bd col-12 mt-4">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  setModalCreateAssistant((prevMessage) => { return false});
                }}
              >
                Cancelar
              </a>
              <input
                className="btn btn-lg btn-info"
                type="submit"
                value={`${modeUpdate ? "Actualizar" : "Aceptar"}`}
              />
            </div>
          </form>


        </Content>
      </Modal> 
    </>
  );
};

export default Assistants;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
`;

const Alert = styled.span`
  color: red;
  display: block;
  width: 100%;
  // width: 200px;
`;